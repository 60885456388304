
// import axios from 'axios';

// const API = axios.create({ baseURL: 'https://edu.d5art.com/api' });

// export const fetchUserProfile = (id) => API.get(`/UserList/${id}`);
// export const fetchUserCourses = (id) => API.get(`/users/courses/${id}`);



// src/services/userService.js

import axios from 'axios';

const SERVER_URL ="https://edu.d5art.com"


export const fetchUserFromAPI = async (email) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/UserList`, {
        params: { email },
      });

      if (response.data && response.data.users && response.data.users.length > 0) {
        return response.data.users[0];  
      }
      
      throw new Error("User not found");
    } catch (error) {
      throw error;
    }
  };