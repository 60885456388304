import React from 'react'

const Rewards = () => {

  return (
    <div  className="pt-10 lg:px-20 bg-black/50 min-h-screen font-poppins ">
          <p className="font-bold text-4xl text-center text-[#F29D38] font-space ">Rewards</p>
          <p className=" text-[#BEC9DA] text-center md:w-[80%] w-[90%] mx-auto text-xs md:text-base mt-5" >Win exciting rewards, including cash prizes, free Goodies, free tokens, premium course access, gadgets, startup support, internships, and tickets to Web3 events. Special awards for innovation and impactful solutions!</p>

          <div className='lg:w-[85%] w-[95%] mx-auto flex md:flex-row flex-col justify-center gap-2 mt-10' >
             <div className='md:w-[30%] bg-Fabcbg2 row-span-3 bg-cover rounded-xl mt-5 md:mt-0' >
              <div  className='bg-[#D9D9D9]/10 bg-center rounded-xl flex flex-col justify-center items-center h-full py-10'>
                 <p className="font-bold text-2xl md:text-3xl text-center text-[#F29D38] ">Winner</p>
                 <p className='font-bold text-4xl md:text-5xl mt-3' ><span className='text-[#F29D38]' >$</span>1000</p>
                 <p className='text-sm w-[80%] text-center mt-14' >For the overall winner of the hackathon</p>
                 </div>
             </div>

             <div className='md:w-[55%] md:grid grid-cols-3 gap-2' >
             <div className='bg-Fabcbg2 bg-cover rounded-xl h-full mt-5 md:mt-0' >
              <div  className='bg-[#D9D9D9]/10  bg-center rounded-xl py-7 h-full flex flex-col justify-center gap-6'>
                 <p className="font-bold text-lg text-center text-[#AAAAAA] ">Second Place</p>
                 <p className='font-bold text-5xl  text-center' ><span className='text-[#A6A6A6]' >$</span>600</p>
                 
                 </div>
             </div>
             <div className='bg-Fabcbg2 bg-cover rounded-xl h-full  col-span-2 mt-5 md:mt-0' >
              <div  className='bg-[#D9D9D9]/10  bg-center rounded-xl flex flex-col justify-center items-center h-full py-7 gap-5'>
                 <p className="font-bold text-lg text-center text-[#02DC52] ">Honorable mentioning</p>
                 <p className=' text-xl md:text-3xl font-bold text-center text-white/70'  >Special Awards</p>
                 <p className='text-center w-[80%] text-sm' >For each category, excluding the winning team</p>
                 </div>
             </div>
             <div className='bg-Fabcbg2 bg-cover rounded-xl h-full mt-5 md:mt-0' >
              <div className='bg-[#D9D9D9]/10  bg-center rounded-xl py-5 h-full flex flex-col justify-center items-center gap-6 py-10'>
                 <p className="font-bold text-lg text-center text-[#FFA87D] ">Third Place</p>
                 <p className='font-bold text-5xl text-center' ><span className='text-[#FFA87D]' >$</span>400</p>
                 </div>
             </div>
         
             <div className='bg-Fabcbg2 bg-cover rounded-xl h-full mt-5 md:mt-0' >
              <div  className='bg-[#D9D9D9]/10  bg-center rounded-xl flex flex-col justify-center items-center h-full gap-6 px-2  py-10'>
                 <p className="font-bold text-lg text-center text-[#1CFFFF] ">Goodies</p>
                 <p className=' text-sm text-center ' >Customized goodies for all participants</p>                
                 </div>
             </div>
             <div className='bg-Fabcbg2 bg-cover rounded-xl h-full mt-5 md:mt-0' >
              <div  className='bg-[#D9D9D9]/10 bg-center rounded-xl flex flex-col justify-center items-center h-full gap-6 py-10'>
                 <p className="font-bold text-lg text-center text-[#C963FF] ">NFT Certificate</p>
                 <p className='text-sm text-center ' >For all participants</p>
                
                 </div>
             </div>
             </div>
          </div>
      
    </div>
  )
}

export default Rewards