// import React from 'react'
// import Header from '../../../Layout/Header';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// export default function Assessment() {
//     const questions = [
//         {
//           text: "What is your favorite color?",
//           options: ["Red", "Blue", "Green", "Yellow"],
//         },
//         {
//           text: "What is your favorite animal?",
//           options: ["Dog", "Cat", "Bird", "Fish"],
//         },
//         {
//           text: "What is your favorite food?",
//           options: ["Pizza", "Burger", "Pasta", "Sushi"],
//         },
//       ];

//     return (
//         <div className='bg-[#0F0F0F] bg-Fabcbg bg-cover bg-center'>
//         <Header/>
//         <button className='mx-[5%] text-white flex items-center'><FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2'/>Back</button>
//             <div className=' min-h-screen pb-10'>
//                 <div className='text-white w-[90%] mx-auto'>
//                     <div className='text-center fade-in'>
//                         <div className='flex justify-center'>
//                             <h1 className='bg-gradient-to-r from-[#31989B] to-[#4A5CA4] text-transparent bg-clip-text text-3xl font-bold'>Events</h1>
//                         </div>
//                         <h2 className='font-bold text-xl mt-2'>Jordon Skill Summit</h2>
//                         <h3 className='font-bold mt-5'>Welcome to D5art holland Assessment</h3>
//                         <p className='text-sm 2xl:text-base lg:w-[75%] mx-auto mt-2'>Discover your unique personality and natural abilities with the Holland Assessment. Explore your personal interests and gain valuable insights to better understand yourself. Start your journey now!</p>
//                     </div>
//                     <div className='mt-7 bg-white/10 rounded-2xl lg:w-[90%] mx-auto px-4 py-5 lg:p-10 fade-in'>
//                         {questions.map((question, index) => (
//                             <div key={index} className="mb-8">
//                                 <h2 className="text-lg font-semibold mb-4">{`${index + 1}. ${question.text}`}</h2>
//                                 <div className="space-y-4 pl-5">
//                                     {question.options.map((option, optionIndex) => (
//                                         <label key={optionIndex} className="flex items-center space-x-3">
//                                             <input
//                                                 type="radio"
//                                                 name={`question${index}`}
//                                                 className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded-full"
//                                             />
//                                             <span>{option}</span>
//                                         </label>
//                                     ))}
//                                 </div>
//                             </div>
//                         ))}
//                         <div className='flex justify-center'>
//                         <button className='bg-[#5973CC] px-3 py-1 rounded-md'>Submit my response</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }



















import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../../Layout/Header';
import Footer from "../../../Layout/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { SERVER_URL } from '../../../../config';

export default function Assessment() {
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [result, setResult] = useState(null);
    const [completedStep3, setCompletedStep3] = useState(false);

    const email = localStorage.getItem("edu_email");


    const navigate = useNavigate();

    useEffect(() => {
        const checkAssessmentStatus = async () => {
          try {
            const response = await axios.get(`${SERVER_URL}/api/jordan-assessment-check?email=${email}`);
            
            if (response.data.success && response.data.hasTakenAssessment) {
            
              toast.dismiss(); 
    
              toast.error("You have already completed the assessment", {
                duration: 3000,
              });
    
              setTimeout(() => {
                navigate('/jordan-event'); 
              }, 3000);
            }
          } catch (error) {
            console.error('Error checking assessment status:', error);
          }
        };
    
        checkAssessmentStatus();
      }, [email, navigate]);


    useEffect(() => {
        axios
            .get(`${SERVER_URL}/api/jordan-question`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.error('Error fetching questions:', error);
                toast.error('Failed to load questions. Please try again.');
            });
    }, []);


    

    const handleSubmit = () => {

        const unansweredQuestions = questions.filter((q) => !responses[q.id]);

    if (unansweredQuestions.length > 0) {
        toast.error('Please answer all questions before submitting.');
        return; 
    }


        const toastId = toast.loading('Your Assessment is submitting...', { icon: '⌛' });
    
        const formattedResponses = Object.entries(responses).map(([questionId, answer]) => ({
            questionId: Number(questionId),
            answer, 
        }));
    
        axios
            .post(`${SERVER_URL}/api/jordan-answer-set`, { email, responses: formattedResponses })
            // .then(() => {
            //     return axios.post('http://localhost:4000/api/jordan-answer-calculate', { email });
            // })
            .then(response => {
                setResult(response.data); 
                toast.success('Assessment is successfully completed!', {
                    id: toastId,
                });      
              setCompletedStep3(true)
            })
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    toast.error('You already submitted the assessment.', {
                        id: toastId, 
                    });               
                     setTimeout(() => {
                        window.location.href = '/jordan-event'; 
                    }, 2000);
                } else {
                    console.error('Submission error:', error);
                    toast.error('Something went wrong. Please try again.', {
                        id: toastId, 
                    });               
                 }
            });
    };
    

    return (
        <div className="bg-[#0F0F0F] bg-Fabcbg bg-cover bg-center">
            <Header />
            <button className="mx-[5%] text-white flex items-center">
                <FontAwesomeIcon icon={faChevronLeft} className="text-sm mr-2" />
                Back
            </button>
            <div className="min-h-screen pb-10">
                <Toaster />
                <div className="text-white w-[90%] mx-auto">
                    <div className="text-center fade-in">
                        <div className="flex justify-center">
                            <h1 className="bg-gradient-to-r from-[#31989B] to-[#4A5CA4] text-transparent bg-clip-text text-3xl font-bold">
                                Events
                            </h1>
                        </div>
                        <h2 className="font-bold text-xl mt-2">Jordon Skill Summit</h2>
                        <h3 className="font-bold mt-5">Welcome to D5art Holland Assessment</h3>
                        <p className="text-sm 2xl:text-base lg:w-[75%] mx-auto mt-2">
                            Discover your unique personality and natural abilities with the Holland Assessment.
                            Explore your personal interests and gain valuable insights to better understand
                            yourself. Start your journey now!
                        </p>
                    </div>
                    <div className="mt-7 bg-white/10 rounded-2xl lg:w-[90%] mx-auto px-4 py-5 lg:p-10 fade-in">
                        {questions.map((question, index) => (
                            <div key={index} className="mb-8">
                                <h2 className="text-lg font-semibold mb-4">{`${index + 1}. ${question.question_text}`}</h2>
                                <div className="space-y-4 pl-5">
                                    {(() => {
                                        try {
                                            const options = question.options ? JSON.parse(question.options) : [];
                                            return options.map((option, optionIndex) => (
                                                <label key={optionIndex} className="flex items-center space-x-3">
                                                    <input
                                                        type="radio"
                                                        name={question.id}
                                                        className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded-full"
                                                        value={option}
                                                        onChange={() =>
                                                            setResponses({
                                                                ...responses,
                                                                [question.id]: option,
                                                            })
                                                        }
                                                    />{' '}
                                                    <span>{option}</span>
                                                </label>
                                            ));
                                        } catch (error) {
                                            console.error('Invalid JSON for options', error);
                                            return <p>Options are unavailable</p>;
                                        }
                                    })()}
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-center">
                            <button
                                className="bg-[#5973CC] px-3 py-1 rounded-md"
                                onClick={handleSubmit}
                            >
                                Submit my response
                            </button>
                        </div>
                    </div>
                    {/* {result && (
                        <div className="mt-6 text-center">
                            <h2 className="text-xl font-bold">Your Results</h2>
                            <p>Personality Type: {result.personalityType}</p>
                            <p>Total Score: {result.totalScore}</p>
                        </div>
                    )} */}
                </div>

                {completedStep3 && (
          <div className="fixed inset-0 bg-black/50 flex justify-center items-center ">
            <div className=" w-[95%] mx-auto max-w-xl bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5">
              <div className="w-full flex justify-around items-center">
                <img
                  src="/assets/Others/confetti.svg"
                  className="w-4/12 rotate-90 "
                />
                <img src="/assets/Others/confetti.svg" className="w-4/12" />
              </div>

              <p className="text-xs italic mt-3 text-center text-white">
                Thank you for completing the assessment! Your report is being
                generated and will be shared with you via email shortly
              </p>
              <div className="flex justify-center items-center mt-5">
                <NavLink to="/jordan-event">
                <button
                  onClick={() => setCompletedStep3(false)}
                  className="px-5 py-1.5 rounded-full bg-[#D9D9D95C] text-sm "
                >
                  Back to hackathon
                </button>
                </NavLink>
              </div>
            </div>
          </div>
        )}
            </div>
            <Footer/>
        </div>
    );
}
