import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react'

const Faq = () => {

    const [expandedIndex, setExpandedIndex] = useState(-1);

    const faq = [
        {
          question: "Who can participate?",
          answer:
            "Anyone passionate about innovation and technology, including students, developers, designers, and professionals.",
        },
        {
          question:
            "What is the team size limit?",
          answer:
            "Teams can have a minimum of 2 member and a maximum of 5 members.",
        },
        {
          question: "Can we work on our own problem statement?",
          answer:
            " Yes, participants are free to choose their own problem statements or work on the given themes.",
        },
        {
          question: "Is prior experience required?",
          answer:
            " No, the hackathon is open to participants of all skill levels. Beginners are encouraged to join!",
        },
        {
          question:
            "Can I participate remotely?",
          answer:
            " Yes, the hackathon supports remote participation. Teams can collaborate online using provided tools.",
        },
        {
            question:
              "How will the projects be judged?",
            answer:
              "Projects will be evaluated on innovation, technical feasibility, impact, and design/user experience.",
          },
    ]

    const toggleExpand = (index) => {
        if (expandedIndex === index) {
          setExpandedIndex(-1); 
        } else {
          setExpandedIndex(index); 
        }
      };


  return (
    <div className='w-full min-h-screen py-14' >
        <p className='text-[#F29D38] text-center text-2xl font-semibold font-space ' >FAQ's</p>
        <div className='w-full md:flex justify-between items-center' >
            <img src='../assets/jordanevent/avatar2.svg' className=' hidden md:block '  />
            <div>
        {faq.map((data, index) => (
          
              <div  key={index}
              onClick={() => toggleExpand(index)}
              className={`w-[90%] mx-auto  bg-[#323232]/25 rounded-md duration-500 p-3 mt-5 transform transition ease-in-out ${
                expandedIndex === index ? "h-full" : "h-full"
              }`}>
             <div className="flex ">
                <div className="w-[90%]">
                  <h1 className="font-bold py-2 text-center">{data.question}</h1>
                </div>
                <div className="w-[10%] flex items-center justify-center">
                  <button
                    className="font-bold"
                   
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={`transform transition-transform duration-500 ${
                        expandedIndex === index
                          ? "rotate-90 text-white px-2 py-1.5 rounded-full duration-500"
                          : ""
                      }`}
                    />
                  </button>
                </div>
              </div>
              <div
                className={`duration-300 ease-in-out overflow-hidden ${
                  expandedIndex === index
                    ? "py-3 max-h-[300px]   rounded-b-md"
                    : "max-h-0"
                }`}
              >
                <p className="text-sm text-center text-[#878C91] duration-500">
                  {data.answer}
                </p>
              </div>
              </div>
          
          ))}
        </div>
            <img src='../assets/jordanevent/avatar3.svg' className=' hidden md:block ' />
        </div>
     
    </div>
  )
}

export default Faq