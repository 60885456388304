import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendar, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import Leaderboard from '../Sliders/Leaderboard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstituteDetails } from '../../../../Slice/instituteSlice';
import { fetchUserMeetings, selectMeetings, selectMeetingsStatus } from '../../../../Slice/meetingsSlice';
import toast, { Toaster } from 'react-hot-toast';
import { fetchUserCoursesProgress, selectCourseError, selectCourseProgress, selectCourseStatus } from '../../../../Slice/courseProgressSlice';
import { fetchUser, selectError, selectStatus, selectUser } from '../../../../Slice/userSlice';


export default function Coe() {

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [selectedDate, setSelectedDate] = useState(15);
    const [isUniversity, setUniversity] = useState(false);
    const dispatch = useDispatch();
    const { instituteName, instituteAddress, totalUsers, instituteRank, usersWithRank, status, error } = useSelector(state => state.institute);
    const coursesStatus = useSelector(selectCourseStatus);
    const coursesError = useSelector(selectCourseError);
    const user = useSelector(selectUser);
    const userStatus = useSelector(selectStatus);
    const userError = useSelector(selectError);

     const email ="hwtbdpdjtw.t@rightbliss.beauty"

    useEffect(() => {
        if (email && userStatus === 'idle') {
          dispatch(fetchUser(email));
        }
      }, [dispatch,  userStatus]);


    useEffect(() => {
      if (user?.institute_id) {
        dispatch(fetchInstituteDetails(user?.institute_id));
      }
    }, [dispatch, user?.institute_id]);
    
  
    const courses = useSelector(selectCourseProgress);

  
useEffect(() => {
  
    if (coursesStatus === 'idle' && user?.id) {
      dispatch(fetchUserCoursesProgress(user.id));
    }
  }, [dispatch, user?.id, coursesStatus]);
  



    const handleUniversity = () => {
        setUniversity(!isUniversity);
    }

 


    const calculateOverallProgress = (courses) => {
        const courseEntries = Object.values(courses);
      
        if (courseEntries.length === 0) return 0;
      
        const totalCompletion = courseEntries.reduce((sum, course) => sum + course.overallCompletion, 0);
        console.log("totalCompletion",totalCompletion)
        return (totalCompletion / courseEntries.length).toFixed(2); // Return percentage as a fixed 2-decimal number
      };
      
      const overallProgress = `${calculateOverallProgress(courses)}%`;

      



    const parseTimeToDate = (timeStr) => {
        const [hours, minutes] = timeStr.split(":");
        return new Date(1970, 0, 1, parseInt(hours, 10), parseInt(minutes, 10)); // Convert to a Date object for easy comparison
    };


   
    const getEarliestTask = (tasks) => {
        return tasks.reduce((earliest, task) => {
            const taskTime = parseTimeToDate(task.time.split(" ")[0]);
            const earliestTime = parseTimeToDate(earliest.time.split(" ")[0]);
            return taskTime < earliestTime ? task : earliest;
        });
    };

    const handleClickDate = (date) => {
        setSelectedDate(date);
    };

   
    const category = [
        { value: 'student', label: 'Student' },
        { value: 'graduate', label: 'Graduate' },
        { value: 'working', label: 'Working' }
    ]

    const CustomStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'black',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000', // Color of the option text
            ':active': {
                backgroundColor: '#d3d3d3', // Light gray when clicked
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            background: '#fff', // Color of the typed input text
        }),
    };

    const [formData, setFormData] = useState({
        name: "",
        collegeName: "",
        email: "",
        designation: "",
        description: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name.trim()) {
            formErrors.name = "Name is required.";
        }
        if (!formData.collegeName.trim()) {
            formErrors.collegeName = "College Name is required.";
        }
        if (!formData.email.trim()) {
            formErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email is invalid.";
        }
        if (!formData.designation.trim()) {
            formErrors.designation = "Designation is required.";
        }
        if (!formData.description.trim()) {
            formErrors.description = "Description is required.";
        }
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            console.log("Form submitted successfully:", formData);
        } else {
            setErrors(validationErrors);
        }
    };


    const transaction = [
        {
            id: "0x282CHET",
            rank: "1",
            points: "1",
            name: "0x282CHET",
            place: "Lorem Ipsum",
            overallpoints: "1"
        },
        {
            id: "0x282CHET",
            rank: "1",
            points: "1",
            name: "0x282CHET",
            place: "Lorem Ipsum",
            overallpoints: "1"
        },
        {
            id: "0x282CHET",
            rank: "1",
            points: "1",
            name: "0x282CHET",
            place: "Lorem Ipsum",
            overallpoints: "1"
        },
        {
            id: "0x282CHET",
            rank: "1",
            points: "1",
            name: "0x282CHET",
            place: "Lorem Ipsum",
            overallpoints: "1"
        },
        {
            id: "0x282CHET",
            rank: "1",
            points: "1",
            name: "0x282CHET",
            place: "Lorem Ipsum",
            overallpoints: "1"
        },
    ];


   

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    let currentEntities = usersWithRank;

    console.log("currentEntities",currentEntities)
    // transaction.slice(
    //     indexOfFirstEntity,
    //     indexOfLastEntity
    // );

    // const emptyRowCount = entitiesPerPage - currentEntities.length;

    const emptyRowCount = 5 - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        if (currentEntities.length === entitiesPerPage) {
            setCurrentPage(currentPage + 1);
        }
    };




    const meetings = useSelector(selectMeetings);
    const meetingsStatus = useSelector(selectMeetingsStatus);
  
   
    const [weekDates, setWeekDates] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
  
    useEffect(() => {
      dispatch(fetchUserMeetings(user?.id));
  
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDay);
  
      const dates = [];
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
      for (let i = 0; i < 7; i++) {
        const dayDate = new Date(startOfWeek);
        dayDate.setDate(startOfWeek.getDate() + i);
        dates.push(dayDate.getDate());
      }
  
      setWeekDates(dates);
      setWeekDays(days);
      setSelectedDate(dates[currentDay]);
    }, [dispatch, user]);
  


    const handleJoinMeeting = (event) => {
      const currentTime = new Date();
      const meetingStartTime = new Date(event.scheduled_at);
      const meetingAccessStart = new Date(meetingStartTime);
      meetingAccessStart.setMinutes(meetingStartTime.getMinutes() - 15);
    
      const meetingAccessEnd = new Date(meetingStartTime);
      meetingAccessEnd.setHours(meetingStartTime.getHours() + 1);
    
      if (currentTime < meetingAccessStart) {
        toast.error("Meeting has not started yet!");
      } else if (currentTime > meetingAccessEnd) {
        toast.error("Meeting has already ended!");
      } else {
        toast.success("Redirecting to meeting...");
        window.open(event.meeting_link, "_blank");
      }
    };

    if (meetingsStatus === 'loading') {
      return <div>Loading...</div>;
    }
  
    const filteredEvents = meetings.filter((event) => {
      const eventDate = new Date(event.scheduled_at).getDate();
      return eventDate === selectedDate;
    });
  
    const earliestTask = filteredEvents.length > 0
      ? filteredEvents.reduce((earliest, task) => {
          const taskTime = new Date(task.scheduled_at);
          const earliestTime = new Date(earliest.scheduled_at);
          return taskTime < earliestTime ? task : earliest;
        })
      : null;

    return (
        <div className='overflow-hidden'>
            <Header />
            <div className='sec-1'>
                <h2 className="text-2xl text-center font-bold my-4">My Centre of Excellence</h2>
                <div className="w-[90%] mx-auto bg-white rounded-lg relative">
                    <div className="flex flex-col md:flex-row lg:items-center">
                        <div className="w-[100%] md:w-1/2 ">
                            <img src='/assets/Others/citadel1.png' className='w-[100%] lg:w-[70%] mx-auto' data-aos='flip-left' data-aos-duration='1500'></img>
                        </div>
                        <div className="w-[100%] md:w-1/2 px-0 md:p-6">

                             <div className="space-y-2">
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">Ranking:</span>
                                <span className="font-semibold">#{instituteRank}</span>
                            </div>
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">Name of the Institute:</span>
                                <p className="font-semibold">{instituteName || 'Loading...'}</p>
                            </div>
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">Address:</span>
                                <span className="font-semibold">{instituteAddress || 'Loading...'}</span>
                            </div>
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">Strength:</span>
                                <span className="font-semibold">{totalUsers}</span>
                            </div>
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">COE ID:</span>
                                <p className="font-semibold">Sw2024WWQ20</p>
                            </div>
                            <div className="flex">
                                <span className="w-[50%] lg:w-[40%]">Current Status:</span>
                                <span className="text-[#13AA48] font-semibold">Ongoing</span>
                            </div>
                            </div>

                            <div className="mt-6">
                                <a href="#" className="flex items-center gap-2 font-semibold" onClick={handleUniversity}>View All Members <FontAwesomeIcon icon={faArrowRight} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bottom-2 right-2">
                        <img src='/assets/Others/d5artstamp.png'></img>
                    </div>
                </div>

                {isUniversity && (
                    <div className='fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10'
                        data-aos='fade-in' data-aos-duration='1000'
                    >
                        <div className='md:w-[60%] w-[90%] mx-auto' >
                            <div className=" flex md:flex-row flex-col gap-5 md:gap-0 items-center text-lg justify-between mt-10">
                                <div className="bg-white/20 py-1 rounded-full">
                                    <p className="px-3 text-sm font-medium">
                                        Show:
                                        <select className="ml-2 font-semibold outline-none bg-transparent">
                                            <option className="text-black">Today</option>
                                            <option className="text-black">This Week</option>
                                            <option className="text-black">This Month</option>
                                        </select>
                                    </p>
                                </div>
                                <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={handleUniversity} className='cursor-pointer'/></div>
                            </div>

                            <div className=" bg-[#FFFFFF] rounded-xl mt-5 ">
                                <div className='text-black flex '>
                                    <div className='w-full bg-[#D9D9D9] rounded-tl-xl p-4'>
                                        <p className='w-full text-lg font-semibold text-center '>University name</p>
                                    </div>
                                    <div className='w-full p-4'>
                                        <p className='w-full text-lg font-semibold text-center '>Individual name</p>
                                    </div>
                                </div>
                                <div className='w-full overflow-hidden overflow-x-auto overflow-y-auto rounded-xl'>
                                    <table className="w-full">
                                        <thead className='w-full bg-[#d9d9d960]' >
                                            <tr className="w-full text-black border-b border-white/20">
                                                <th className="py-5 px-10 whitespace-nowrap">Name</th>
                                                <th className="py-5 px-10 whitespace-nowrap">Place</th>
                                                <th className="py-5 px-10 whitespace-nowrap">Overall Points</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {currentEntities.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5" className="text-center py-5 font-bold">
                                                        No Transaction History found
                                                    </td>
                                                </tr>
                                            ) : (
                                                currentEntities.map((data, index) => (

                                                    <tr key={index} className="border-y-8 border-white group cursor-pointer text-black bg-[#D9D9D938] " >
                                                        <td className="py-4 font-medium text-[#363A44]  text-sm text-center whitespace-nowrap">
                                                            {data.name}
                                                        </td>
                                                        <td className="py-4 text-center font-medium text-sm whitespace-normal">
                                                            {data.rank}
                                                        </td>
                                                        <td className="py-4 text-center text-sm whitespace-nowrap">
                                                            {data.coe_points}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}

                                            {Array.from({ length: emptyRowCount }).map((_, index) => (
                                                <tr key={`empty-${index}`} className="h-10">
                                                    <td>&nbsp;</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className=" flex justify-end px-5 text-xs pt-3">
                                <button
                                    className="bg-black/50 rounded-md px-5 py-1 mr-3 z-10"
                                    onClick={handlePrevClick}
                                    disabled={currentPage === 1}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                <button
                                    className="bg-black/50 rounded-md px-5 py-1 z-10"
                                    onClick={handleNextClick}
                                    disabled={currentEntities.length < entitiesPerPage}
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>


            <div className='sec-2 py-10 w-[90%] mx-auto'>
                <h2 className="text-2xl font-bold mb-4">My COE Status</h2>
                <div className='flex items-center relative'>
                    <div className='rounded-xl h-2.5 w-full bg-black/15'>
                        <div className='bg-gradient-to-r from-[#00768B] to-[#F29D38]  h-2.5 rounded-xl'
                            style={{ width: `${overallProgress}` }}>
                        </div>
                    </div>
                    <div className="flex justify-between absolute w-full">
                        {[...Array(6)].map((_, index) => {
                            const position = (index + 1) * (100 / 6);
                            const isFilled = position <= overallProgress;

                            return (
                                <div
                                    key={index}
                                    className={`h-5 w-5 rounded-full flex items-center ${isFilled ? 'bg-[#0E2DA7]' : 'bg-[#0E2DA7]/50'
                                        }`}
                                >
                                    {!isFilled && (
                                        <div className="m-auto h-2.5 w-2.5 bg-gray-100 rounded-full"></div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='flex justify-between mt-3'>
                    <p>Month 1</p>
                    <p>Month 2</p>
                    <p>Month 3</p>
                    <p>Month 4</p>
                    <p>Month 5</p>
                    <p>Month 6</p>
                </div>
            </div>


            <div className='sec-3 py-10 w-[90%] mx-auto'>
                <h2 className="text-2xl text-start font-bold mb-4">Schedules</h2>

                <div className='flex flex-col lg:flex-row gap-5 lg:gap-0'>
                <div className='w-[100%] lg:w-[50%] md:px-5'>
            <div className="flex justify-around mb-6">
              {weekDates.map((currentDate, index) => (
                <div
                  key={currentDate}
                  onClick={() => setSelectedDate(currentDate)}
                  className={`w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer ${currentDate === selectedDate ?
                    "bg-gradient-to-br from-blue-400 via-purple-400 to-pink-400 text-white font-bold"
                    : "bg-gray-200 text-gray-600"
                    }`}
                >
                  <span>{currentDate}</span>
                  <span>{weekDays[index]}</span>
                </div>
              ))}
            </div>
  
            <div className="p-4 space-y-4">
              {filteredEvents.map((event, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <FontAwesomeIcon icon={faCalendar} />
                    <div>
                      <p className={`text-lg ${event === earliestTask ? 'font-bold' : ''}`}>{event.title}</p>
                      <p className={`text-sm text-gray-500 ${event === earliestTask ? 'font-bold' : ''}`}>
                        {new Date(event.scheduled_at).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleJoinMeeting(event)}
                    className={`p-2 rounded ${event === earliestTask ? ' font-bold' : ''}`}
                  >
                    Join Now
                  </button>
                </div>
              ))}
              <Toaster  />
            </div>
            
          </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/UserProfile/userview.png' className='w-[75%] mx-auto' data-aos='flip-right' data-aos-duration='1500'></img>
                    </div>
                </div>
            </div>
            <div className='sec-4 py-10'>
                <h2 className="text-2xl text-center font-bold mb-4">Leaderboard</h2>
                <p className='text-center w-[90%] mx-auto'>The top two performer will get a scholarship reward for the next month engagement</p>
                <Leaderboard />
            </div>
            <div className="sec-5 py-10 w-[90%] mx-auto">
                <h1 className="text-3xl font-bold text-center mb-4">Reach us</h1>
                <p className='text-center'>
                    If you have any queries/concern feel free to reach us, your query will be addressed within 24 hours
                </p>

                <div className="flex flex-col lg:flex-row gap-8 lg:gap-0 items-center mt-7">
                    <div className="w-[100%] lg:w-[50%]">
                        <img
                            src="/assets/Others/mailbox.png"
                            alt="Decorative mailbox illustration"
                            className="w-[100%] md:w-[70%] mx-auto"
                            data-aos='flip-left' data-aos-duration='1500'
                        />
                    </div>

                    <div className="w-[100%] lg:w-[50%]">
                        <form className="bg-gray-200 rounded-lg 2xl:w-[90%] mx-auto shadow-md border space-y-6 p-7" data-aos='fade-up' data-aos-duration='1500'>
                            <div className="grid">
                                <label>Full Name:</label>
                                <input
                                    className='bg-transparent pb-2 outline-none border-b border-black border-dotted w-[100%]'
                                    required
                                />
                            </div>
                            <div className="grid md:grid-cols-2 gap-5">
                                <div className="grid">
                                    <label>Mail ID:</label>
                                    <input
                                        type="email"
                                        className='bg-transparent pb-2 outline-none border-b border-black border-dotted w-[100%]'
                                        required
                                    />
                                </div>
                                <div className="grid">
                                    <label>Mobile :</label>
                                    <input
                                        type="tel"
                                        className='bg-transparent pb-2 outline-none border-b border-black border-dotted w-[100%]'
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid">
                                <label>Select category</label>
                                <Select
                                    options={category}
                                    styles={CustomStyle}
                                    className=' border-b border-black border-dotted'
                                    placeholder=''>
                                </Select>
                            </div>
                            <div className="grid">
                                <label>Describe your Query</label>
                                <textarea
                                    className="bg-transparent pb-2 outline-none border-b border-black border-dotted w-[100%]"
                                    required
                                />
                            </div>
                            <div className='flex justify-center'>
                                <button type="submit" className="shadow-md bg-[#30303030] font-semibold px-5 py-2 rounded-full duration-300 border border-black/5 active:translate-y-1">
                                    Submit now
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
