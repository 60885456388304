import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import '../Style/Style.css';
import Select from 'react-select';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import toast, { Toaster } from "react-hot-toast";


export default function CareerHub() {
    const [isApply, setApply] = useState(false);
    const [jobPostings, setJobPostings] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filteredJobPostings, setFilteredJobPostings] = useState([]);
    const [durationFilter, setDurationFilter] = useState('');
    const [engagementFilter, setEngagementFilter] = useState('');
    const [sortFilter, setSortFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState('');



    const handleApply = () => {
        setApply(!isApply);
    }

    // const handleApply = (jobId) => {
    //     console.log(`Apply for job ID: ${jobId}`); // Replace with your actual apply logic
    // };

    const Duration = [
        { value: 'short', label: 'Short-Term (< 3 months)' },
        { value: 'medium', label: 'Medium-Term (3-6 months)' },
        { value: 'long', label: 'Long-Term (> 6 months)' },
    ];

    const Engagement = [
        { value: 'Full-Time', label: 'Full-Time' },
        { value: 'Part-Time', label: 'Part-Time' },
        { value: 'Freelance', label: 'Freelance' },
        { value: 'Internship', label: 'Internship' },
    ];

    const Sorted = [
        { value: 'relevance', label: 'Relevance' },
        { value: 'latest', label: 'Latest' },
        { value: 'most_applied', label: 'Most Applied' },
    ];


    const CustomStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'black',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000', // Color of the option text
            ':active': {
                backgroundColor: '#d3d3d3', // Light gray when clicked
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '7rem',
            background: '#fff', // Color of the typed input text
        }),
    };


    ///

    // useEffect(() => {
    //     const fetch_jobPostings = async () => {
    //         try {

    //                 const response = await axios.get(`${SERVER_URL}/api/fetch_jobPostingas`);
    //                 const fetch_jobPostings = response.data.data;

    //                 console.log("fetch_jobPostings",response.data.data);
    //                 setJobPostings(fetch_jobPostings);




    //         } catch (error) {
    //             console.error("Error fetching job postings:", error);
    //         }
    //     };

    //     fetch_jobPostings();
    // }, []);

    // useEffect(() => {
    //     const fetchJobPostings = async () => {
    //         try {
    //             const response = await axios.get(`${SERVER_URL}/api/fetch_jobPostings`);
    //             console.log("fetch_jobPostings",response.data.data);
    //             setJobPostings(response.data.data || []); 
    //         } catch (error) {
    //             console.error('Error fetching job postings:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchJobPostings();
    // }, []);


    useEffect(() => {
        const fetchJobPostings = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/fetch_jobPostings`);
                const jobData = response.data.data || [];
                console.log("fetch_jobPostings", jobData);

                let filteredData = jobData;


                // Filter by search query (if applicable)
                if (searchQuery) {
                    filteredData = filteredData.filter(posting =>
                        posting.role.toLowerCase().includes(searchQuery.toLowerCase())

                    );
                }


                // Filter by Duration (if applicable)
                if (durationFilter) {
                    filteredData = filteredData.filter(posting => {
                        if (durationFilter === 'short') {
                            return posting.duration && ['1-month', '2-months'].includes(posting.duration);
                        } else if (durationFilter === 'medium') {
                            return posting.duration && ['3-months', '4-months', '6-months'].includes(posting.duration);
                        } else if (durationFilter === 'long') {
                            return posting.duration && ['7-months', '9-months'].includes(posting.duration);
                        }
                        return true;
                    });
                }

                // Filter by Engagement (if applicable)
                if (engagementFilter) {
                    filteredData = filteredData.filter(posting => posting.engagement === engagementFilter);
                }

                // Sort the data (if applicable)
                if (sortFilter === 'latest') {
                    filteredData = filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                } else if (sortFilter === 'most_applied') {
                    filteredData = filteredData.sort((a, b) => b.applied_count - a.applied_count);
                }

                setJobPostings(jobData); // To display original unfiltered job postings
                setFilteredJobPostings(filteredData); // To display filtered job postings

            } catch (error) {
                console.error('Error fetching job postings:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobPostings();
    }, [durationFilter, engagementFilter, sortFilter, searchQuery]);



    const handleDurationChange = (selectedOption) => {
        setDurationFilter(selectedOption ? selectedOption.value : '');
    };

    const handleEngagementChange = (selectedOption) => {
        setEngagementFilter(selectedOption ? selectedOption.value : '');
    };

    const handleSortChange = (selectedOption) => {
        setSortFilter(selectedOption ? selectedOption.value : 'relevance');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };


    return (
        <div className='overflow-hidden'>
            <Header />
            <div className='sec-1 mb-10 mt-5'>
                <div className='flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 w-[90%] mx-auto'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/Others/castle.png' className='w-[100%] md:w-[70%] mx-auto' data-aos='flip-right' data-aos-duration='1500'></img>
                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <h1 className='text-3xl font-bold'>Be the wizardry</h1>
                        <p className='mt-3'>Embark on magical internship opportunities and thrilling projects, unlocking tomorrow’s skills and rising as a hero in the digital age. Your adventure begins now</p>
                        <button className='flex items-center gap-3 mt-7'>
                            <button className='w-8 h-8 rounded-full border border-black flex items-center justify-center'>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                            Seek for Opportunity
                        </button>
                    </div>
                </div>
            </div>
            <div className='sec-2 py-10 bg-[#D9D9D95E] grid content-center min-h-screen'>
                <div className='w-[90%] md:w-[50%] mx-auto bg-white rounded-full py-3 px-7 relative'>
                    <h1 className='text-sm'>Find Open Projects and Internship</h1>
                    <input
                        type='search'
                        className='w-[100%] outline-none mb-3 py-1'
                        placeholder='Search by internship role/ref. code'
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button className='absolute top-[50%] -translate-y-[50%] right-3 w-10 h-10 rounded-full bg-gray-400'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='text-white' />
                    </button>
                </div>

                <div className='flex flex-col lg:flex-row justify-center gap-3 mt-3 mb-5 w-[90%] mx-auto'>
                    <div className='flex gap-2 items-center'>
                        <span className='font-bold'>Duration :</span>
                        <Select
                            options={Duration}
                            styles={CustomStyle}
                            placeholder='Any'
                            onChange={handleDurationChange}
                        />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <span className='font-bold'>Engagement :</span>
                        <Select
                            options={Engagement}
                            styles={CustomStyle}
                            placeholder='Any'
                            onChange={handleEngagementChange}
                        />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <span className='font-bold'>Sort by :</span>
                        <Select
                            options={Sorted}
                            styles={CustomStyle}
                            placeholder='Relevance'
                            onChange={handleSortChange}
                        />
                    </div>
                </div>
                <div className='w-[90%] mx-auto flex flex-col lg:flex-row gap-8 lg:gap-0'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <h1 className='text-3xl font-bold text-center'>Be the Frontier</h1>
                        <img src='/assets/Mascots/wizard5.png' className='w-[75%] mx-auto' data-aos='flip-right' data-aos-duration='1500'></img>
                    </div>
                    <div className='w-[100%] lg:w-[50%]  gap-7 scroll-demo duration-500 h-[560px] 2xl:h-[612px] flex flex-col gap-5'>
                        {loading ? (
                            <p>Loading...</p>
                        ) : filteredJobPostings.length === 0 ? (
                            <div className='text-center'>
                                <p>No jobs found</p>
                                <img src="/no-jobs-found.png" alt="No Jobs Found" />
                            </div>
                        ) : (
                            filteredJobPostings.map(posting => (
                                <div key={posting.id} className='px-10 py-3 flex flex-col lg:flex-row justify-between gap-5 lg:gap-0 border-2 bg-white rounded-[2.5rem] shadow-lg duration-500 hover:-translate-y-2 hover:shadow-black/20'>
                                    <div className='grid content-center gap-3 md:w-[50%]'>
                                        <h1 className='text-[#0E2DA7] font-bold text-lg 2xl:text-xl'>{posting.role}</h1>
                                        <p>{posting.companyName}</p>
                                        <div className='flex flex-wrap gap-3 text-sm'>
                                            {posting.skills.split(',').map((skill, index) => (
                                                <div key={index} className='bg-red-100 px-2 py-0.5 rounded'>
                                                    {skill.trim()}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='grid content-center gap-3 text-xs 2xl:text-sm text-center lg:text-end'>
                                        <p>Posted on: <span className='font-semibold'>{new Date(posting.created_at).toLocaleDateString()}</span></p>
                                        <button className='font-bold text-base 2xl:text-lg' onClick={() => handleApply()}>Apply Now</button>
                                        <p>Applied: <span>{posting.applied_count}</span></p>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {isApply && (
                    <div className='fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10'
                        data-aos='fade-in' data-aos-duration='1000'
                    >
                        <div className="relative w-[90%] mx-auto max-w-sm text-black bg-white rounded-xl p-5 font-inter">
                            <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={handleApply} className='absolute -top-6 right-0 cursor-pointer' /></div>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='font-medium'>Are you sure you want to apply now?</p>
                                <button className='mt-5 bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] rounded-full px-7 py-2 font-semibold text-white sm:text-base text-sm '>Apply Now</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
