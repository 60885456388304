












// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchUserMeetings, selectMeetings, selectMeetingsStatus } from '../../Slice/meetingsSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendar } from '@fortawesome/free-solid-svg-icons';

// const ScheduleComponent = () => {
//   const  userId  = 26142




//   const dispatch = useDispatch();
//   const meetings = useSelector(selectMeetings);
//   const meetingsStatus = useSelector(selectMeetingsStatus);

//   const [selectedDate, setSelectedDate] = useState(null);
//   const [weekDates, setWeekDates] = useState([]);
//   const [weekDays, setWeekDays] = useState([]);

//   useEffect(() => {
//     // Fetch the user meetings from the server when the component mounts
//     dispatch(fetchUserMeetings(userId));

//     // Get the current date and calculate the current week's start date (Sunday)
//     const currentDate = new Date();
//     const currentDay = currentDate.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday
//     const startOfWeek = new Date(currentDate);
//     startOfWeek.setDate(currentDate.getDate() - currentDay); // Move to Sunday of this week

//     const dates = [];
//     const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

//     // Calculate the dates for the current week
//     for (let i = 0; i < 7; i++) {
//       const dayDate = new Date(startOfWeek);
//       dayDate.setDate(startOfWeek.getDate() + i);
//       dates.push(dayDate.getDate());
//     }

//     setWeekDates(dates);
//     setWeekDays(days);
//     setSelectedDate(dates[currentDay]); // Set the selected date to today
//   }, [dispatch, userId]);

//   // If meetings are still loading
//   if (meetingsStatus === 'loading') {
//     return <div>Loading...</div>;
//   }

//   const parseTimeToDate = (timeStr) => {
//     if (!timeStr) return new Date(); // If no time is provided, return the current date
//     const [hours, minutes] = timeStr.split(":");
//     return new Date(1970, 0, 1, parseInt(hours, 10), parseInt(minutes, 10)); // Convert to a Date object for easy comparison
//   };

//   // Get the earliest task for styling
//   const getEarliestTask = (tasks) => {
//     return tasks.reduce((earliest, task) => {
//       if (task.time) {  // Check if task.time is defined
//         const taskTime = parseTimeToDate(task.time.split(" ")[0]);
//         const earliestTime = parseTimeToDate(earliest.time.split(" ")[0]);
//         return taskTime < earliestTime ? task : earliest;
//       }
//       return earliest;  // If task.time is undefined, return the current earliest task
//     });
//   };

//   const handleClickDate = (date) => {
//     setSelectedDate(date);
//   };

//   // Filter the events by comparing only the day part of the scheduled date
//   const filteredEvents = meetings.filter((event) => {
//     const eventDate = new Date(event.scheduled_at).getDate(); // Get the day of the month
//     return eventDate === selectedDate;
//   });

//   const earliestTask = filteredEvents.length > 0 ? getEarliestTask(filteredEvents) : null;

//   return (
//     <div className='sec-3 py-10 w-[90%] mx-auto'>
//       <h2 className="text-2xl text-start font-bold mb-4">Schedules</h2>

//       <div className='flex flex-col lg:flex-row gap-5 lg:gap-0'>
//         <div className='w-[100%] lg:w-[50%] md:px-5'>
//           <div className="flex justify-around mb-6">
//             {weekDates.map((currentDate, index) => (
//               <div
//                 key={currentDate}
//                 onClick={() => handleClickDate(currentDate)}
//                 className={`w-16 h-16 flex flex-col items-center justify-center rounded-full cursor-pointer ${currentDate === selectedDate ?
//                   "bg-gradient-to-br from-blue-400 via-purple-400 to-pink-400 text-white font-bold"
//                   : "bg-gray-200 text-gray-600"
//                   }`}
//               >
//                 <span>{currentDate}</span>
//                 <span>{weekDays[index]}</span>
//               </div>
//             ))}
//           </div>

//           <div className="p-4 space-y-4">
//             {filteredEvents.map((event, index) => (
//               <div key={index} className="flex items-center justify-between">
//                 <div className="flex items-center space-x-3">
//                   <FontAwesomeIcon icon={faCalendar} />
//                   <div>
//                     <p className={`text-lg ${event === earliestTask ? 'font-bold' : ''}`}>{event.title}</p>
//                     <p className={`text-sm text-gray-500 ${event === earliestTask ? 'font-bold' : ''}`}>
//                       {new Date(event.scheduled_at).toLocaleTimeString()}
//                     </p>
//                   </div>
//                 </div>
//                 <button className={`p-2 rounded ${event === earliestTask ? ' font-bold' : ''}`}>
//                   Join Now
//                 </button>
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className='w-[100%] lg:w-[50%]'>
//           <img src='/assets/UserProfile/userview.png' className='w-[75%] mx-auto' data-aos='flip-right' data-aos-duration='1500'></img>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ScheduleComponent;











// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProjects } from '../../Slice/projectSlice';

// const ProjectList = () => {
//     const dispatch = useDispatch();
//     const { projects, status, error } = useSelector((state) => state.projects);

//     console.log("projects, status, error ",projects, status, error )
// const userId = 26142
//     useEffect(() => {
//         dispatch(fetchProjects(userId));
//     }, [dispatch,userId]);

//     return (
//         <div className="container mx-auto p-4">
//             <h1 className="text-2xl font-bold mb-4">Projects</h1>
//             {status === 'loading' && <p>Loading...</p>}
//             {status === 'failed' && <p>Error: {error}</p>}
//             {status === 'succeeded' && (
//                 <table className="table-auto w-full border-collapse border border-gray-200">
//                     <thead>
//                         <tr>
//                             <th className="border px-4 py-2">ID</th>
//                             <th className="border px-4 py-2">Name</th>
//                             <th className="border px-4 py-2">Description</th>
//                             <th className="border px-4 py-2">Start Date</th>
//                             <th className="border px-4 py-2">End Date</th>
//                             <th className="border px-4 py-2">Seeker Name</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {projects.map((project) => (
//                             <tr key={project.project_id}>
//                                 <td className="border px-4 py-2">{project.project_id}</td>
//                                 <td className="border px-4 py-2">{project.project_name}</td>
//                                 <td className="border px-4 py-2">{project.project_description}</td>
//                                 <td className="border px-4 py-2">{project.start_date}</td>
//                                 <td className="border px-4 py-2">{project.end_date}</td>
//                                 <td className="border px-4 py-2">{project.seeker_name}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             )}
//         </div>
//     );
// };

// export default ProjectList;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const Questionnaire = () => {
    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [result, setResult] = useState(null);
    const userId = 1;

    useEffect(() => {
        toast('Welcome to PreAssessment!');
        axios
            .get('http://localhost:4000/api/jordan-question')
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.error('Error fetching questions:', error);
                toast.error('Failed to load questions. Please try again.');
            });
    }, []);

    const handleSubmit = () => {
        toast('Your Assessment is submitting...');
        
        const formattedResponses = Object.entries(responses).map(([questionId, answer]) => ({
            questionId: Number(questionId),
            answer,
            correct: questions.find(q => q.id === Number(questionId)).correct_answer === answer,
        }));

        axios
            .post('http://localhost:4000/api/jordan-answer-set', { userId, responses: formattedResponses })
            .then(() => {
                return axios.post('http://localhost:4000/api/jordan-answer-calculate', { userId });
            })
            .then(response => {
                setResult(response.data);
                toast.success('Assessment is successfully completed!');
            })
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    toast.error('You already submitted the assessment.');
                    setTimeout(() => {
                        window.location.href = '/'; 
                    }, 2000);
                } else {
                    console.error('Submission error:', error);
                    toast.error('Something went wrong. Please try again.');
                }
            });
    };

    return (
        <div className="p-6">
            <Toaster />
            <h1 className="text-2xl font-bold mb-4">Assessment</h1>
            {questions.map(question => (
                <div key={question.id} className="mb-4">
                    <p className="text-lg font-medium">{question.question_text}</p>
                    {(() => {
                        try {
                            const options = question.options ? JSON.parse(question.options) : [];
                            return options.map(option => (
                                <label key={option} className="block">
                                    <input
                                        type="radio"
                                        name={question.id}
                                        value={option}
                                        onChquestionange={() => setResponses({ ...responses, [question.id]: option })}
                                    />{' '}
                                    {option}
                                </label>
                            ));
                        } catch (error) {
                            console.error('Invalid JSON for options', error);
                            return <p>Options are unavailable</p>;
                        }
                    })()}
                </div>
            ))}
            <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
                Submit
            </button>
            {result && (
                <div className="mt-6">
                    <h2 className="text-xl font-bold">Your Results</h2>
                    <p>Personality Type: {result.personalityType}</p>
                    <p>Total Score: {result.totalScore}</p>
                </div>
            )}
        </div>
    );
};

export default Questionnaire;
