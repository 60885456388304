import React from 'react'

const Mentorship = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-14 ' >
         <div>
        <p className='text-3xl font-bold text-[#F29D38] text-center font-space' >Mentorship</p>
        <p className='w-[60%] mx-auto mt-5 text-[#BEC9DA] text-sm text-center' >We’ve got your back! During the hackathon, you’ll have access to mentorship sessions to help you refine your ideas and align with the hackathon’s</p>
        </div>

        <div className='w-[80%] flex md:flex-row flex-col justify-center md:justify-start items-center gap-14' >
           <div className='md:w-1/2 '>
             <div  className='p-3 bg-Fabcbg2 rounded-2xl bg-cover bg-center w-full bg-white/10' >
                 <p className='text-center' >Answer your questions about timelines and structure</p>
             </div>
             <div  className='p-3 bg-Fabcbg2 rounded-2xl bg-cover bg-center w-full bg-white/10 mt-5' >
                 <p className='text-center' >Get clear guidance on what to deliver</p>
             </div>
             <div className='p-3 bg-Fabcbg2 rounded-2xl bg-cover bg-center w-full bg-white/10 mt-5' >
                 <p className='text-center' >Stay focused and on track with deadlines</p>
             </div>
           </div>

           <div className='md:w-1/2' >
             <img src='../assets/jordanevent/comingsoon.svg' className='w-9/12 mx-auto' />
           </div>
        </div>
    </div>
  )
}

export default Mentorship