import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL = 'http://localhost:5000';

export const fetchInstituteDetails = createAsyncThunk(
  'institute/fetchDetails',
  async (instituteId) => {
    const response = await axios.get(`${SERVER_URL}/api/coe/institute-details/${instituteId}`);
    console.log("response institute-details",response)
    return response.data;
  }
);

const initialState = {
    instituteName: '',
    instituteAddress:'',
    totalUsers: 0,
    instituteRank: 0,
    usersWithRank: [],
    status: 'idle', // 'loading', 'succeeded', 'failed'
    error: null,
  };


const instituteSlice = createSlice({
    name: 'institute',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchInstituteDetails.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchInstituteDetails.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.instituteName = action.payload.institute_name;
          state.instituteAddress = action.payload.institute_address;
          state.totalUsers = action.payload.total_users_in_institute;
          state.instituteRank = action.payload.institute_rank;
          state.usersWithRank = action.payload.users_with_rank;
        })
        .addCase(fetchInstituteDetails.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    },
  });
  
  export default instituteSlice.reducer;