import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const SERVER_URL ="http://localhost:5000"

export const fetchUserRank = createAsyncThunk(
  'rank/fetchUserRank',
  async (userId) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/coe/user-rank/${userId}`);
      return response.data.user || {};  
    } catch (error) {
      throw Error('Error fetching user rank');
    }
  }
);

const rankSlice = createSlice({
  name: 'rank',
  initialState: {
    user_rank: null, // { user_id, coe_points, organization_rank, global_rank }
    rank_status: 'idle',
    rank_error: null,
  },
  reducers: {
    resetRank: (state) => {
      state.user_rank = null;
      state.rank_status = 'idle';
      state.rank_error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRank.pending, (state) => {
        state.rank_status = 'loading';
      })
      .addCase(fetchUserRank.fulfilled, (state, action) => {
        state.rank_status = 'succeeded';
        state.user_rank = action.payload;
      })
      .addCase(fetchUserRank.rejected, (state, action) => {
        state.rank_status = 'failed';
        state.rank_error = action.error.message;
      });
  },
});

export const { resetRank } = rankSlice.actions;
export default rankSlice.reducer;
