import React,{useEffect, useRef, useState} from 'react';
import { motion } from "framer-motion";

const AnimatedText = ({
    text,
    duration = 2000,
    animateOnLoad = false,
    className = "",
  }) => {
    const numbers = "0101010101110101011100101".split("");
    const getRandomInt = (max) => Math.floor(Math.random() * max);
  
    const [displayText, setDisplayText] = useState(Array(text.length).fill("")); // Initialize empty characters
    const [trigger, setTrigger] = useState(false);
    const elementRef = useRef(null);
   
    const triggerAnimation = () => {
      setTrigger(true);
    };
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && animateOnLoad) {
            triggerAnimation();
          }
        },
        { threshold: 0.1 }
      );
  
      if (elementRef.current) observer.observe(elementRef.current);
  
      return () => {
        if (elementRef.current) observer.unobserve(elementRef.current);
      };
    }, [animateOnLoad]);
  
    useEffect(() => {
      if (!trigger) return;
  
      const animateCharacter = (index) => {
        if (index >= text.length) return;
  
        let charInterval;
        charInterval = setInterval(() => {
          setDisplayText((currentText) =>
            currentText.map((char, i) =>
              i === index ? numbers[getRandomInt(10)] : char
            )
          );
        }, 30); // Speed of randomization
  
        setTimeout(() => {
          clearInterval(charInterval);
          setDisplayText((currentText) =>
            currentText.map((char, i) => (i === index ? text[i] : char))
          );
          animateCharacter(index + 1);
        }, duration / text.length);
      };
  
      animateCharacter(0);
    }, [trigger, text, duration]);
  
  
  
    return (
      <motion.p
        ref={elementRef}
        className={className}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {displayText.join("")}
      </motion.p>
    );
  };

export default AnimatedText

