import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import '../Style/Style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import Aos from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import toast, { Toaster } from "react-hot-toast";



Aos.init();

export default function Home() {




  const [isActive, setActive] = useState('0');
  const [LeaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);







  const handleTabClick = (tab) => {
    setActive(tab)
  }

  //* leaderboard *//


  const sampleLeaderboardData = [
    { avatar: 'image 3yr.png', nft_Id: '0x282CHET', rank: 1, coe_points: 1500, color: 'bg-yellow-400' },
    { avatar: 'image 90.png', nft_Id: '0X57EVE7H', rank: 2, coe_points: 1200, color: 'bg-lime-400' },
    { avatar: 'image 90 (1).png', nft_Id: '0XG2IUG3IO', rank: 3, coe_points: 500, color: 'bg-teal-400' },
    { avatar: 'image 3yr.png', nft_Id: '0XH2U29JO', rank: 4, coe_points: 300, color: 'bg-gray-300' },
    { avatar: 'image 90.png', nft_Id: '0XA31REDA', rank: 5, coe_points: 200, color: 'bg-orange-300' },
  ];

  const FinalLeaderboardData = LeaderboardData && LeaderboardData.length > 0 ? LeaderboardData : sampleLeaderboardData;

  // to determine the height of the rank
  const maxPoints = Math.max(...LeaderboardData.slice(0, 5).map(item => item.coe_points));



  const [isLeaderboard, setLeaderboard] = useState(false);

  const handleLeaderboard = () => {
    setLeaderboard(!isLeaderboard);
  }




  useEffect(() => {
    const fetchUserRank = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/user_leaderboard`);
        console.log(response);
        setLeaderboardData(response.data.leaderboard || []);
        setLoading(false);
      } catch (error) {
        setError('Error fetching user rank data');
        setLoading(false);
      }
    };

    fetchUserRank();
  }, []);


  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);


  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = FinalLeaderboardData.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  // Calculate the no of rows
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };


  //* Enquire form *//

  const [formData, setFormData] = useState({
    name: "",
    collegeName: "",
    email: "",
    designation: "",
    description: "",
    type: "",
  });

  const [errors, setErrors] = useState({});
  const [isEnquireForm, setIsEnquireForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = "Name is required.";
    }
    if (!formData.collegeName.trim()) {
      formErrors.collegeName = "College Name is required.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid.";
    }
    if (!formData.designation.trim()) {
      formErrors.designation = "Designation is required.";
    }
    if (!formData.description.trim()) {
      formErrors.description = "Description is required.";
    }
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const response = await axios.post(`${SERVER_URL}/api/enquire_form`, formData);
      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        setTimeout(() => {
          window.location.reload(); 
          setIsEnquireForm(false); 
        }, 2000); 
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.error); 
      } else {
        console.error("Error submitting form:", error);
        toast.error("Error submitting the form. Please try again.");
      }
    }
  };
  

  const handleEnquireForm = (type) => {
    setFormData((prev) => ({ ...prev, type }));
    setIsEnquireForm(true);
  };

  const closeEnquireForm = () => {
    setIsEnquireForm(false);
  };
  








  return (
    <div className=' overflow-hidden  2xl:text-lg'>
      <div className='sec-1 '>
        <Header />
        <Toaster />
        <div className='flex flex-col lg:flex-row items-center justify-around w-[90%] mx-auto py-5 lg:py-0'>
          <div className='w-[100%] lg:w-[45%] ml-auto relative'>
            <h1 className='text-4xl lg:text-5xl 2xl:text-6xl lg:leading-[60px] 2xl:leading-[75px] font-semibold'>Embark on Your Journey to Excellence with
              <span className='yellow-gradient'> D5art</span>
            </h1>
            <p className='text-gray-500 mt-3'>Join the Realm of Limitless Possibilities in the Web3 Universe</p>
            <p className='mt-10 font-semibold'>Explore My COE <FontAwesomeIcon icon={faArrowRight} className='ml-2' /></p>
            <div className='absolute text-3xl right-10 top-[80%]' style={{ transform: 'rotate(-45deg)' }}>
              <i class='fa-solid fa-star' data-aos='zoom-in' data-aos-duration='1000'></i>
            </div>
          </div>
          <div className='w-[100%] lg:w-[50%]' data-aos='flip-left' data-aos-duration='1500'>
            <img src='/assets/Mascots/Heroright.png' className='lg:translate-x-[25%]'></img>
          </div>
        </div>
      </div>
      <div className='sec-2 py-10'>
        <div className='w-[90%] mx-auto text-center relative flex items-center'>
          <div className='z-10'>
            <h1 className='text-3xl font-semibold lg:w-[60%] mx-auto'>Discover the Citadel of Mastery: D5art’s Path to <span className='blue-gradient'>WEB 3.0</span> Excellence</h1>
            <p className='mt-7'>In the mystical lands of D5art COE, a new era of talent is rising. Job seekers embark on heroic quests to unlock their potential, while recruiters, the Gatekeepers of Opportunity, seek those with ancient skills. With enchanted tools for skill validation and pathways that protect your identity, D5art COE is where legends are born and destinies are forged.</p>
            <img src='/assets/Others/citadel1.png' className='w-[100%] md:w-[70%] lg:w-[35%] mx-auto' data-aos='flip-left' data-aos-duration='2000'></img>
          </div>
          <div className='absolute z-0 bg-[#4B53FC]/15 w-[50rem] h-[30rem] blur-3xl rounded-full left-[50%] -translate-x-[50%]'></div>
        </div>
      </div>
      <div className='sec-3 py-10'>
        <div className='w-[90%] mx-auto text-center relative'>
          <h1 className='text-3xl font-semibold lg:w-[60%] mx-auto'>Council of <span className='blue-gradient'>Champions</span>: Leading the Quest for Excellence</h1>
          <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 py-10">
            <div className="w-full md:w-2/3 flex justify-center">
              <div className="flex items-end space-x-4">
                {FinalLeaderboardData && FinalLeaderboardData.length > 0 ? (
                  FinalLeaderboardData.slice(0, 5).map((item, index) => {
                    // Dynamically assign colors based on rank
                    let color = '';
                    if (item.rank === 1) {
                      color = 'bg-yellow-400';
                    } else if (item.rank === 2) {
                      color = 'bg-lime-400';
                    } else if (item.rank === 3) {
                      color = 'bg-teal-400';
                    } else if (item.rank === 4) {
                      color = 'bg-gray-300';
                    } else if (item.rank === 5) {
                      color = 'bg-orange-300';
                    } else {
                      color = 'bg-gray-200';
                    }

                    // Calculate height dynamically based on maxPoints
                    const maxPoints = Math.max(...FinalLeaderboardData.slice(0, 5).map(item => item.coe_points));
                    console.log(maxPoints)
                    const scaledHeight = Math.min(Math.max((item.coe_points / maxPoints) * 10, 2), 10); // 10 is the max height

                    return (
                      <div key={index} className="flex flex-col items-center">
                        <div
                          className={`w-16 ${color} rounded-t-lg flex items-center justify-center text-white`}
                          style={{ height: `${scaledHeight}rem` }}
                        >
                          <span className="font-semibold">
                            {item.rank === 1 ? '1st' : item.rank === 2 ? '2nd' : item.rank === 3 ? '3rd' : `${item.rank}th`}
                          </span>
                        </div>
                        <span className="mt-2 text-sm text-gray-600">{item.coe_points} Pts</span>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center py-12 text-gray-600">No leaderboard data available</div>
                )}

              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <table className="w-full text-left">
                <thead>
                  <tr className="text-gray-500 bg-gray-100 text-sm rounded-md">
                    <th className="py-2 pl-4">Skill ID</th>
                    <th className="py-2">Rank</th>
                    <th className="py-2">Points</th>
                  </tr>
                </thead>
                <tbody>
                  {FinalLeaderboardData.length > 0 ? (
                    FinalLeaderboardData.slice(0, 5).map((item, index) => (
                      <tr key={index} className="text-gray-700 text-sm">
                        <td className="py-2 pl-4 flex items-center">
                          <img
                            src={`/assets/${item.avatar}`}
                            alt={item.avatar}
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          {item.nft_Id.slice(0, 12)}
                        </td>
                        <td>{item.rank}</td>
                        <td>{item.coe_points}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-12">No leaderboard data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full flex justify-center mt-4">
            <button className="font-semibold flex items-center space-x-1" onClick={handleLeaderboard}>
              <span>Explore Leaderboard</span>
              <FontAwesomeIcon icon={faArrowRight} className="pl-2" />
            </button>
          </div>
        </div>
        {isLeaderboard && (
          <div className='fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10'
            data-aos='fade-in' data-aos-duration='1000'
          >
            <div className='md:w-[60%] w-[90%] mx-auto' >
              <div className=" flex md:flex-row flex-col gap-5 md:gap-0 items-center text-lg justify-between mt-10">
                {/* <div className="bg-white/20 py-1 rounded-full">
                  <p className="px-3 text-sm font-medium">
                    Show:
                    <select className="ml-2 font-semibold outline-none bg-transparent">
                      <option className="text-black">Today</option>
                      <option className="text-black">This Week</option>
                      <option className="text-black">This Month</option>
                    </select>
                  </p>
                </div> */}
                 <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={handleLeaderboard} className='absolute  md:right-60 right-5 cursor-pointer' /> </div>
              </div>

              <div className=" bg-[#FFFFFF] rounded-xl mt-5 overflow-hidden overflow-x-auto overflow-y-auto">
                <table className="w-full">
                  <thead className='w-full bg-[#D9D9D9]' >
                    <tr className="w-full text-black border-b border-white/20">
                      <th className="py-5 px-10 whitespace-nowrap">Skill ID</th>
                      <th className="py-5 px-10 whitespace-nowrap">Rank</th>
                      <th className="py-5 px-10 whitespace-nowrap">Points</th>
                    </tr>
                  </thead>

                  <tbody>

                    {currentEntities.map((data, index) => (

                      <tr key={index} className="border-y-8 border-white group cursor-pointer text-black bg-[#D9D9D938] " >
                        <td className="py-4 font-medium text-[#363A44]  text-sm text-center whitespace-nowrap">
                          {data.nft_Id.slice(0,10)}
                        </td>
                        <td className="py-4 text-center font-medium text-sm whitespace-normal">
                          {data.rank}
                        </td>
                        <td className="py-4 text-center text-sm whitespace-nowrap">
                          {data.coe_points}
                        </td>
                      </tr>
                    ))
                    }

                    {Array.from({ length: emptyRowCount }).map((_, index) => (
                      <tr key={`empty-${index}`} className="h-10">
                        <td>&nbsp;</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className=" flex justify-end px-5 text-xs pt-3">
                <button
                  className="bg-black/50 rounded-md px-5 py-1 mr-3 z-10"
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="bg-black/50 rounded-md px-5 py-1 z-10"
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='sec-4 py-10'>
        <div className='w-[100%] relative'>
          <h1 className='text-3xl text-center font-semibold mb-10 mx-auto'>Dedicated <span className='blue-gradient'>D5Pass</span></h1>
          <div className='flex flex-col lg:flex-row gap-5 md:gap-10 items-center w-[90%] mx-auto'>
            <div className='w-[100%] lg:w-[50%]'>
              <img src='/assets/Others/d5passHero.png' className='w-[90%] md:w-[75%] mx-auto lg:ml-auto lg:mx-0' data-aos='flip-left' data-aos-duration='1500'></img>
              <div className='absolute top-[50%] -translate-y-[50%] left-0 w-[30%]'>
                <img src='/assets/Others/d5passHeroleft.png' data-aos='fade-right' data-aos-duration='2000'></img>
              </div>
            </div>
            <div className='w-[100%] lg:w-[40%]'>
              <p className='leading-8'>The D5art Passport for COE Students is a <span className='yellow-gradient font-semibold'>legendary artifact</span>, forged in the halls of excellence. More powerful than a common scroll, it bears the seal of mastery, with every skill and achievement enchanted by expert validators.</p>
              <p className='font-semibold mt-5'>My D5-Pass<FontAwesomeIcon icon={faArrowRight} className='ml-2' /></p>
            </div>
          </div>
        </div>
      </div>
      <div className='sec-5 py-10'>
        <div className='relative'>
          <h1 className='text-3xl text-center font-semibold w-[90%] lg:w-[100%] mx-auto'>Treasures of the <span className='blue-gradient'>Citadel </span>: <br />Our Offerings & Key Takeaways</h1>
          <div className='bg-[#F1F1F1] py-10 mt-10'>
            <div className='w-[90%] mx-auto grid gap-5'>
              <div className='flex flex-col lg:flex-row gap-5'>
                <div className='w-[100%] lg:w-1/3 grid gap-5'>
                  <div className='w-[100%] border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg flex gap-5 items-center bg-white'>
                    <div>
                      <img src='/assets/Others/global opportunity 1.png'></img>
                    </div>
                    <div>
                      <h1 className='font-semibold text-lg'>Global Networking opportunity</h1>
                      <p className='text-sm mt-1 lg:mt-3'>Our Partnership are spread across <span className='yellow-gradient font-semibold'> 3 global regions</span></p>
                    </div>
                  </div>
                  <div className='flex gap-5'>
                    <div className='w-1/2 border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white p-5'>
                      <img src='/assets/Others/projects1.png' className='mx-auto'></img>
                      <p className='font-semibold text-lg text-center'>Real-time project</p>
                      <p className='text-sm mt-1 lg:mt-3'>Access to explore to real - time industrial project</p>
                    </div>
                    <div className='w-1/2 border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white p-5'>
                      <img src='/assets/Others/work1.png' className='mx-auto'></img>
                      <p className='font-semibold text-lg text-center'>Job & Internship</p>
                      <p className='text-sm mt-1 lg:mt-3'>Exclusive access to WEB 3.0 job portal with <span className='yellow-gradient font-semibold'>D pass</span></p>
                    </div>
                  </div>
                </div>
                <div className='w-[100%] lg:w-1/3 border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg px-5 pb-5 bg-white'>
                  <img src='/assets/Others/dna1.png' className='w-[80%] mx-auto'></img>
                  <p className='font-semibold text-lg text-center'>DNA </p>
                  <p className='text-sm mt-1 lg:mt-3 text-center'>Unleash your inner power to <span className='yellow-gradient font-semibold'>fuel your dreams</span> and conquer every challenge in your path. </p>
                </div>
                <div className='w-[100%] lg:w-1/3 flex gap-5'>
                  <div className='w-1/2 grid gap-5'>
                    <div className='border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white flex px-2'>
                      <div className='flex items-end'>
                        <img src='/assets/Others/hi1.png'></img>
                      </div>
                      <div className='grid content-center'>
                        <p className='font-semibold text-lg text-center leading-5'>Hi - Ai Validation</p>
                        <p className='text-sm mt-1 lg:mt-3'>Next-gen validation</p>
                      </div>
                    </div>
                    <div className='border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white'>
                      <p className='font-semibold text-lg text-center mt-5'>Health consultation</p>
                      <p className='text-sm mt-1 lg:mt-3 mx-auto px-4'><span className='yellow-gradient font-semibold'>Personalized health insights</span> based on genetic testing for wellness and prevention.</p>
                      <img src='/assets/Others/health1.png'></img>
                    </div>
                  </div>
                  <div className='w-1/2 border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white'>
                    <img src='/assets/Others/nurture1.png'></img>
                    <p className='font-semibold text-lg text-center'>Career Consultation</p>
                    <p className='text-sm mt-1 lg:mt-3 px-4 mb-2 lg:mb-0'>Embark on a Web 3.0 career with <span className='yellow-gradient font-semibold'> dedicated, tailor-made guidance,</span> where blockchain magic and decentralization shape your future. Your journey starts now!</p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col lg:flex-row gap-5'>
                <div className='w-[100%] lg:w-2/3 border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white flex items-center justify-center py-2 lg:py-0'>
                  <div className='w-[25%]'>
                    <img src='/assets/Others/knowledge1.png'></img>
                  </div>
                  <div className='w-[65%]'>
                    <h1 className='font-semibold text-lg text-center'>Nurture your Nature</h1>
                    <p className='text-sm mt-1 lg:mt-3'>Unleash your inner magic, cultivate your talents, and let your natural abilities <span className='yellow-gradient font-semibold'>grow into extraordinary</span> feats of wonder.</p>
                  </div>
                </div>
                <div className='w-[100%] lg:w-1/3 flex gap-5'>
                  <div className='w-1/2 grid content-center border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white'>
                    <h1 className='font-semibold text-lg text-center'>WEB 3.0 Community</h1>
                    <img src='/assets/Others/community1.png' className='mx-auto'></img>
                  </div>
                  <div className='w-1/2 grid content-center border-2 border-black/15 drop-shadow-lg rounded-3xl duration-500 hover:-translate-y-2 hover:shadow-black/25 hover:shadow-lg bg-white'>
                    <h1 className='font-semibold text-lg text-center'>Empowerment</h1>
                    <img src='/assets/Others/empower1.png' className='mx-auto'></img>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='sec-6 py-10 overflow-hidden'>
        <h1 className='text-3xl text-center font-semibold w-[90%] lg:w-[100%] mx-auto'>The Quest of Ascension <br /> Your<span className='yellow-gradient'>Journey </span> in D5art’s <span className='blue-gradient'> Mystical Realm</span></h1>
        <p className='text-center w-[90%] mx-auto mt-10'>Embark on a journey through the D5art Citadel of Mastery, where visionary leaders and skilled technomancers unite, honing their abilities in strategy, innovation, and technical prowess to shape the future of the Web3 realm and forge lasting legacies.</p>
        <img src='/assets/Others/curveline.png' className='mt-10 scale-125 mx-auto translate-x-3 lg:translate-x-10'></img>
        <p className='text-center font-semibold mt-14'>My journey <FontAwesomeIcon icon={faArrowRight} /></p>
      </div>
      <div className='sec-7 py-10'>
        <h1 className='text-3xl text-center font-semibold w-[90%] lg:w-[100%] mx-auto'>Step into <br /> the world of <span className='yellow-gradient'>Web3 Wizardry</span></h1>
        <div className="mt-10 relative">
          <div className='w-[100%] flex flex-wrap justify-center gap-5 bg-[#D9D9D95E]'>
            <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '0' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#F5E587A1] to-[#A1D3FFA1]' : ''}`} onClick={() => handleTabClick('0')}>Entrepreneur<br /><p className='font-semibold blue-gradient italic text-sm'>Venture Seeker</p></button>
            <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '1' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#F5E587A1] to-[#A1D3FFA1]' : ''}`} onClick={() => handleTabClick('1')}>Technopreneur<br /><p className='font-semibold blue-gradient italic text-sm'>Techno-Overlord</p></button>
            <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '2' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#F5E587A1] to-[#A1D3FFA1]' : ''}`} onClick={() => handleTabClick('2')}>Technical<br /><p className='font-semibold blue-gradient italic text-sm'>Arcane Engineers</p></button>
            <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '3' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#F5E587A1] to-[#A1D3FFA1]' : ''}`} onClick={() => handleTabClick('3')}>Non - Tech<br /><p className='font-semibold blue-gradient italic text-sm'>Guildmasters</p></button>
            <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '4' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#F5E587A1] to-[#A1D3FFA1]' : ''}`} onClick={() => handleTabClick('4')}>Instructor<br /><p className='font-semibold blue-gradient italic text-sm'>Sage Mentor</p></button>
          </div>
          <div className="w-[90%] mx-auto lg:h-[480px] 2xl:h-[560px]">
            {isActive === '0' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/Mascots/wizard1.png" className="w-[90%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8 lg:leading-10">Entrepreneur <FontAwesomeIcon icon={faArrowRight} className='text-xs' /> Venture SeekerA fearless explorer of new horizons, the Venture Seeker ventures into uncharted territories of business, seeking hidden treasures (opportunities). They use innovation, strategy, and boldness to build and expand their empire, often taking on great risks to secure valuable rewards.     </p>
                    <div className="h-5 lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '1' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/Mascots/wizard2.png" className="w-[75%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8 lg:leading-10">Technopreneur <FontAwesomeIcon icon={faArrowRight} className='text-xs' /> Techno-OverlordA formidable ruler in both the magical and technological realms, the Techno-Overlord merges innovation and arcane mastery to build vast digital kingdoms and empires. They control cutting-edge technology and powerful magic to forge new industries, set trends, and influence both economic and magical landscapes.</p>
                    <div className="h-5 lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '2' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/Mascots/wizard3.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8 lg:leading-10">Tech <FontAwesomeIcon icon={faArrowRight} className='text-xs' /> People Arcane Engineers Masters of the mystical sciences, Arcane Engineers combine ancient magic with advanced technology to create enchanted devices, automations, and innovations. They manipulate both the physical and digital realms, weaving spells and algorithms to shape the future of their world’s infrastructure and tools.
                    </p>
                    <div className="h-5 lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '3' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/Mascots/wizard4.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8 lg:leading-10">Non-Tech <FontAwesomeIcon icon={faArrowRight} className='text-xs' /> Business People  Guildmasters The Guildmasters are the tactical and diplomatic leaders who wield influence over business and trade, though they may not be versed in the magical or technological arts. They manage resources, form alliances, and ensure the prosperity of their guilds or enterprises, relying on wisdom, negotiation, and strategic thinking.     </p>
                    <div className="h-5 lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
            {isActive === '4' && (
              <div data-aos='fade-in' data-aos-duration="1000">
                <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                  <div className="relative lg:w-[50%]">
                    <img src="/assets/Mascots/wizard5.png" className="w-[80%] mx-auto z-10"></img>
                    <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                    </div>
                  </div>
                  <div className="lg:w-[50%]">
                    <p className="leading-8 lg:leading-10">Instructor <FontAwesomeIcon icon={faArrowRight} className='text-xs' /> Sage Mentor The Sage Mentor is a venerable figure, respected for their deep knowledge and ability to teach others. With wisdom forged through experience, they guide students through complex subjects, using a blend of ancient scrolls, spells, and personalized teachings to prepare apprentices for the challenges of both magic and the world beyond.        </p>
                    <div className="h-5 lg:h-20"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-[90%] mx-auto lg:mx-0 lg:w-[40%] lg:absolute bottom-[18%] right-[8.3%]">
            <p className='font-semibold '>Check My Possibility<FontAwesomeIcon icon={faArrowRight} className='pl-2' /></p>
          </div>
        </div>

      </div>
      <div className='sec-8 py-10'>
        <h1 className='text-3xl text-center font-semibold w-[90%] lg:w-[100%] mx-auto'>Gateway to the <span className='yellow-gradient'>Realm</span></h1>
        <p className='text-center w-[90%] mx-auto mt-10'>Unlock the doors to a world of endless possibilities, where knowledge, innovation, and growth await. Join an exclusive circle of pioneers and shape the future with your unique abilities. Step through the gateway and embark on your journey within our realm of excellence.</p>
        <div className='flex flex-col md:flex-row justify-center gap-5 mt-10 text-[#475467] w-[90%] mx-auto'>
          <div className='w-[100%] lg:w-[30%] border-2 p-5 shadow-lg duration-500 hover:-translate-y-2 hover:shadow-black/20'>
            <img src='/assets/Others/icon.png'></img>
            <h1 className='text-xl font-semibold mt-1 text-black'>Technical</h1>
            <div className='flex items-center gap-2'>
              <h2 className='text-4xl font-bold text-black'>$19</h2>
              <div>
                <p className='font-semibold text-black'><del>$29</del></p>
                <p>Per student </p>
                <p>(minimum 20 enrollment)</p>
              </div>
            </div>
            <p>For Technical University</p>
            <ul className="mt-3 mb-6 space-y-2">
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">6 month emerging upskilling</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated DNA test & validation</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Hi & Ai Validation</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Live projects and internships</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated WEB 3.0 career guidance</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated D pass for WEB 3 job portal</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Potential entrepreneur upskill program</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">International recognition</span>
              </li>
            </ul>
            <button className="duration-300 active:translate-y-1 w-full py-2 text-white bg-gray-700 rounded-md hover:bg-gray-800" onClick={() => handleEnquireForm("Technical")}>Enquire Now</button>
          </div>
          <div className='w-[100%] lg:w-[30%] border-2 p-5 shadow-lg duration-500 hover:-translate-y-2 hover:shadow-black/20'>
            <img src='/assets/Others/icon.png'></img>
            <h1 className='text-xl font-semibold mt-1 text-black'>Non - Technical</h1>
            <div className='flex items-center gap-2'>
              <h2 className='text-4xl font-bold text-black'>$19</h2>
              <div>
                <p className='font-semibold text-black'><del>$29</del></p>
                <p>Per student </p>
                <p>(minimum 20 enrollment)</p>
              </div>
            </div>
            <p>For Technical University</p>
            <ul className="mt-3 mb-6 space-y-2">
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">4 month emerging upskilling</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated DNA test & validation</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Hi & Ai Validation</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Live projects and internships</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated WEB 3.0 career guidance</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Dedicated D pass for WEB 3 job portal</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">Potential entrepreneur upskill program</span>
              </li>
              <li className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faCheckCircle} className='text-[#00768B]' />
                <span className="text-gray-700 text-sm">International recognition</span>
              </li>
            </ul>
            <button className="duration-300 active:translate-y-1 w-full py-2 text-white bg-gray-700 rounded-md hover:bg-gray-800" onClick={() => handleEnquireForm("Non-Technical")}>Enquire Now</button>
          </div>
        </div>
        {isEnquireForm && (
          <div className='fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10'
            data-aos='fade-in' data-aos-duration='1000'
          >
            <div className="relative w-[90%] mx-auto md:w-full max-w-xl text-black bg-white rounded-xl p-10 font-inter">
              <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={closeEnquireForm} className='absolute -top-6 right-0 cursor-pointer' /></div>
              <div className='w-full flex sm:flex-row flex-col justify-center items-center gap-5' >
                <div className='w-full' >
                  <div className='' >
                    <input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full bg-[#F3F3F3] text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                      placeholder="Name"
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                  </div>
                  <div className='mt-5' >
                    <input
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                      className="w-full bg-[#F3F3F3] text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                      placeholder="College Name"
                    />
                    {errors.collegeName && <p className="text-red-500 text-xs mt-1">{errors.collegeName}</p>}
                  </div>
                </div>
                <div className='w-full' >
                  <div>
                    <input
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full bg-[#F3F3F3] text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                      placeholder="Email"
                    />
                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                  </div>
                  <div className='mt-5' >
                    <input
                      name="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      className="w-full bg-[#F3F3F3] text-sm rounded-lg py-2 px-4 focus:outline-none border border-black/5"
                      placeholder="Designation"
                    />
                    {errors.designation && <p className="text-red-500 text-xs mt-1">{errors.designation}</p>}
                  </div>
                </div>
              </div>
              <div>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className='w-full mt-5 bg-[#F3F3F3] text-sm rounded-lg p-2 focus:outline-none border border-black/5'
                  placeholder=' Description'>
                </textarea>
                {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
              </div>
              <div className='mt-5 flex justify-center items-center ' >
                <button onClick={handleSubmit} className='bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] rounded-full px-10 py-2 font-semibold text-white text-sm '>Submit</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer/>
    </div>
  )
}
