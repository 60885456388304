import React, { useState, useEffect, useRef } from "react";
import "../../Style/App.css";
import "animate.css";
import Bingo from "./Bingo";
import { motion } from "framer-motion";



// Custom AnimatedText component
const AnimatedText = ({
  text,
  duration = 2000,
  animateOnLoad = false,
  className = "",
}) => {
  const numbers = "0101010101110101011100101".split("");
  const getRandomInt = (max) => Math.floor(Math.random() * max);

  const [displayText, setDisplayText] = useState(Array(text.length).fill(""));
  const [trigger, setTrigger] = useState(false);
  const elementRef = useRef(null);

  const triggerAnimation = () => {
    setTrigger(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && animateOnLoad) {
          triggerAnimation();
        }
      },
      { threshold: 0.1 }
    );

    if (elementRef.current) observer.observe(elementRef.current);

    return () => {
      if (elementRef.current) observer.unobserve(elementRef.current);
    };
  }, [animateOnLoad]);

  useEffect(() => {
    if (!trigger) return;

    const animateCharacter = (index) => {
      if (index >= text.length) return;

      let charInterval;
      charInterval = setInterval(() => {
        setDisplayText((currentText) =>
          currentText.map((char, i) =>
            i === index ? numbers[getRandomInt(10)] : char
          )
        );
      }, 30);

      setTimeout(() => {
        clearInterval(charInterval);
        setDisplayText((currentText) =>
          currentText.map((char, i) => (i === index ? text[i] : char))
        );
        animateCharacter(index + 1);
      }, duration / text.length);
    };

    animateCharacter(0);
  }, [trigger, text, duration]);

  return (
    <motion.p
      ref={elementRef}
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {displayText.join("")}
    </motion.p>
  );
};


function Dummy() {
  const [isMoving, setIsMoving] = useState(false);
  const [isBackgroundChanged, setIsBackgroundChanged] = useState(false);
  const [fadeOutText, setFadeOutText] = useState(false);
  const [showContainers, setShowContainers] = useState(false);

  // Handle scroll to fade out text and trigger container animation
  useEffect(() => {
    const handleScroll = () => {
      // Trigger fade-out effect when the scroll position reaches a certain point
      if (window.scrollY > 50) {
        setFadeOutText(true);
      } else {
        setFadeOutText(false);
      }

      // Trigger "door" animation when the scroll reaches further
      if (window.scrollY > 200) {
        setShowContainers(true);
      } else {
        setShowContainers(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleonclick = () => {
    setIsMoving(true);
    setTimeout(() => {
      setIsBackgroundChanged(true);
    }, 700);
  };

  const backgroundStyle = isMoving ? "500%" : "100%";

  return (
    <div
      style={{
        backgroundImage: "url(assets/background.jpeg)",
        backgroundSize: backgroundStyle,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        transition: "background-size 2s ease-in-out",
      }}
      className="w-full bg-cover"
    >
      <div className="bg-black h-screen bg-cover bg-opacity-65 ">
        <div
          onClick={handleonclick}
          className="cursor-pointer min-h-screen flex flex-col justify-center items-center font-chakra overflow-hidden"
        >
          {!isBackgroundChanged ? (
            <>
            <AnimatedText
              duration={2000}
              animateOnLoad={true}              
              className={`text-7xl text-[#0a98ff] font-medium w-full text-center uppercase transition-opacity duration-1000 ${
                isMoving ? "animate__animated animate__fadeOutLeft" : ""
              }`}
              text="Welcome to D5art"
            />
              {/* <p
                className={`text-9xl font-medium w-full text-center uppercase `}
              >

                Welcome to D5art 
              </p> */}
              <AnimatedText
              duration={2000}
              animateOnLoad={true}
              className={`text-7xl text-[#0a98ff] font-medium w-full text-center uppercase transition-opacity duration-1000 mt-3 ${
                isMoving ? "animate__animated animate__fadeOutRight" : ""
              }`}
              text="games"
            />
              {/* <p
                className={`text-9xl font-medium w-full text-center uppercase ${
                  isMoving ? "animate__animated animate__fadeOutRight" : ""
                }`}
              >
                game
              </p> */}
            </>
          ) : (
          <div className="w-full h-screen overflow-hidden overflow-y-auto" >
            <Bingo/>           
          </div>       
          )}
        </div>
      </div>
    </div>
  );
}

export default Dummy;

