import React from 'react'

const Exclusivity = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-14 py-10' >
        <div>
        <p className='text-3xl font-bold text-[#F29D38] text-center font-space' >D5art Exclusivity</p>
        <p className='w-[95%] md:w-[60%] mx-auto mt-5 text-[#BEC9DA] text-sm text-center' >Participate in our hackathon and unlock access to two exclusive D5art courses, designed to enhance your skills, validate your expertise, and prepare you for cutting-edge opportunities in the Web3 and tech industry</p>
        </div>
        <div>
          <img src='../assets/jordanevent/book.svg' className=' w-8/12 mx-auto '/>
        </div>
        <div className='flex'>
              <div className="border border-white/50 p-1.5 rounded-br-3xl">
                <button
                  className="relative inline-block px-6 py-1.5 rounded-br-3xl text-white text-sm font-medium bg-gray-700 "
                >                  
                  Explore Now
                </button>
                </div>
            </div>
    </div>
  )
}

export default Exclusivity