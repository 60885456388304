// meetingsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL = "http://localhost:5000";

export const fetchUserMeetings = createAsyncThunk(
  'meetings/fetchUserMeetings',
  async (userId) => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/user-meetings/${userId}`);
      console.log(" fetchUserMeetings response",response)
      return response.data; 
    } catch (error) {
      throw Error('Error fetching meetings');
    }
  }
);

// Meetings slice
const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: {
    meetings: [],        
    status: 'idle',      // Loading state: 'idle', 'loading', 'succeeded', 'failed'
    error: null,         
  },
  reducers: {
    resetMeetings: (state) => {
      state.meetings = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMeetings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserMeetings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.meetings = action.payload;  
      })
      .addCase(fetchUserMeetings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default meetingsSlice.reducer;

export const selectMeetings = (state) => state.meetings.meetings;
export const selectMeetingsStatus = (state) => state.meetings.status;
export const selectMeetingsError = (state) => state.meetings.error;
