// // src/features/userSlice.js
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchUserProfile } from '../services/api';

// export const fetchUser = createAsyncThunk('user/fetchUser', async (userId) => {
//     const response = await fetchUserProfile(userId);
//     return response.data;
// });

// const userSlice = createSlice({
//     name: 'user',
//     initialState: {
//         data: {},
//         status: 'idle',
//         error: null,
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchUser.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchUser.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.data = action.payload;
//             })
//             .addCase(fetchUser.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     },
// });

// export default userSlice.reducer;


// src/features/userSlice.js












import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserFromAPI } from '../Services/authapi';  // Import API service

// Initial state for the user slice
const initialState = {
  user: null,
  status: 'idle',  // Can be 'idle', 'loading', 'succeeded', 'failed'
  error: null,
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser', 
  async (email) => {
    const user = await fetchUserFromAPI(email);
    return user;  
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state.user = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the fetchUser lifecycle states
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectStatus = (state) => state.user.status;
export const selectError = (state) => state.user.error;

export default userSlice.reducer;
