// import React from "react";
// import { Navigate, useNavigate } from "react-router-dom";

// const ProtectedRoute = ({ element, ...rest }) => {

//     const navigate = useNavigate()
//   const email = localStorage.getItem("edu_email");

//   console.log("email",email)

//   return email ? element :   navigate("https://ex.d5art.com/SeekerRegistration") ;
// };

// export default ProtectedRoute;


import React from "react";

const ProtectedRoute = ({ element }) => {
  const email = localStorage.getItem("edu_email");

  console.log("email:", email);

  if (!email) {
    // Redirect to external URL
    window.location.href = "https://ex.d5art.com/SeekerRegistration";
    return null; // Return nothing since the redirect will happen
  }

  return element;
};

export default ProtectedRoute;
