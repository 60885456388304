import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Landing from './Components/Pages/Landing';
import Faq from './Components/Pages/Faq';
import NotFound from './Components/Pages/NotFound';
import DnaHome from './Components/Pages/DNA/DnaHome';
import DnaTracking from './Components/Pages/DNA/DnaTracking';
import CeoHome from './Components/Pages/Coe/Pages/Home';
import CeoDetails from './Components/Pages/Coe/Pages/Coe';
import CeoCareerHub from './Components/Pages/Coe/Pages/CareerHub';
import CeoMyDashboard from './Components/Pages/Coe/Pages/MyDashboard';
import CeoDnaAi from './Components/Pages/Coe/Pages/DnaAi';
import Bingo from './Components/Pages/Game/Bingo';
import BingoAdmin from './Components/Pages/Game/BingoAdmin';
import Bingogame from './Components/Pages/Game/Game';
import ScheduleMeeting from './Components/Pages/Coe/ADMIN/ScheduleMeeting';
import CreateMeeting from './Components/Pages/Coe/ADMIN/Create_meeitng';
import AddProjects from './Components/Pages/Coe/ADMIN/AddProjects';
import TestDemo from './Components/Pages/TestDemo';
import EditProject from './Components/Pages/Coe/ADMIN/EditProject';

import EventHome from './Components/Pages/JordanEvent/Pages/Home';
import AddTeamMembers from './Components/Pages/JordanEvent/Pages/AddTeamMembers';
import Instruction from './Components/Pages/JordanEvent/Pages/Instruction';
import Assessment from './Components/Pages/JordanEvent/Pages/Assessment';
import TeamMembers from './Components/Pages/JordanEvent/Pages/TeamMembers';
import ProtectedRoute from './Components/Pages/ProtectedRoute';


function Routers() {

  const subdomain = window.location.host.split('-')[0];
  const isGame = subdomain === 'game';
  
  console.log("isGame",isGame)

  return (

    <div>
      {!isGame ? (

        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
          {/*DNA*/}
          <Route path="/dna-home" element={<DnaHome />} />
          <Route path="/dna-track" element={<DnaTracking />} />
          {/*COE Portal*/}
          <Route path="/coe/home" element={<CeoHome />} />
          <Route path="/coe/details" element={<CeoDetails />} />
          <Route path="/coe/mydashboard" element={<CeoMyDashboard />} />
          <Route path="/coe/careerhub" element={<CeoCareerHub />} />
          <Route path="/coe/dna_ai" element={<CeoDnaAi />} />
          <Route path="/coe/admin/schedul-meeting" element={<ScheduleMeeting />} />
          <Route path="/coe/admin/create-meeting" element={<CreateMeeting />} />
          <Route path="/coe/admin/add-projects" element={<AddProjects />} />
          <Route path="/coe/admin/edit-projects" element={<EditProject />} />
          {/*Events*/}
          {/* <Route path="/jordan-event/" element={<EventHome />} />
          <Route path="/jordan-event/add-teammembers" element={<AddTeamMembers />} />
          <Route path="/jordan-event/instruction" element={<Instruction />} />
          <Route path="/jordan-event/start-assessment" element={<Assessment />} />
          <Route path="/jordan-event/team-members" element={<TeamMembers />} />
 */}

          <Route
          path="/jordan-event/"
          element={<ProtectedRoute element={<EventHome />} />}
        />
        <Route
          path="/jordan-event/add-teammembers"
          element={<ProtectedRoute element={<AddTeamMembers />} />}
        />
        <Route
          path="/jordan-event/instruction"
          element={<ProtectedRoute element={<Instruction />} />}
        />
        <Route
          path="/jordan-event/start-assessment"
          element={<ProtectedRoute element={<Assessment />} />}
        />
        <Route
          path="/jordan-event/team-members"
          element={<ProtectedRoute element={<TeamMembers />} />}
        />

          <Route path="/test" element={<TestDemo />} />


        </Routes>
      ) : (


        <Routes>
          <Route path="/" element={<Bingogame />} />
          <Route path="/game-track" element={<BingoAdmin />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      )}

    </div>


  );
}

export default Routers;