import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Badge() {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute -bottom-16 left-[35%] w-10 h-10 rounded-full bg-black cursor-pointer duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faArrowLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute -bottom-16 right-[35%] w-10 h-10 rounded-full bg-black cursor-pointer  duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faArrowRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 4000,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 250,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        appendDots: dots => (
            <div
                style={{
                    transform: 'translateY(30px)',
                }}
            >
                <ul style={{ display: "flex", justifyContent: "center", gap: "10px" }}>{dots}</ul>
            </div>
        ),
    };
    return (
        <div className='w-[90%] mx-auto mt-7 mb-10'>
            <Slider {...settings}>
                <div>
                    <div className='flex flex-col lg:flex-row items-center gap-8 '>
                        <div className='w-[100%] lg:w-1/3'>
                            <h1 className='text-2xl lg:text-5xl text-center font-bold lg:-rotate-90'>First <span className='blue-gradient'>NFT </span><br />certificate </h1>
                        </div>
                        <div className='w-[100%] lg:w-1/3'>
                            <img src='/assets/Others/FrameSlider.png'></img>
                        </div>
                        <div className='w-[100%] lg:w-1/3'>
                            <p className='leading-8'>Awarded for being one of the first to mint an NFT on the platform, marking the beginning of your journey in the digital collectible realm</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col lg:flex-row items-center gap-8 '>
                        <div className='w-[100%] lg:w-1/3'>
                            <h1 className='text-2xl lg:text-5xl text-center font-bold lg:-rotate-90'>First <span className='blue-gradient'>NFT </span><br />certificate </h1>
                        </div>
                        <div className='w-[100%] lg:w-1/3'>
                            <img src='/assets/Others/FrameSlider.png'></img>
                        </div>
                        <div className='w-[100%] lg:w-1/3'>
                            <p className='leading-8'>Awarded for being one of the first to mint an NFT on the platform, marking the beginning of your journey in the digital collectible realm</p>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
