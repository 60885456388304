import React from 'react';

const About = ({onclick}) => {
  return (
    <div style={{ backgroundImage: 'url(../assets/FABC.png)' }}  className="w-full font-dmsans min-h-screen relative">
        <div className="absolute -left-32 top-0 bottom-0 bg-[#C963FF]/10 blur-3xl md:w-96 md:h-96 rounded-full " ></div>
        <div className="absolute -right-0 top-0 bottom-0 bg-[#C963FF]/10 blur-3xl md:w-72 md:h-72 rounded-full " ></div>

        <div className=" w-[75%] mx-auto md:py-10 py-20 min-h-screen">
           <p className=" text-[#BEC9DA] text-center " >Are you ready to level up your skills and stand out in the market? It’s not about whether you should learn AI, Blockchain, or Web3 anymore. It’s about where you’ll master them. These technologies are shaping the future, and we’re here to make sure Jordan leads the way.</p>
           <div className=' w-full flex md:flex-row flex-col justify-around items-center mt-10 gap-10'>
            <div className='md:w-[45%]'>
              <p className='text-4xl font-extrabold text-[#F29D38] font-space ' >About</p>
              <div className='py-10 text-gray-400' >
              <p className=''>We’re the <span className='text-[#22DCFD] font-semibold ' > First Academy of Blockchain Council,</span> and we’re not just about learning—we’re about giving you a complete package: skills, employability, innovation, and tech entrepreneurship (yes, turning your tech ideas into reality).</p>
              <p className='mt-5 ' >Our vision?<span className='text-[#22DCFD] font-semibold ' > To make Jordan the hub for future tech talent.</span> A place where global companies look for the brightest minds in AI, Blockchain, and Web3. Let’s build that future together.</p>
              </div>

              <div className='flex'>
              <div className="border border-white/50 p-1.5 rounded-br-3xl">
                <button
                onClick={() => onclick()}
                  className="relative inline-block px-6 py-3 rounded-br-3xl text-white text-sm font-medium bg-gray-700 "
                >                  
                  Register Now
                </button>
                </div>
            </div>
            </div>
             <div className='md:w-[55%] mx-auto'>
               <img src='../assets/jordanevent/avatar.svg' className='w-9/12 mx-auto' />
             </div>
           </div>
        </div>

        </div>
  )
}

export default About