import React from "react";
import "./SkeletonLoader.css"
import Header from "../Layout/Header";

const SkeletonLoader = () => {


  return (
    <div className='overflow-hidden'>
      <Header />
      <div className='sec-1 w-[90%] mx-auto mt-5 mb-10'>
        <div className='flex flex-col lg:flex-row items-center gap-8 lg:gap-0'>
          <div className='w-[100%] lg:w-[50%]'>
         
            <div className='w-[70%] mx-auto h-40 bg-gray-300 animate-pulse'></div>
          </div>
          <div className='w-[100%] lg:w-[50%] grid gap-4'>
            <div className='h-8 bg-gray-300 rounded w-3/4 animate-pulse'></div>

            {/* Role Skeleton */}
            <div className='h-6 bg-gray-300 rounded w-1/2 animate-pulse'></div>

            {/* Description Skeleton */}
            <div className='h-5 bg-gray-300 rounded w-full animate-pulse'></div>

            {/* Progress Bar Skeleton */}
            <div className='w-full bg-gray-300 h-3 rounded-full mt-4'>
              <div className='bg-gray-400 h-3 rounded-full w-1/4 animate-pulse'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
