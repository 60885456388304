
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Slice/userSlice';
import courseProgressReducer  from './Slice/courseProgressSlice';
  // import courseReducer from './Slice/courseSlice';
  import scoresReducer from './Slice/scoresSlice';
  import userScoresReducer from './Slice/rankingsSlice';
  import rankReducer from './Slice/userRankSlice';
  import meetingsReducer from './Slice/meetingsSlice';
  import instituteReducer from './Slice/instituteSlice';
  import projectReducer from './Slice/projectSlice';


const store = configureStore({
  
    reducer: {
        user: userReducer,
        courseProgress: courseProgressReducer,
        scores: scoresReducer,
        rankings :userScoresReducer,
        rank: rankReducer,
        meetings: meetingsReducer,
        institute: instituteReducer,
        projects: projectReducer,
        

    },
});

export default store;
