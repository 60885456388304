import React, { useState, useRef, useEffect } from "react";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import AddTeamMembers from "./AddTeamMembers";
import axios from "axios";
import { SERVER_URL } from "../../../../config";
import toast, { Toaster } from "react-hot-toast";
import SwipeCards from "../Swiper/SwipeCards";
import ProblemStatement from "../Slider/ProblemStatement";
import Leaderboard from "../../Coe/Sliders/Leaderboard";
import ProblemStatementJson from "../Json/problemStatement.json";
import PreSkill from "./Instruction";
import About from "./About";
import Exclusivity from "./Exclusivity";
import Mentorship from "./Mentorship";
import Faq from "./Faq";
import Rewards from "./Rewards";
import AnimatedText from "./Animatetext";
import Timer from "./Timer";
import TeamMembers from "./TeamMembers"


const Home = ({ length = 6 }) => {
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp1, setOtp1] = useState(new Array(length).fill(""));
  const [completedStep1, setCompletedStep1] = useState(false);
  const [completedStep2, setCompletedStep2] = useState(false);
  const [completedStep3, setCompletedStep3] = useState(false);
  const [remainingSeats, setRemainingSeats] = useState(50);
  const [enquireForm, setIsEnquireForm] = useState("");
  const gotoregister = useRef(null);
  const [teammembersopen, setTeamMembersopen] = useState(false);

  const email = localStorage.getItem("edu_email");

  const [teamLeaderData, setTeamLeaderData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);

  const scrollToExplorediv = () => {
    gotoregister.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleotpChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp1];
    newOtp[index] = value.substring(value.length - 1);
    setOtp1(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) onOtpSubmit(combinedOtp);

    // Move focus to the next input field if available and the current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const inputRefs = useRef([]);

  useEffect(() => {
    if (showOtpInput) {
      setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 0);
    }
  }, [showOtpInput]);

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp1[index - 1]) {
      inputRefs.current[otp1.indexOf("")].focus();
    }
  };

  const handleVerifyOTP = () => {
    onOtpSubmit(otp1.join(""));
    // navigate("/Preskill");
    setCompletedStep1(true);
    setShowOtpInput(false);
  };

  const onOtpSubmit = (otp) => {
    console.log("Registration Successfull", otp);
  };

  if (showOtpInput) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  // team leader registration

  const [formData, setFormData] = useState({
    name: "",
    institutionName: "",
    email: "",
    fullname: "",
    mailid: "",
    mobilenumber: "",
    query: "",
    teamname: "",
    problemstatement: "",
    file: null,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = "Name is required.";
    }
    if (!formData.teamname.trim()) {
      formErrors.teamname = "Team Name is required.";
    }
    if (!formData.institutionName.trim()) {
      formErrors.institutionName = "College Name is required.";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; 
  };

  // otp generation and verification

  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [otpData, setOtpData] = useState({
    otp: "",
  });

  const handleChangeOtp = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otpData.otp];
    newOtp[index] = element.value;
    const OTP = newOtp.join("");
    setOtpData({ ...otpData, otp: OTP });

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };


  useEffect(() => {
    const fetchRegisteredTeams = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/total-teams`); 
        const totalTeams = response.data.totalTeams || 0;
        setRemainingSeats(50 - totalTeams);
      } catch (err) {
        console.error("Error fetching registered teams:", err);
      }
    };

    fetchRegisteredTeams();
  }, []);



  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otpData.otp[index] && index > 0) {
      const previousInput = event.target.previousSibling;
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const MAX_ATTEMPTS = 5;
  const COOLDOWN_PERIOD = 60 * 60 * 1000;

  const generateOtp = async (email) => {
    if (!validateForm()) {
      return;
    }
    let loadingToastId;
    const attemptsKey = `${email}_otpAttempts`;
    const cooldownKey = `${email}_otpCooldown`;

    try {
      setOtpData({ otp: "" });
      const lastAttemptTime = localStorage.getItem(cooldownKey);
      const now = Date.now();

      if (lastAttemptTime && now - lastAttemptTime < COOLDOWN_PERIOD) {
        const remainingTime = Math.ceil(
          (COOLDOWN_PERIOD - (now - lastAttemptTime)) / 60000
        );
        toast.error(
          `OTP generation limit reached. Try again in ${remainingTime} minutes.`
        );
        return;
      }

      let attempts = parseInt(localStorage.getItem(attemptsKey) || "0", 10);

      if (attempts >= MAX_ATTEMPTS) {
        localStorage.setItem(cooldownKey, now);
        localStorage.setItem(attemptsKey, "0");
        toast.error(
          "OTP generation limit reached. Please try again after 1 hour."
        );
        return;
      }

      loadingToastId = toast.loading("Please wait while generating OTP...");

      const response = await axios.post(
        `${SERVER_URL}/api/generate-jordan-email-otp`,
        { email },
        { headers: { "Content-Type": "application/json" } }
      );

      toast.dismiss(loadingToastId);

      if (response.data.success) {
        setTimeout(() => {
          toast.success(
            "OTP generated successfully! Check your email inbox/spam for the OTP.",
            {
              duration: 7000,
            }
          );
        }, 1200);

        attempts += 1;
        localStorage.setItem(attemptsKey, attempts.toString());

        setIsOtpOpen(true);
      } else {
        toast.error("Failed to generate OTP. Please try again.");
      }
    } catch (error) {
      if (loadingToastId) toast.dismiss(loadingToastId);
      console.error("Error generating OTP:", error);
      toast.error("Error generating OTP. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const verifyOtp = async () => {
    try {
      const Email = formData.email?.toLowerCase();
      if (!Email) {
        toast.error("Email not defined.");
        return;
      }

      const response = await axios.post(`${SERVER_URL}/api/verify-jordan-email-otp`, {
        otp: otpData.otp,
        Email,
      });

      if (response.data.status === "success") {
        // toast.success("OTP verified successfully!");

        console.log("OTP verified successfully!");
        await handleSubmit();
      } else {
        toast.error(response.data.message || "OTP verification failed.");
      }
    } catch (error) {
      console.error(
        "Error during OTP verification:",
        error.response?.data || error.message
      );
      toast.error("Error during OTP verification. Please try again.");
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    console.log("Form data to be sent:", formData);

    try {
      const response = await axios.post(
        `${SERVER_URL}/api/teamLeaderForm`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setShowOtpInput(false);
        setTimeout(() => {
          window.location.href = "/jordan-event/add-teammembers";
        }, 2000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error.message);
      if (error.response?.data?.error) {
        toast.error(error.response.data.error);

        const errorMessage = error.response.data.error;
        if (
          errorMessage === "Register on D5art platform." ||
          errorMessage ===
            "Email already registered on Jordan event using this email." ||
          errorMessage === "Email already registered as team member."
        ) {
          setIsOtpOpen(false);
        }
      } else {
        toast.error("An error occurred while submitting the form.");
      }
    }
  };

  useEffect(() => {
    const fetchUserStep = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/fetch_userStep`, {
          params: { email },
        });

        if (response.status === 200) {
          console.log("User Step Data:", response.data.data);
          setTeamLeaderData(response.data.data);
        } else {
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        console.error(
          "Error fetching user step data:",
          error.response?.data || error.message
        );
      }
    };

    fetchUserStep();
  }, []);

  // step 2

  // const handleStep2Completion = (status) => {
  //   setCompletedStep2(status);
  // };

  const handleStep2Completion = (status) => {
    setCompletedStep1(true);
    setCompletedStep2(true);
  };

  // step 3 update problem statement

  const handleFileChange = (e, name) => {
    const file = e.target.files[0];

    if (file.size > 5 * 1024 * 1024) {
      // 5 MB in bytes
      toast.error(`${file.name} is larger than 5 MB.`);
      return;
    }

    const acceptedFormats = ["application/pdf"];
    if (!acceptedFormats.includes(file.type)) {
      toast.error(`${file.name} has an invalid file type.`);
      return;
    }

    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleStep3Submit = async () => {
    const validationErrors = {};

    const toastId = toast.loading("Uploading pitch, please wait...");

    if (!formData.problemstatement.trim()) {
      validationErrors.problemstatement = "Problem statement is required.";
    }

    if (!formData.file) {
      validationErrors.file = "Please upload your pitch file.";
    }

    if (Object.keys(validationErrors).length > 0) {
      Object.keys(validationErrors).forEach((key) =>
        toast.error(validationErrors[key])
      );
      toast.dismiss(toastId);
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("file", formData.file);
      formDataToSend.append("problemstatement", formData.problemstatement);
      formDataToSend.append("email", email);

      console.log([...formDataToSend.entries()]);

      const response = await axios.post(
        `${SERVER_URL}/api/uploadPitch`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message, { id: toastId });
        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (error) {
      toast.dismiss(toastId);
      const errorMsg =
        error.response?.data?.error ||
        "An error occurred while submitting the form.";
      toast.error(errorMsg);
    }
  };

  // fetch team member details and checking user step

  useEffect(() => {
    const fetchTeamMemberDetails = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/api/fetch_teamMemberDetails`,
          {
            params: { email },
          }
        );

        if (response.status === 200) {
          console.log("team member Step Data:", response.data.data);
          setTeamMemberData(response.data.data);
        } else {
          console.error("Unexpected response:", response);
        }
      } catch (error) {
        console.error(
          "Error fetching user step data:",
          error.response?.data || error.message
        );
      }
    };

    fetchTeamMemberDetails();
  }, []);

  // navigate team members list

  const navigateTeamProgess = () => {
    // window.location = "/jordan-event/team-members";
    setTeamMembersopen(!teammembersopen);
  };

  // Query form

  const [queryFormData, setQueryFormData] = useState({
    mailId: "",
    subject: "",
    category: "",
    description: "",
  });

  const validateQuery = () => {
    let validationErrors = {};

    if (!queryFormData.mailId.trim()) {
      validationErrors.mailId = "Mail ID is required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        queryFormData.mailId
      )
    ) {
      validationErrors.mailId = "Please enter a valid email address.";
    }

    if (!queryFormData.subject.trim()) {
      validationErrors.subject = "Subject is required.";
    }

    if (!queryFormData.description.trim()) {
      validationErrors.description = "Please describe your query.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleQueryChange = (e) => {
    setQueryFormData({ ...queryFormData, [e.target.name]: e.target.value });
  };

  const handleSubmitQuery = async (e) => {
    e.preventDefault();

    if (validateQuery()) {
      const toastId = toast.loading("Please wait...");
      const email = queryFormData.email;
      const attemptsKey = `${email}_attempts`;
      const cooldownKey = `${email}_cooldown`;

      try {
        const now = Date.now();
        const lastAttemptTime = localStorage.getItem(cooldownKey);
        const attempts = parseInt(localStorage.getItem(attemptsKey) || "0", 10);

        if (lastAttemptTime && now - lastAttemptTime < COOLDOWN_PERIOD) {
          const remainingTime = Math.ceil(
            (COOLDOWN_PERIOD - (now - lastAttemptTime)) / 60000
          );
          toast.dismiss(toastId);
          toast.error(
            `Raise query limit reached. Try again in ${remainingTime} minutes.`
          );
          return;
        }

        if (attempts >= MAX_ATTEMPTS) {
          localStorage.setItem(cooldownKey, now);
          localStorage.setItem(attemptsKey, "0");
          toast.dismiss(toastId);
          toast.error(
            "Raise query limit reached. Please try again after 1 hour."
          );
          return;
        }

        const response = await fetch(
          "https://ex.d5art.com/api/SeekerRaiseTickets",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(queryFormData),
          }
        );

        if (response.ok) {
          localStorage.setItem(attemptsKey, (attempts + 1).toString());
          toast.dismiss(toastId);
          toast.success("Ticket Submitted Successfully", { autoClose: 3000 });

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          toast.dismiss(toastId);
          toast.error("Error submitting ticket. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting ticket:", error);
        toast.dismiss(toastId);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const valueRef = useRef(null);

  const animateValue = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.textContent = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    const obj = valueRef.current;
    if (obj) {
      animateValue(obj, 0, 2025, 3000);
    }
  }, []);

  return (
    <div className="w-full bg-contain bg-black text-white">
      <Header />
      <Toaster />
      <div className="fade-in">
        <div className=" relative  bg-jordanbg bg-cover">
          {/* <video
              autoPlay
              muted
              loop
              playsInline
              className="h-screen w-screen object-cover"
            >
               <source src='../assets/jordanevent/bgvideo.mp4' type="video/mp4" />
            </video> */}
          <div className="h-screen w-full bg-black/80 ">
            <div className="h-screen bg-gradient-to-b from-transparent to-black">
              <div className="h-screen flex flex-col justify-center items-center gap-10">
                <AnimatedText
                  duration={4000}
                  animateOnLoad={true}
                  style={{
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent", // Ensures compatibility for WebKit browsers
                    color: "transparent", // Ensures the text is transparent
                    backgroundImage:
                      "linear-gradient(to right, #EFEFEF, #38BDF2)",
                    display: "inline-block", // Ensures proper clipping
                  }}
                  className="md:w-[60%] text-[#22DCFD] px-5 text-2xl md:text-4xl leading-10 font-bold font-worksan text-center"
                  text="Join InnovNation Hackathon and Compete Across Jordan!"
                />

                <p
                  ref={valueRef}
                  style={{
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    color: "transparent",
                    backgroundImage:
                      "linear-gradient(to right, #F29D38, #00768B)",
                  }}
                  className="text-5xl font-semibold font-chakra"
                >
                  2025
                </p>

                <p className="text-[#BEC9DA] font-dmsans text-center text-xl w-[80%] md:w-[40%] mx-auto  ">
                  Welcome to Jordan Future skill summit Sign up now to be part
                  of Jordan’s biggest tech challenge. Spots are limited—don’t
                  wait
                </p>
                <div>
                  {teamLeaderData[0]?.userStep === "step1" ||
                  teamLeaderData[0]?.userStep === "step2" ||
                  !teamLeaderData[0]?.userStep ? (
                    <div className="border border-white/50 p-2 rounded-br-3xl">
                      <button
                        onClick={scrollToExplorediv}
                        className="relative inline-block px-6 py-3 text-white text-sm font-medium bg-gray-700 rounded-br-3xl font-dmsans"
                      >
                        Register now for free
                      </button>
                    </div>
                  ) : teamLeaderData[0]?.userStep === "step2" ||
                    teamLeaderData[0]?.userStep === "step3" ||
                    teamLeaderData[0]?.userStep === "step4" ||
                    teamMemberData[0]?.userStep === "step1" ||
                    teamMemberData[0]?.userStep === "step2" ? (
                    <div className="border border-white/50 p-2 rounded-br-3xl">
                      <button
                        onClick={navigateTeamProgess}
                        className="relative inline-block px-6 py-3 text-white text-sm font-medium bg-gray-700 rounded-br-3xl "
                      >
                        View MyTeam
                      </button>
                    </div>
                  ) : null}
                </div>

                <div>
                  <p className=" text-[#BEC9DA] text-center font-dmsans ">
                    In Collaboration with
                  </p>
                  <div className="mt-10 flex gap-14">
                    <img src="../assets/jordanevent/fabc.svg" />
                    <img src="../assets/jordanevent/cx.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <About onclick={scrollToExplorediv} />
        </div>

        <div
          style={{
            backgroundImage: "url(../assets/jordanevent/gamestream.png)",
          }}
          className="w-full bg-cover"
        >
          <div className="pt-10 md:px-20 px-5 bg-black/50 font-dmsans">
            <p className="font-bold text-4xl text-center text-[#F29D38] font-space ">
              Problem Statements
            </p>
            <p className=" text-[#BEC9DA] text-center md:w-[95%] mx-auto mt-5 font-dmsans ">
              We’re asking you to create solutions using Web3 and decentralized
              technologies. Think of it like this: decentralized technologies
              are systems where no single person or company controls the data.
              It’s all about transparency and giving people more control over
              their information.
            </p>
            <ProblemStatement />
          </div>
        </div>

        <div className="min-h-screen py-10 flex justify-center items-center">
          <Rewards />
        </div>

        <div className="relative ">
          {/* Background Video */}
          {/* <video
    autoPlay
    muted
    loop
    playsInline
    className="absolute top-0 left-0 w-full h-screen object-cover "
  >
    <source src="../assets/jordanevent/bgvideo.mp4" type="video/mp4" />
  </video> */}

          {/* Overlay */}
          <div className=" w-full h-full bg-black/90">
            {/* Content */}
            <div className="relative flex flex-col items-center justify-center h-full bg-gradient-to-b from-transparent to-black px-4 py-10">
              <p className="font-semibold text-2xl text-center text-[#F29D38]">
                Onboard Now
              </p>
              <div
                ref={gotoregister}
                className="w-[95%] md:w-[90%] mx-auto mt-10 flex md:flex-row flex-col justify-between  items-center gap-10 md:gap-5"
              >
                <div className="w-full md:w-[45%] bg-[#595959]/20 rounded-2xl p-5 ">
                  <div className="flex mt-5">
                    <div className="w-full flex flex-col justify-between text-center">
                      <p className="text-sm "> Hackathon Kickoff session </p>
                      <p className="text-sm "> Last date for registration </p>
                      <p className="text-sm">Final date for submission of solutions</p>
                      <p className="text-sm ">Future skill summit event day</p>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center">
                      <div className="w-5 h-5 rounded-full bg-[#D9D9D9] flex justify-center items-center">
                        <div className="bg-[#02DC52] rounded-full w-3 h-3"></div>
                      </div>
                      <hr className="h-14 border border-dashed " />
                      <div className="w-5 h-5 rounded-full border-4 border-[#D9D9D9]  flex justify-center items-center">
                        <div className=" rounded-full w-3 h-3"></div>
                      </div>
                      <hr className="h-14 border border-dashed " />
                      <div className="w-5 h-5 rounded-full border-4 border-[#D9D9D9]  flex justify-center items-center">
                        <div className=" rounded-full w-3 h-3"></div>
                      </div>
                      <hr className="h-14 border border-dashed " />
                      <div className="w-5 h-5 rounded-full border-4 border-[#D9D9D9]  flex justify-center items-center">
                        <div className="rounded-full w-3 h-3"></div>
                      </div>                     
                    </div>
                    <div className="w-full flex flex-col justify-between">
                      <p className="text-sm">19 December 2024</p>
                      <p className="text-sm">01 January 2025</p>
                      <p className="text-sm">09 February 2025</p>
                      <p className="text-sm">20 February 2025</p>
                    </div>
                  </div>
                  <div className="bg-[#595959]/10  rounded-2xl p-5 mt-10">
                    <p className="text-[#F29D38] font-space text-2xl font-semibold text-center">
                      Limitation
                    </p>
                    <div className="flex justify-around mt-5">
                      <div className=" flex flex-col justify-between items-center ">
                        <img
                          src="../assets/jordanevent/Vector.svg"
                          className="w-14"
                        />
                        <p className="text-2xl text-green-400">2-5</p>
                        <p className="font-bold text-sm">Team size</p>
                      </div>
                      <div className=" flex flex-col justify-between items-center ">
                        <img
                          src="../assets/jordanevent/MeetingRoom.png"
                          className="w-16"
                        />
                        <p className="text-2xl text-green-400">50</p>
                        <p className="font-bold text-sm">Max Team</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-[55%] bg-black p-5">
                  {!(
                    (teamLeaderData.length > 0 &&
                      teamLeaderData[0]?.userStep === "step1") ||
                    teamLeaderData[0]?.userStep === "step2" ||
                    teamLeaderData[0]?.userStep === "step3" ||
                    teamLeaderData[0]?.userStep === "step4" ||
                    teamMemberData[0]?.userStep === "step1" ||
                    teamMemberData[0]?.userStep === "step2"
                  ) && (
                    <div>
                      <p className="text-xl font-bold text-center">
                        Registration Starts in
                      </p>
                      <Timer/>

                      {/* <div className="flex items-center justify-between  mt-5">
                    <div className="flex flex-col items-center">
                      <p className="text-2xl font-bold">1</p>
                      <p
                        className={`text-xs ${completedStep1 ? "text-[#02DC52]" : ""
                          }`}
                      >
                        Register
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-2xl font-bold">2</p>
                      <p className="text-xs">Pre-Skill</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-2xl font-bold">3</p>
                      <p className="text-xs">Pitch Submission</p>
                    </div>
                  </div> */}


                    {/* ================================ */}





                     <div className=" w-[80%] mx-auto  ">
                        <div className="mt-5">
                          <label className="text-sm">Team Name</label>
                          <input
                            name="teamname"
                            value={formData.teamname}
                            onChange={handleChange}
                            className="w-full bg-[#D9D9D9]/5 text-white text-sm rounded-lg py-2 px-4 focus:outline-none border border-[#797979]"
                            placeholder="Enter Team Name"
                          />
                          {errors.teamname && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.teamname}
                            </p>
                          )}
                        </div>
                        <div className="mt-5">
                          <label className="text-sm">Team leader name</label>
                          <input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full bg-[#D9D9D9]/5 text-white text-sm rounded-lg py-2 px-4 focus:outline-none border border-[#797979]"
                            placeholder="Enter Team Leader Name"
                          />
                          {errors.name && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.name}
                            </p>
                          )}
                        </div>

                        <div className="mt-5">
                          <label className="text-sm">Mail ID</label>
                          <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full bg-[#D9D9D9]/5 text-white text-sm rounded-lg py-2 px-4 focus:outline-none border border-[#797979]"
                            placeholder="Enter Email Id"
                          />
                          {errors.email && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.email}
                            </p>
                          )}
                        </div>

                        <div className="mt-5">
                          <label className="text-sm">Institution Name</label>
                          <input
                            name="institutionName"
                            value={formData.institutionName}
                            onChange={handleChange}
                            className="w-full bg-[#D9D9D9]/5 text-white text-sm rounded-lg py-2 px-4 focus:outline-none border border-[#797979]"
                            placeholder="Enter Institution Name"
                          />
                          {errors.institutionName && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.institutionName}
                            </p>
                          )}
                        </div>
                      </div>

                      {remainingSeats > 0 ? (
            <p className="text-xs text-center mt-5">
              <span className="text-[#FF0B0B] text-lg font-bold">{remainingSeats}</span>{" "}
              Spots only left, hurry complete your{" "}
              <span className="text-[#12ECFC] font-bold">free</span>{" "}
              registration
            </p>
          ) : (
            <p className="text-lg text-center mt-5 text-red-500 font-bold">
              Registration Closed
            </p>
          )} 
         
                      <div className="flex justify-center items-center gap-2 mt-5">
                        <p>Free</p>
                        <div className="border border-white/50 p-2 rounded-br-3xl">
                          <button
                            onClick={async () => {
                              generateOtp(formData.email);
                            }}
                            disabled={remainingSeats <= 0}
                            className={`relative inline-block px-6 py-2 text-white text-sm font-medium rounded-br-3xl ${
                              remainingSeats > 0 ? "bg-gray-700" : "bg-gray-400 cursor-not-allowed"
                            }`}   
                              >
                            Register now
                          </button>
                        </div>
                      </div> 
                    </div>

                    
                  )}

                  {teamLeaderData.length > 0 &&
                    teamLeaderData[0]?.userStep === "step1" && (
                      <div className="w-full flex flex-col justify-center items-center mt-5 bg-Fabcbg2 bg-center rounded-xl">
                        <div className=" bg-black/60  rounded-lg  p-5">
                          <p className="font-semibold text-2xl text-center text-[#F29D38] font-space ">
                            Add Team Members
                          </p>

                          <NavLink to="/jordan-event/add-teammembers">
                            <div className="flex justify-center items-center">
                              <div className="border border-white/50 p-2 rounded-br-3xl mt-10">
                                <button
                                  onClick={async () => setCompletedStep1(true)}
                                  className="relative inline-block px-6 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                                >
                                  Add Members
                                </button>
                              </div>
                            </div>
                          </NavLink>
                          <div className="flex justify-center items-center gap-3 mt-5">
                            <img
                              src="../assets/jordanevent/avatar.svg"
                              className="w-28 mt-5"
                            />
                            <img
                              src="../assets/Mascots/slazzer1.png"
                              className="w-24 mt-5"
                            />
                            <img
                              src="../assets/Mascots/slazzer3.png"
                              className="w-24 mt-5"
                            />
                            <img
                              src="../assets/Mascots/slazzer4.png"
                              className="w-24 mt-5"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {/* {completedStep1 && !completedStep2 && (
              <div className="w-full fixed inset-0 flex justify-center items-center z-50">
                <AddTeamMembers completedStep2={handleStep2Completion} />
              </div>
            )} */}

                  {completedStep1 && completedStep2 && (
                    <div className="w-full fixed inset-0 flex justify-center items-center z-50">
                      <PreSkill completedStep2={handleStep2Completion} />
                    </div>
                  )}

                  {((teamLeaderData.length > 0 &&
                    teamLeaderData[0]?.userStep === "step2") ||
                    teamMemberData[0]?.userStep === "step1") && (
                    <div>
                      <p className=" font-semibold text-2xl text-center text-[#F29D38]">
                        Ready To Onboard
                      </p>
                      <div className="flex items-center justify-between  mt-10">
                        <div className="flex flex-col items-center">
                          <p className="text-3xl font-bold text-[#02DC52]">1</p>
                          <p className={"text-sm text-[#02DC52]"}>Register</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-3xl font-bold">2</p>
                          <p className="text-sm">Pre-Skill</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-3xl font-bold">3</p>
                          <p className="text-sm">Pitch Submission</p>
                        </div>
                      </div>
                      <div className=" mt-10 ">
                        <p className="text-sm italic">
                          Please ensure to completed the pre-skill assessment.
                          This ensures alignment and readiness for the next
                          steps.
                        </p>
                      </div>
                      <div className="w-[80%] mx-auto">
                        <div className="flex justify-center items-center mt-10">
                          <div className="border border-white/50 p-2 rounded-br-3xl">
                            <button
                              onClick={async () => handleStep2Completion()}
                              className="relative inline-block px-6 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                            >
                              Attend PreAssessment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {teamLeaderData.length > 0 &&
                    teamLeaderData[0]?.userStep === "step3" && (
                      <div>
                        <p className="text-[#F29D38] font-space text-2xl font-semibold text-center">
                          Ready To Onboard
                        </p>
                        <div className="flex items-center justify-between  mt-10">
                          <div className="flex flex-col items-center">
                            <p className="text-2xl font-bold text-[#02DC52]">
                              1
                            </p>
                            <p className={"text-sm text-[#02DC52]"}>Register</p>
                          </div>
                          <div className="flex flex-col items-center">
                            <p className="text-2xl font-bold text-[#02DC52]">
                              2
                            </p>
                            <p className="text-sm text-[#02DC52]">Pre-Skill</p>
                          </div>
                          <div className="flex flex-col items-center">
                            <p className="text-2xl font-bold">3</p>
                            <p className="text-sm">Pitch Submission</p>
                          </div>
                        </div>
                        <div className=" mt-10 ">
                          <p className="text-sm italic">
                            Please ensure that all your teammates have completed
                            the pre-skill assessment before uploading your
                            pitch. This ensures alignment and readiness for the
                            next steps.
                          </p>
                        </div>
                        <div className="w-[80%] mx-auto">
                          <div className="mt-5">
                            <label className="text-sm">
                              Select Problem Statements
                            </label>
                            <select
                              name="problemstatement"
                              value={formData.problemstatement}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  problemstatement: e.target.value,
                                })
                              }
                              className="w-full bg-[#D9D9D9] text-black text-sm rounded-lg py-2 px-4 focus:outline-none border border-[#797979]"
                            >
                              <option value="" disabled>
                                Select a problem statement
                              </option>
                              {ProblemStatementJson.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.problemstatement && (
                              <p className="text-red-500 text-xs mt-1">
                                {errors.problemstatement}
                              </p>
                            )}
                          </div>

                          <div className="mt-5">
                            <label className="block text-sm font-medium">
                              Submit your pitch
                            </label>
                            <div className="relative w-full flex justify-center items-center">
                              <input
                                type="file"
                                accept=".pdf"
                                onChange={(e) => handleFileChange(e, "file")}
                                id="file1"
                                className="hidden"
                              />
                              <label
                                htmlFor="file1"
                                className="w-full cursor-pointer flex items-center justify-center rounded-md"
                              >
                                {formData.file ? (
                                  <div className="w-full border rounded-2xl p-3 flex flex-col items-center justify-center">
                                    <p className="text-xs text-center italic mt-3">
                                      {formData.file.name} uploaded
                                    </p>
                                  </div>
                                ) : (
                                  <div className="w-full border rounded-2xl p-3 flex flex-col items-center justify-center">
                                    <FontAwesomeIcon
                                      icon={faCloudArrowUp}
                                      size="2xl"
                                      className="text-white/45"
                                    />
                                    <p className="text-xs text-center italic mt-3">
                                      Make sure the pitch in PDF format only,
                                      under 5 MB
                                    </p>
                                  </div>
                                )}
                              </label>
                            </div>
                            {errors.file && (
                              <span className="w-full text-red-500 text-center text-xs">
                                {errors.file}
                              </span>
                            )}
                          </div>

                          <div className="flex justify-center items-center mt-5">
                            <div className="border border-white/50 p-1.5 rounded-br-3xl">
                              <button
                                onClick={handleStep3Submit}
                                className="relative inline-block px-6 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {((teamLeaderData.length > 0 &&
                    teamLeaderData[0]?.userStep === "step4") ||
                    teamMemberData[0]?.userStep === "step2") && (
                    <div>
                      <p className="text-[#F29D38] font-space text-2xl font-semibold text-center">
                        Ready To Onboard
                      </p>
                      <div className="flex items-center justify-between  mt-10">
                        <div className="flex flex-col items-center">
                          <p className="text-2xl font-bold text-[#02DC52]">1</p>
                          <p className={"text-xs text-[#02DC52]"}>Register</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-2xl font-bold text-[#02DC52]">2</p>
                          <p className="text-xs text-[#02DC52]">Pre-Skill</p>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-2xl font-bold text-[#02DC52]">3</p>
                          <p className="text-xs text-[#02DC52]">
                            Pitch Submission
                          </p>
                        </div>
                      </div>
                      <div className=" mt-10 ">
                        <p className="text-xs italic">
                          Congratulations! Your Team has been created.
                          Collaborate, innovate, and showcase your skills to
                          create something extraordinary. Get ready to make an
                          impact together!
                        </p>
                      </div>
                      <div className="w-[80%] mx-auto">
                        <div className=" flex md:flex-row flex-col items-center gap-5 ">
                          <div className="flex justify-center items-center gap-3 mt-5">
                            <img
                              src="../assets/jordanevent/avatar.svg"
                              className="w-16 mt-5"
                            />
                            <img
                              src="../assets/Mascots/slazzer1.png"
                              className="w-14 mt-5"
                            />
                          </div>
                          <div className="flex justify-center items-center md:mt-10">
                            <div className="border border-white/50 p-1.5 rounded-br-3xl">
                              <button
                                onClick={async () => navigateTeamProgess()}
                                className="relative inline-block px-3 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                              >
                                View MyTeam
                              </button>
                            </div>
                          </div>
                          <div className="flex justify-center items-center gap-3 md:mt-5">
                            <img
                              src="../assets/Mascots/slazzer3.png"
                              className="w-14 mt-5"
                            />
                            <img
                              src="../assets/Mascots/slazzer4.png"
                              className="w-14 mt-5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <Mentorship />
        </div>

        <div className="w-full">
          <Exclusivity />
        </div>

        <div className="w-full">
          <Faq />
        </div>

        {isOtpOpen && (
          <div className="fixed inset-0 z-50 flex items-center text-white justify-center">
            <div className="absolute inset-0 bg-black/60 "></div>
            <div className="relative w-full max-w-md bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5 shadow-md">
              <div className="flex justify-end mb-2">
                <button
                  className="text-white text-xl font-bold"
                  onClick={() => setIsOtpOpen(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <h1 className="text-2xl text-[#F29D38] text-center font-bold mb-5">
                Email OTP Verification
              </h1>
              <label>Enter OTP</label>
              <div className="flex justify-between mb-5 mt-3">
                {[...Array(6)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    className=" bg-transparent border-b border-gray-500 py-2 px-3 mt-2 focus:outline-none text-center w-12"
                    maxLength="1"
                    value={otpData.otp[index] || ""}
                    onChange={(e) => handleChangeOtp(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
              <div className="flex justify-center mt-3">
              <div className="border border-white/50 p-1.5 rounded-br-3xl">
                <button
                   className="relative inline-block px-6 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                  onClick={() => verifyOtp(otpData.otp)}
                >
                  Verify
                </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {teammembersopen && (
            <div className="fixed inset-0 flex justify-center items-center bg-black/30 backdrop-blur-md " >
              <TeamMembers onClose={navigateTeamProgess} /> 
              </div>
        )
        }

        {/* Reach us form */}
        <div className="mt-10 ">
          <p className=" w-full text-center text-xl font-semibold">Reach us</p>
          <p className="text-center text-xs w-[80%] mx-auto mt-5">
            If you have any queries/concern feel free to reach us, your query
            will be addressed within 24 hours{" "}
          </p>
          <div className="flex md:flex-row-reverse flex-col-reverse items-center py-10 w-[100%] lg:w-[85%] mx-auto ">
            <div className="md:w-1/2">
              <img
                src="/assets/Others/mailbox.svg"
                className="w-9/12 mx-auto"
              />
            </div>
            <div className="md:w-1/2">
            <div className=" rounded-xl p-6 lg:p-10  bg-Fabcbg bg-cover h-full ">
              <div className=" rounded-xl p-10 bg-[#D9D9D91C] bg-cover h-full ">
                <div className="mt-5">
                  <label className="text-sm">Mail ID</label>
                  <input
                    name="mailId"
                    value={queryFormData.mailId}
                    onChange={handleQueryChange}
                    className="w-full bg-transparent text-sm py-2 px-4 focus:outline-none border-b border-dashed border-[#CCCCCC]"
                  />
                  {errors.mailId && (
                    <p className="text-red-500 text-xs mt-1">{errors.mailId}</p>
                  )}

                  {/* <div className="mt-5">
                    <label className="text-sm">Mobile</label>
                    <input
                      name="mobilenumber"
                      value={formData.mobilenumber}
                      onChange={handleChange}
                      className="w-full bg-transparent text-sm py-2 px-4 focus:outline-none border-b border-dashed border-[#CCCCCC]"
                    />
                    {errors.mobilenumber && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.mobilenumber}
                      </p>
                    )}
                  </div> */}
                </div>

                <div className="mt-5">
                  <label className="text-sm">Subject</label>
                  <input
                    name="subject"
                    value={queryFormData.subject}
                    onChange={handleQueryChange}
                    className="w-full bg-transparent text-sm py-2 px-4 focus:outline-none border-b border-dashed border-[#CCCCCC]"
                  />
                  {errors.subject && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.subject}
                    </p>
                  )}
                </div>

                <div className="mt-5">
                  <label className="text-sm">Describe your Query:</label>
                  <textarea
                    name="description"
                    value={queryFormData.description}
                    onChange={handleQueryChange}
                    className="w-full bg-transparent text-sm py-2 px-4 focus:outline-none border-b border-dashed border-[#CCCCCC]"
                  />
                  {errors.description && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors.description}
                    </p>
                  )}
                </div>
               

                <div className="flex justify-center items-center" >
                <div className="border border-white/50 p-1.5 rounded-br-3xl mt-14">
                  <button
                    onClick={handleSubmitQuery}
                    className="relative inline-block px-6 py-2 text-white text-sm font-medium bg-gray-700  rounded-br-3xl"
                  >
                    Submit Now
                  </button>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        {showOtpInput && (
          <div className="fixed inset-0 bg-black/50 flex justify-center items-center ">
            <div className=" w-[95%] mx-auto max-w-xl bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5">
              <div className="flex justify-end">
                <button
                  onClick={() => setShowOtpInput(false)}
                  className=" text-2xl font-semibold rotate-45 text-white "
                >
                  +
                </button>
              </div>
              <p className="text-sm italic text-center ">
                We sent the OTP to your mail ID{" "}
              </p>
              <p className="mt-5 text-sm text-white/50 text-center">
                Enter your OTP
              </p>
              <div className="flex justify-center items-center">
                <div className="mt-3">
                  {otp1.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      ref={(input) => (inputRefs.current[index] = input)}
                      value={value}
                      onChange={(e) => handleChangeOtp(index, e)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className="w-10 h-10 m-3 bg-transparent focus:outline-none border-b border-gray-300 text-center text-sm"
                    />
                  ))}
                </div>
              </div>
              <p className="text-xs font-semibold mt-3 text-center">
                Resend OTP
              </p>
              <div className="flex justify-center items-center mt-5">
                <button
                  onClick={handleVerifyOTP}
                  className="px-5 py-1 rounded-full bg-[#D9D9D95C] text-sm font-semibold "
                >
                  Register Now
                </button>
              </div>
            </div>
          </div>
        )}

        {completedStep3 && (
          <div className="fixed inset-0 bg-black/50 flex justify-center items-center ">
            <div className=" w-[95%] mx-auto max-w-xl bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5">
              <div className="w-full flex justify-around items-center">
                <img
                  src="/assets/Others/confetti.svg"
                  className="w-4/12 rotate-90 "
                />
                <img src="/assets/Others/confetti.svg" className="w-4/12" />
              </div>

              <p className="text-xs italic mt-3 text-center">
                Thank you for completing the assessment! Your report is being
                generated and will be shared with you via email shortly
              </p>
              <div className="flex justify-center items-center mt-5">
                <button
                  onClick={() => setCompletedStep3(false)}
                  className="px-5 py-1.5 rounded-full bg-[#D9D9D95C] text-sm "
                >
                  Back to hackathon
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
