import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../Style/Style.css';
import Aos from 'aos';
import 'aos/dist/aos.css'

Aos.init();

const Header = () => {
    const location = useLocation();
    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const handleDropdown = () => {
        setDropdown(!isDropdown)
    }

    const linkClasses = 'font-bold my-auto relative hover:text-[#F29D38] transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:origin-cente after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:origin-center';
    const activeLinkClasses = 'text-[#F29D38]';

    return (
        <header className="bg-white md:px-6 w-full text-sm md:text-base relative">
            <div>
                {/* Portal Selector */}
                <div className="container mx-auto relative mb-5 lg:mb-4 flex justify-center">
                    <div className='flex justify-center items-center py-3 gap-4 display_header'>
                        <button className="text-black px-4 py-2 ">
                            Skill Portal
                        </button>
                        <button className="text-black px-4 py-2 shadow-md shadow-black/25 bg-[#87F5D1] rounded-xl">
                            COE Portal
                        </button>
                        <button className="text-black px-4 py-2 ">
                            CDO Portal
                        </button>
                    </div>
                    <div className='absolute w-[85%] md:w-[60%] lg:w-[40%] flex justify-center items-center '>
                        <div className='w-[100%] bg-white border-2 border-t-0 h-[64px] shadow-sm' style={{ transform: 'skew(45deg) translatex(-32px)' }}></div>
                        <div className='absolute w-[100%] bg-white border-2 border-t-0 border-l-0 h-[64px]' style={{ transform: 'skew(-45deg) translatex(32px)' }}></div>
                    </div>
                </div>
                <div className="container mx-auto flex justify-between items-center px-5 lg:px-0">
                    {/* Logo */}
                    <div className="flex items-center space-x-4">
                        <img src="/assets/logo/Logod5art.png" alt="Logo" className="h-8 w-auto" />
                    </div>

                    <nav className="hidden lg:flex space-x-6 text-black font-semibold relative">
                        <NavLink to="/coe/home" end className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            Home
                        </NavLink>
                        <NavLink to="/coe/details" className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            COE
                        </NavLink>
                        <NavLink to="/coe/mydashboard" className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            My Dashboard
                        </NavLink>
                        <NavLink to="/coe/careerhub" className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            Career Hub
                        </NavLink>
                        <NavLink to="/coe/dna_ai" className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            DNA & Ai/Hi
                        </NavLink>
                        <NavLink to="/none" className={({ isActive }) => isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses}>
                            FAQ
                        </NavLink>
                    </nav>

                    {/* Login Button */}
                    <div className='hidden lg:block'>
                        <div className="flex items-center">
                            <a href="#_" class="relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-full">
                                <span class="w-full h-full bg-gradient-to-br from-[#0E2DA7]  to-[#51CBFF] group-hover:from-[#51CBFF] group-hover: group-hover:to-[#0E2DA7] absolute"></span>
                                <span class="relative px-10 py-2 transition-all ease-out bg-white rounded-full group-hover:bg-opacity-0 duration-700">
                                    <span class="relative bg-gradient-to-br from-[#0E2DA7] to-[#51CBFF] bg-clip-text text-transparent group-hover:text-white">Login</span>
                                </span>
                            </a>
                        </div>
                    </div>
                    {/* Mobile Menu Toggle */}
                    <div className="lg:hidden flex items-center">
                        <button className="text-black" onClick={handleDropdown} ref={buttonRef}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isDropdown && (
                <div className="absolute right-3 z-30" data-aos="fade-left" data-aos-duration='1000' ref={dropdownRef}>
                    {/* Navigation Links */}
                    <nav className="flex flex-col gap-1.5  text-center  bg-white border shadow-md rounded-xl p-3 text-black font-semibold">
                        <NavLink to='/coe/home' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            Home
                        </NavLink>
                        <NavLink to='/coe/details' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            COE
                        </NavLink>
                        <NavLink to='/coe/mydashboard' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            My Dashboard
                        </NavLink>
                        <NavLink to='/coe/careerhub' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            Career Hub
                        </NavLink>
                        <NavLink to='/coe/dna_ai' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            DNA & Ai/Hi
                        </NavLink>
                        <NavLink to='/none' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            FAQ
                        </NavLink>
                        <a href="#_" class="relative inline-flex items-center justify-start inline-block px-5 py-2 overflow-hidden font-bold rounded-full group">
                            <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                            <span class="absolute top-0 left-0 w-48 h-48 -mt-1  transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-black opacity-100 group-hover:-translate-x-8"></span>
                            <span class="relative w-full text-center text-black transition-colors duration-200 ease-in-out group-hover:text-white">Login</span>
                            <span class="absolute inset-0 border-2 border-black rounded-full"></span>
                        </a>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default Header;
