import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL = "http://localhost:5000";

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async (userID) => {
    try {
        const response = await axios.get(`${SERVER_URL}/api/coe/get-projects/${userID}`);
        console.log('response', response);
        // Correctly returning the data from the response
        return response.data; // You only need `response.data`, not `response.data.data`
    } catch (error) {
        console.log("fetchProjects", error);
    }
});


const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        projects: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Directly assigning the returned data to state.projects
                state.projects = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default projectSlice.reducer;




