// // NotificationPanel.js
// import React, { useEffect, useState } from 'react';
// import { io } from 'socket.io-client';
// import './NotificationPanel.css'; // Include custom styles

// const SERVER_URL = 'http://localhost:6000';

// const NotificationPanel = () => {
//   const [notifications, setNotifications] = useState([]);

//   useEffect(() => {
//     const socket = io(SERVER_URL);

//     socket.on('meetingNotification', (data) => {
//       setNotifications((prev) => [...prev, data]); // Add new notification
//     });


//     return () => {
//       socket.disconnect(); // Cleanup on component unmount
//     };
//   }, []);



//   console.log("notifications",notifications)

//   const closeNotification = (id) => {
//     setNotifications((prev) => prev.filter((notif) => notif.id !== id)); // Remove notification
//   };

//   return (
//     <div className="notification-container">
//       {notifications.map((notif) => (
//         <div key={notif.id} className="notification">
//           <div className="notification-header">
//             <h4>{notif.title}</h4>
//             <button
//               className="close-btn"
//               onClick={() => closeNotification(notif.id)}
//             >
//               ✕
//             </button>
//           </div>
//           <p>{notif.message}</p>
//           <button
//             className="join-btn"
//             onClick={() => window.open(notif.meeting_link, '_blank')}
//           >
//             Join Now
//           </button>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default NotificationPanel;




// MeetingNotifications.js
import React, { useEffect } from 'react';
import { io } from 'socket.io-client';

const SERVER_URL = 'http://localhost:6000';

const MeetingNotifications = () => {

    const  userId = 26142
  useEffect(() => {
    const socket = io(SERVER_URL);

    // Register the user to their socket connection
    socket.emit('registerUser', userId);

    // Listen for meeting reminder notifications
    socket.on('meetingReminder', (data) => {
      // Show browser notification
      if (Notification.permission === 'granted') {
        const notification = new Notification('Meeting Reminder', {
          body: `${data.message}`,
          icon: '/assets/icons/meeting-icon.png',
        });

        // Create buttons in the notification (Join and Close)
        notification.onclick = () => {
          window.open(data.meeting_link, '_blank');
        };

        notification.onclose = () => {
          console.log('Notification closed');
        };
      } else {
        console.log('Notification permission not granted');
      }
    });

    return () => {
      socket.disconnect(); // Cleanup on component unmount
    };
  }, [userId]);

  return null;
};

export default MeetingNotifications;

