import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faCalendarAlt, faChevronLeft, faChevronRight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import Attendence from '../Apexchart/Attendence';
import Badge from '../Sliders/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectError, selectStatus, selectUser } from '../../../../Slice/userSlice';
import SkeletonLoader from './SkeletonLoader';
import axios from 'axios';
import { fetchCourseProgress, fetchUserCoursesProgress, selectCourseError, selectCourseProgress, selectCourseStatus, updateModuleCompletion } from '../../../../Slice/courseProgressSlice';
import { fetchUserScores } from '../../../../Slice/scoresSlice';
// import { fetchUserRank } from '../../../../Slice/rankingsSlice';
import { fetchUserRank } from '../../../../Slice/userRankSlice';
import { fetchUserMeetings, selectMeetings, selectMeetingsError, selectMeetingsStatus } from '../../../../Slice/meetingsSlice';
import NotificationPanel from './NotificationPanel';
import { fetchProjects } from '../../../../Slice/projectSlice';


export default function MyDashboard() {
    const SERVER_URL = "http://localhost:5000";
    const [isProject, setProject] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProject = (project) => {
      setSelectedProject(project);  
      setProject(!isProject); 
      };
    const Progress = { status: '50%' };
    const progressPercentage = parseInt(Progress.status);

    const [Activepath, setActivepath] = useState('1');

    const handlePath = (tab) => {
        setActivepath(tab)
    }
   
   


    // const [meetings, setMeetings] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(new Date());
   

const email ="hwtbdpdjtw.t@rightbliss.beauty"


const dispatch = useDispatch();
const user = useSelector(selectUser);
const userStatus = useSelector(selectStatus);
const userError = useSelector(selectError);
const courses = useSelector(selectCourseProgress);

console.log("courses",courses)
  const coursesStatus = useSelector(selectCourseStatus);
  const coursesError = useSelector(selectCourseError);

  const [selectedCourseId, setSelectedCourseId] = useState(null);
  // const { userScores, status, error } = useSelector((state) => state.scores);
  const { userScores, organizationRanking, globalRanking, status: rankingsStatus, error: rankingsError } = useSelector((state) => state.rankings);
  const { user_rank, rank_status, rank_error } = useSelector((state) => state.rank);
  const { projects, status:projectStatus, error:projectError } = useSelector((state) => state.projects);
     console.log("user_rank",user_rank)

const meetings = useSelector(selectMeetings);
const meetingsStatus = useSelector(selectMeetingsStatus);
const meetingsError = useSelector(selectMeetingsError);

  // useEffect(() => {
  //   if (user?.email && userStatus === 'idle') {
  //     dispatch(fetchUser(user?.email));
  //   }
  // }, [dispatch, user, userStatus]);

  useEffect(() => {
    if (email && userStatus === 'idle') {
      dispatch(fetchUser(email));
    }
  }, [dispatch,  userStatus]);



  useEffect(() => {
    if (user) {
      dispatch(fetchUserMeetings(user.id));
      dispatch(fetchProjects(user.id)); 
    }
  }, [dispatch, user]); 

  useEffect(() => {
  
    if (coursesStatus === 'idle' && user?.id) {
      dispatch(fetchUserCoursesProgress(user.id));
    }
  }, [dispatch, user?.id, coursesStatus]);
  


useEffect(() => {
  if (courses && Object.keys(courses).length > 0) {
    setSelectedCourseId(Object.keys(courses)[0]); 
  }
}, [courses])


useEffect(() => {
  if (rankingsStatus === 'idle' && user?.instituteId) {
    dispatch(fetchUserScores(user?.instituteId));
  }
}, [dispatch, rankingsStatus, user?.instituteId]);

useEffect(() => {
  if (user && rank_status === 'idle') {
    dispatch(fetchUserRank(user?.id));
  }
}, [dispatch, user, rank_status]);




if (userStatus === 'loading' ) return <SkeletonLoader />;
if (userStatus === 'failed') return <div>Error loading user: {userError}</div>;

if (coursesStatus === 'loading') return <div>Loading...</div>;
if (coursesStatus === 'failed') return <div>Error: {coursesError}</div>;
if (rankingsStatus === 'loading') return <p>Loading rankings...</p>;
if (rankingsStatus === 'failed') return <p>Error loading rankings: {rankingsError}</p>;
if (rank_status === 'loading') {return <div>Loading...</div>;}

if (rank_status === 'failed') {return <div>Error: {rank_error}</div>;}
if (!user_rank) {  return <div>No rank data available</div>;}

if (meetingsStatus === 'loading') {
  return <SkeletonLoader />; 
}

if (meetingsStatus === 'failed') {
  return <div>Error: {meetingsError}</div>; 
}


if (meetingsStatus === 'succeeded' && meetings.length === 0) {
  return <div>No meetings available</div>; 
}


if (!user) {
  return <div>Loading user data...</div>;
}


const avatarMapping = {
    '/assets/seeker_avatar1.png': '/assets/Mascots/slazzer1.png',
    '/assets/seeker_avatar2.png': '/assets/Mascots/slazzer2.png',
    '/assets/seeker_avatar3.png': '/assets/Mascots/slazzer3.png',
    '/assets/seeker_avatar4.png': '/assets/Mascots/slazzer4.png',
    '/assets/seeker_avatar5.png': '/assets/Mascots/slazzer5.png',
    '/assets/seeker_avatar6.png': '/assets/Mascots/slazzer6.png',
  }

const avatarImage = avatarMapping[user.avatar] || '/assets/Mascots/UserMascot.png';

const getMeetingsForDate = (date) => {
  return meetings.filter((meeting) => {
    const meetingDate = new Date(meeting.scheduled_at);
    return (
      meetingDate.getDate() === date.getDate() &&
      meetingDate.getMonth() === date.getMonth() &&
      meetingDate.getFullYear() === date.getFullYear()
    );
  });
};


const changeMonth = (direction) => {
  const newDate = new Date(currentMonth);
  newDate.setMonth(currentMonth.getMonth() + direction);
  setCurrentMonth(newDate);
};

const generateCalendarDays = () => {
  const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
  const daysInMonth = endOfMonth.getDate();
  const startDay = startOfMonth.getDay();

  const days = [];
  for (let i = 0; i < startDay; i++) {
    days.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return days;
};

const hasMeeting = (day) => {
  const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
  return getMeetingsForDate(date).length > 0;
};

const isToday = (day) => {
  const today = new Date();
  return (
    today.getDate() === day &&
    today.getMonth() === currentMonth.getMonth() &&
    today.getFullYear() === currentMonth.getFullYear()
  );
};

const handleDayClick = (day) => {
  const clickedDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
  setSelectedDate(clickedDate);
};

const isJoinEnabled = (meeting) => {
  const meetingDate = new Date(meeting.scheduled_at);
  const now = new Date();
  const fifteenMinutesBefore = new Date(meetingDate.getTime() - 15 * 60 * 1000);

  return now >= fifteenMinutesBefore && now < meetingDate;
};


const meetingsForSelectedDate = getMeetingsForDate(selectedDate);
const noMeetings = meetingsForSelectedDate.length === 0;

const handleCourseClick = (courseId) => {
  setSelectedCourseId(courseId);
};


const getMilestoneClass = (completionPercentage, milestoneIndex) => {
  const milestones = [25, 50, 75, 100];
  return completionPercentage >= milestones[milestoneIndex] ? 'bg-[#5973CC]' : 'bg-[#01150B26]';
}


const calculateOverallProgress = (courses) => {
  const courseEntries = Object.values(courses);

  if (courseEntries.length === 0) return 0;

  const totalCompletion = courseEntries.reduce((sum, course) => sum + course.overallCompletion, 0);
  console.log("totalCompletion",totalCompletion)
  return (totalCompletion / courseEntries.length).toFixed(2); // Return percentage as a fixed 2-decimal number
};

const overallProgress = `${calculateOverallProgress(courses)}%`;



    return (

        <div className='overflow-hidden'>
                <NotificationPanel />

            <Header />
            <div className='sec-1 w-[90%] mx-auto mt-5 mb-10'>
                <div className='flex flex-col lg:flex-row items-center gap-8 lg:gap-0'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src={avatarImage} className='w-[70%] mx-auto' data-aos='flip-left' data-aos-duration='1500'></img>
                    </div>
                    <div className='w-[100%] lg:w-[50%] grid gap-4'>
                        <h1 className='text-3xl lg:text-4xl font-bold text-[#0EA4A9]' data-aos='fade-up' data-aos-duration='1000'>{user.nft_Id}</h1>
                        <p className='font-bold' data-aos='fade-up' data-aos-duration='1250'>Backend Developer</p>
                        <p data-aos='fade-up' data-aos-duration='1500'>{user.description || "Not mentioned"}  </p>
                        <div className='flex gap-10 justify-center lg:justify-start' data-aos='fade-up' data-aos-duration='1750'>
                          
            <div className='grid'>
                <p className='text-lg font-bold'>
                    # <span className='text-4xl'>{user_rank.organization_rank.split('/')[0]}</span> / {user_rank.organization_rank.split('/')[1]}
                </p>
            </div>
            
            {/* Global Rank */}
            <div className='grid'>
                <p className='text-lg font-bold'>
                    # <span className='text-4xl'>{user_rank.global_rank.split('/')[0]}</span> / {user_rank.global_rank.split('/')[1]}
                </p>
            </div>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000">
            <p className="text-lg font-bold">Overall Progress</p>
            <div>
                <div className="rounded w-full h-2 bg-gray-300 relative group">
                    {/* Progress bar with hover effect */}
                    <div
                        className="bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded relative"
                        style={{ width: `${overallProgress}` }}
                        title={`Overall Completion: ${overallProgress}`}
                    >
                        {/* Tooltip */}
                        <div className="absolute hidden group-hover:block bg-black text-white text-xs py-1 px-2 rounded-md -top-8 left-1/2 transform -translate-x-1/2">
                            {`${overallProgress} completed`}
                        </div>
                    </div>
                </div>
                <p className="flex justify-between px-3 mt-2">
                    <span>0%</span>
                    <span>100%</span>
                </p>
            </div>
        </div>
                    </div>
                </div>
            </div>
            <div className='sec-2 py-10 bg-gray-100'>
                <div className='w-[90%] mx-auto'>
                    <div className='text-center grid gap-5'>
                        <h1 className=' font-bold text-3xl' data-aos='fade-up' data-aos-duration='1000'>My Upskill Path</h1>
                        <p data-aos='fade-up' data-aos-duration='1500'>The Upskill section offers resources to help users enhance their blockchain and Web3 expertise, empowering them to stay competitive in the evolving digital space.</p>
                    </div>
                    <div className='mt-7 mb-10 bg-white/75 rounded-3xl' data-aos='fade-up' data-aos-duration='1750'>
                        <button className={`w-1/3 duraiton-500 py-5 rounded-3xl ${Activepath == '1' ? 'font-bold bg-white shadow-md shadow-black/10 border' : ''}`} onClick={() => handlePath('1')}>Theoretical</button>
                        <button className={`w-1/3 duraiton-500 py-5 rounded-3xl ${Activepath == '2' ? 'font-bold bg-white shadow-md shadow-black/10 border' : ''}`} onClick={() => handlePath('2')}>Practical</button>
                        <button className={`w-1/3 duraiton-500 py-5 rounded-3xl ${Activepath == '3' ? 'font-bold bg-white shadow-md shadow-black/10 border' : ''}`} onClick={() => handlePath('3')}>Overall</button>
                    </div>


                    {Activepath === '1' && (
                        <div className='flex flex-col lg:flex-row gap-10' data-aos='fade-in' data-aos-duration='1500'>
                            <div className='w-[100%] lg:w-1/3 grid gap-5'>
                                <div className='bg-white px-5 rounded-3xl border shadow-md duration-500 hover:scale-105 flex items-center justify-center'>
                                    <Attendence />
                                </div>
                                <div className='bg-white p-5 rounded-3xl border shadow-md duration-500 hover:scale-105 text-center grid content-center'>
                                    <h1 className='text-2xl font-semibold'># 5083 Points</h1>
                                    <p className='mt-3'>750 more Points needed to upgrade your rank</p>
                                </div>
                            </div>

                            {/* <div className='w-[100%] lg:w-1/3 bg-white border shadow-md duration-500 hover:scale-105 py-10 rounded-3xl'>
                                <div className='grid content-around gap-5 lg:gap-0 h-full w-[80%] mx-auto'>
                                    <div>
                                        <label>Blockchain</label>
                                        <div className='mt-2 rounded w-full h-2 bg-gray-300'>
                                            <div className='bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded w-[90%]'>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Blockchain</label>
                                        <div className='mt-2 rounded w-full h-2 bg-gray-300'>
                                            <div className='bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded w-[90%]'>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Blockchain</label>
                                        <div className='mt-2 rounded w-full h-2 bg-gray-300'>
                                            <div className='bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded w-[90%]'>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Blockchain</label>
                                        <div className='mt-2 rounded w-full h-2 bg-gray-300'>
                                            <div className='bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded w-[90%]'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[100%] lg:w-1/3 grid md:grid-cols-3 lg:grid-cols-1 gap-5'>
                                {Lessons.map((item, index) => (
                                    <div key={index} className='bg-white p-5 rounded-3xl border shadow-md duration-500 hover:scale-105 flex justify-center gap-5 items-center'>
                                        <div className='w-[20%]'>
                                            <FontAwesomeIcon icon={faBookOpen} className='text-gray-700 text-xl' />
                                        </div>
                                        <div>
                                            <h1 className='font-semibold'>Module :<span className='pl-2'>{item.moduleno}</span></h1>
                                            <p className={`font-bold mt-1 italic ${item.modulestatus == 'Completed' ? 'text-[#089D0D]' : 'text-[#F29D38]'}`}>{item.modulestatus}</p>
                                        </div>
                                    </div>))}
                            </div> */}

{/* <div className="w-full lg:w-1/3 bg-white border shadow-md duration-500 hover:scale-105 py-10 rounded-3xl">
          <div className="w-[80%] mx-auto">
            {modules.map((module) => (
              <div key={module.module_id} className="mb-4">
                <label>{module.module_title}</label>
                <div className="mt-2 rounded w-full h-2 bg-gray-300">
                  <div
                    className="bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded"
                    style={{ width: `${module.completion_percentage}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full lg:w-1/3 grid md:grid-cols-3 lg:grid-cols-1 gap-5">
          {modules.map((module) => (
            <div
              key={module.module_id}
              className="bg-white p-5 rounded-3xl border shadow-md duration-500 hover:scale-105 flex justify-center gap-5 items-center"
            >
              <div className="w-1/4 text-gray-700 text-xl">
                <FontAwesomeIcon icon={faBookOpen} />
              </div>
              <div>
                <h1 className="font-semibold">Module: <span className="pl-2">{module.module_id}</span></h1>
                <p
                  className={`font-bold mt-1 italic ${
                    module.completion_percentage === 100 ? 'text-[#089D0D]' : 'text-[#F29D38]'
                  }`}
                >
                  {module.completion_percentage === 100 ? 'Completed' : 'In Progress'}
                </p>
                {module.completion_percentage < 100 && (
                  <button
                    className="mt-2 px-4 py-1 rounded-lg bg-blue-500 text-white"
                    onClick={() => handleModuleCompletion(module.module_id)}
                  >
                    Mark as Complete
                  </button>
                )}
              </div>
            </div>
          ))}
        </div> */}
     



<div className="space-y-4">
  {Object.keys(courses).map((courseId) => {
    const course = courses[courseId];
    return (
      <div
        key={courseId}
        onClick={() => handleCourseClick(courseId)}
        className={`cursor-pointer p-4 rounded-xl border ${selectedCourseId === courseId ? 'bg-blue-200' : 'bg-white'} shadow-md hover:bg-blue-100`}
      >
        <h3 className="font-semibold">{course.courseTitle}</h3>
        <p>Overall Completion: {course.overallCompletion.toFixed(2)}%</p>
      </div>
    );
  })}
</div>

{/* Course modules */}
{selectedCourseId && courses[selectedCourseId] && (
  <div className="flex flex-col lg:flex-row gap-10" data-aos="fade-in" data-aos-duration="1500">
    <div className="w-[100%] lg:w-1/3 bg-white border shadow-md duration-500 hover:scale-105 py-10 rounded-3xl">
      <div className="grid content-around gap-5 lg:gap-0 h-full w-[80%] mx-auto">
        {courses[selectedCourseId].modules.map((module) => (
          <div key={module.module_id}>
            <label>{module.module_title}</label>
            <div className="mt-2 rounded w-full h-2 bg-gray-300">
              <div
                className="bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded"
                style={{ width: `${module.completion_percentage}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="w-[100%] lg:w-1/3 grid md:grid-cols-3 lg:grid-cols-1 gap-5">
      {courses[selectedCourseId].modules.map((module) => (
        <div
          key={module.module_id}
          className="bg-white p-5 rounded-3xl border shadow-md duration-500 hover:scale-105 flex justify-center gap-5 items-center"
        >
          <div className="w-[20%]">
            <FontAwesomeIcon icon={faBookOpen} className="text-gray-700 text-xl" />
          </div>
          <div>
            <h1 className="font-semibold">
              Module :<span className="pl-2">{module.module_id}</span>
            </h1>
            <p
              className={`font-bold mt-1 italic ${
                module.completion_percentage === 100 ? 'text-[#089D0D]' : 'text-[#F29D38]'
              }`}
            >
              {module.completion_percentage === 100 ? 'Completed' : 'In Progress'}
            </p>
          </div>
        </div>
      ))}
    </div>

   
    {/* <div className="w-[100%] lg:w-1/3 grid gap-5">
      <div className="bg-white p-5 rounded-3xl border shadow-md duration-500 hover:scale-105 text-center grid content-center">
        <h1 className="text-2xl font-semibold">{courses[selectedCourseId].overallCompletion.toFixed(2)}% Completion</h1>
        <p className="mt-3">Points Needed to Upgrade</p>
      </div>
    </div> */}
  </div>
)}

                        </div>
                    )}


                    {Activepath === '2' && (
                        <div>2</div>
                    )}
                    {Activepath === '3' && (
                        <div>3</div>
                    )}
                </div>
            </div>

            <div className="sec-3 py-10 relative">
      <div className="text-center grid gap-5 w-[90%] mx-auto">
        <h1 className="font-bold text-3xl" data-aos="fade-up" data-aos-duration="1000">
          My Schedule
        </h1>
        <p data-aos="fade-up" data-aos-duration="1500">
          The Schedule section helps users track events, deadlines, and project milestones to stay organized.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-8 w-[90%] mt-5 items-center mx-auto">
        <div className="w-[100%] lg:w-[50%]">
          <div className="w-[100%] lg:w-[90%] lg:ml-auto flex flex-col items-center bg-white shadow-md rounded-3xl border p-6">
            <div className="flex justify-between items-center w-full mb-4">
              <button onClick={() => changeMonth(-1)} className="text-purple-600">
                <FontAwesomeIcon icon={faChevronLeft} />
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <h2 className="text-xl font-bold text-purple-600">
                {currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}
              </h2>
              <button onClick={() => changeMonth(1)} className="text-purple-600">
                <FontAwesomeIcon icon={faChevronRight} />
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>

            <div className="grid grid-cols-7 gap-4 mt-3 w-full text-center">
              <div>S</div>
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>

              {generateCalendarDays().map((day, index) => (
                <div key={index} className="flex justify-center">
                  {day ? (
                    <div
                      className={`w-10 h-10 flex items-center justify-center rounded-full cursor-pointer ${
                        isToday(day) ? 'bg-orange-500 text-white font-bold'
                        : hasMeeting(day) ? 'bg-red-500 text-white font-bold'
                        : 'text-gray-800'
                      }`}
                      onClick={() => handleDayClick(day)}
                    >
                      {day}
                    </div>
                  ) : (
                    <div className="w-10 h-10"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[50%]">
          {noMeetings ? (
            <div className="flex items-center justify-center bg-white shadow-md hover:shadow-black/20 rounded-3xl border-2 duration-300 p-4 md:w-[50%] mx-auto">
              <div className="flex justify-center items-center gap-4">
                <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-300" />
                <div>
                  <h3 className="font-semibold text-lg">No meetings available</h3>
                </div>
              </div>
            </div>
          ) : (
            meetingsForSelectedDate.map((meeting, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-white shadow-md hover:shadow-black/20 rounded-3xl border-2 duration-300 p-4"
              >
                <div className="gap-4">
                  <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-300" />
                  <div>
                    <h3 className="font-semibold text-lg">{meeting.title}</h3>
                    <p className="text-sm text-gray-500">
                      {new Date(meeting.scheduled_at).toLocaleString()}
                    </p>
                  </div>
                </div>
                <button
                  className={`font-bold ${isJoinEnabled(meeting) ? '' : 'opacity-50 cursor-not-allowed'}`}
                  disabled={!isJoinEnabled(meeting)}
                >
                  <a href={meeting.meeting_link} target="_blank" rel="noopener noreferrer">
                    Join Now
                  </a>
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="absolute left-0 -bottom-10 md:bottom-10 md:top-[40%] w-20 md:w-32 lg:w-40">
        <img src="/assets/Others/GrassPot.png" data-aos="fade-right" data-aos-duration="1500" alt="Decoration" />
      </div>
    </div>



            <div className='sec-4 py-10'>
                <h1 className=' font-bold text-3xl text-center' data-aos='fade-up' data-aos-duration='1000'>Badges & Rewards</h1>
                <Badge />
            </div>
            <div className='sec-5 py-10'>
                <div className='text-center grid gap-5 w-[90%] mx-auto'>
                    <h1 className=' font-bold text-3xl' data-aos='fade-up' data-aos-duration='1000'>My Projects</h1>
                    <p data-aos='fade-up' data-aos-duration='1500'>The Projects section showcases blockchain initiatives, including tokenized assets, NFTs, and dApps. Users can track progress, view team details, and engage in community decisions, contributing to the platform’s growth.</p>
                </div>
                <div className='flex flex-col lg:flex-row items-center gap-8 lg:gap-0 w-[90%] mx-auto mt-5'>
                    <div className='w-[100%] lg:w-[50%] grid content-center gap-5'>
                    {projects && projects.map((project) => (
        <div key={project.id} className="bg-white rounded-3xl border-2 shadow-md duration-500 hover:shadow-black/20 hover:-translate-y-1 flex flex-col md:flex-row gap-5 md:gap-0 justify-between items-center py-3 md:py-5 px-5 md:px-7">
          <div className="w-[100%] md:w-[70%]">
            <h1 className="text-lg font-bold">{project.project_name}</h1>
            <div className="text-sm mt-2 flex flex-wrap gap-3">
              <p>Blockchain</p>
              <p>Iot</p>
            </div>
            <div className="rounded w-full h-2 bg-gray-300 mt-4">
              <div className="bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] h-2 rounded" style={{ width: `${project.completion_percentage}%` }}></div>
            </div>
          </div>
          <div className="w-[100%] md:w-[20%] flex justify-center">
            <button className="font-bold" onClick={() => handleProject(project)} >View More</button>
          </div>
        </div>
      ))}

     {isProject && selectedProject && (
        <div className="fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10"
             data-aos="fade-in" data-aos-duration="1000">
          <div className="w-[85%] mx-auto rounded-lg bg-white p-10 text-black relative">
            <div className="flex justify-end">
              <FontAwesomeIcon icon={faXmarkCircle} onClick={()=>{setProject(!isProject)}} className="cursor-pointer absolute right-0 -top-5" />
            </div>

            <div>
              <div className="mt-5">
                <p className="uppercase font-semibold">Project name : <span className="uppercase text-[#5973CC] px-5">{selectedProject?.project_name}</span></p>
              </div>
              <div className="mt-5">
                <p className="uppercase font-semibold">Issued by : <span className="uppercase text-[#5973CC] px-5">{selectedProject?.issued_by || 'N/A'}</span></p>
              </div>
              <div className="flex md:flex-row flex-col justify-start gap-10 mt-5">
                <p className="uppercase font-semibold">Project Description :</p>
                <p className="text-sm font-medium md:w-[75%]">{selectedProject?.project_description || 'No description available'}</p>
              </div>
            </div>

            {/* Milestone Section */}
            <div className="mt-10">
              <p className="uppercase font-semibold text-lg leading-8 text-center underline underline-[#0E2DA7]">Milestone</p>

              <div className="w-[85%] mt-10 mx-auto h-1 bg-black/20 rounded-full relative flex justify-between items-center">
                {[0, 1, 2, 3].map((milestoneIndex) => (
                  <div key={milestoneIndex} className={`w-7 h-7 flex justify-center items-center border border-black rounded-full ${getMilestoneClass(selectedProject.completion_percentage, milestoneIndex)} ${selectedProject.completion_percentage >= (milestoneIndex + 1) * 25 ? 'blinking' : ''}`}>
                    <div className={`w-4 h-4 border border-black ${selectedProject.completion_percentage >= (milestoneIndex + 1) * 25 ? 'bg-[#5973CC]' : 'bg-[#01150B26]'} rounded-full`}></div>
                  </div>
                ))}
              </div>

              <div className="w-full flex justify-center items-center mt-10">
                <button className="mt-5 bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] rounded-full px-10 py-2 font-semibold text-white text-sm">Withdraw</button>
              </div>
            </div>
          </div>
        </div>
      )}


{/* {isProject && (
                    <div className='fixed z-50 inset-0 model-overlay flex justify-center items-center backdrop-blur-md bg-black/10'
                        data-aos='fade-in' data-aos-duration='1000'
                    >
                        <div className=' w-[85%] mx-auto rounded-lg bg-white p-10 text-black relative' >
                        <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={handleProject} className='cursor-pointer absolute right-0 -top-5'/></div>

                            <div>
                                <div className='mt-5'>
                                    <p className='uppercase font-semibold'>Project name :<span className='uppercase text-[#5973CC] px-5' >Cxo</span></p>
                                </div>
                                <div className='mt-5' >
                                    <p className='uppercase font-semibold'>Issued by :<span className='uppercase text-[#5973CC] px-5' >FABC</span></p>
                                </div>
                                <div className='flex md:flex-row flex-col justify-start gap-10 mt-5' >
                                    <p className='uppercase font-semibold'>Project Description :</p>
                                    <p className=' text-sm font-medium md:w-[75%]'>Embark on a Web 3.0 career with <span style={{ backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", backgroundImage: "linear-gradient( to right, #00768B,  #F29D38 )" }} >dedicated, tailor-made guidance</span> where blockchain magic and decentralization shape your future. Your journey starts now!</p>
                                </div>
                            </div>

                            <div className='mt-10' >
                                <p className='uppercase font-semibold text-lg leading-8 text-center underline underline-[#0E2DA7]'>Milestone</p>

                                <div className='w-[85%] mt-10 mx-auto h-1 bg-black/20 rounded-full relative flex justify-between items-center'>
                                    <div className='w-7 h-7 flex justify-center items-center border border-black rounded-full bg-[#01150B26]' >
                                        <div className='w-4 h-4 border border-black bg-[#5973CC] rounded-full ' ></div>
                                    </div>
                                    <div className='w-7 h-7 flex justify-center items-center border border-black rounded-full bg-[#01150B26]' >
                                        <div className='w-4 h-4 border border-black bg-[#5973CC] rounded-full ' ></div>
                                    </div>
                                    <div className='w-7 h-7 flex justify-center items-center border border-black rounded-full bg-[#01150B26]' >
                                        <div className='w-4 h-4 border border-black bg-[#5973CC] rounded-full ' ></div>
                                    </div>
                                    <div className='w-7 h-7 flex justify-center items-center border border-black rounded-full bg-[#01150B26]' >
                                        <div className='w-4 h-4 border border-black bg-[#5973CC] rounded-full ' ></div>
                                    </div>
                                </div>
                                <div className=' w-full flex justify-center items-center mt-10 ' >
                                    <button className='mt-5 bg-gradient-to-r from-[#0E2DA7] to-[#51CBFF] rounded-full px-10 py-2 font-semibold text-white text-sm '>Withdraw</button>
                                </div>
                            </div>

                        </div>
                    </div>
                )} */}

                    </div>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/Others/magicstick.png' className='w-[100%] md:w-[70%] mx-auto' data-aos='flip-right' data-aos-duration='1500'></img>
                    </div>
                </div>
              
        </div>
        </div>
    )
}





