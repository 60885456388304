import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons'

export default function Leaderboard() {

    const settings = {
        infinite: true,
        speed: 4000,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 250,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    const UserProfile = [
        {
            jobtype: 'Full Time',
            photo: 'slazzer1.png',
            color: 'bg-gradient-to-b from-gray-900 via-teal-900 to-gray-900',
            skillid: '1234bdg134xxwd11',
            year: '0-9',
            projects: '1',
        },
        {
            jobtype: 'Part Time',
            photo: 'slazzer2.png',
            color: 'bg-gradient-to-b from-[#008CFF00] via-[#0A1043] to-[#008CFF00]',
            skillid: '1234bdg134xxwd11',
            year: '0-9',
            projects: '1',
        },
        {
            jobtype: 'Full Time',
            photo: 'slazzer3.png',
            color: 'bg-gradient-to-b from-pink-500/10 via-pink-600/40 to-pink-500/10',
            skillid: '1234bdg134xxwd11',
            year: '0-9',
            projects: '1',
        },
        {
            jobtype: 'Full Time',
            photo: 'slazzer4.png',
            color: 'bg-gradient-to-b from-purple-900/10 via-purple-900/40 to-purple-900/10',
            skillid: '1234bdg134xxwd11',
            year: '0-9',
            projects: '1',
        },
        {
            jobtype: 'Full Time',
            photo: 'slazzer5.png',
            color: 'bg-gradient-to-b from-yellow-500/20 via-yellow-500/50 to-yellow-500/20',
            skillid: '1234bdg134xxwd11',
            year: '0-9',
            projects: '1',
        },
    ]

    return (
        <div className="carousel relative">
            <Slider className=" mx-auto " {...settings}>
                {UserProfile.map((item, index) => (
                    <div key={index} class="relative w-96 h-[500px] md:h-auto lg:h-[600px] 2xl:h-[680px] text-center bg-[#051119] px-3 rounded-md flex-shrink-0">
                        <div className={`${item.color} h-full grid content-center py-5`}>
                            <div class="absolute top-20 left-2 transform -rotate-90 text-xs tracking-widest text-gray-300">
                                {item.jobtype}
                            </div>
                            <img src='/assets/Others/star.png' className='w-3 h-3 absolute top-[20%] left-[15%]'></img>
                            <img src='/assets/Others/star.png' className='absolute top-[10%] left-[20%]'></img>
                            <div class="relative flex justify-center mb-4">
                                <img src={`/assets/Mascots/${item.photo}`} alt="Character" class="w-[80%] mx-auto object-cover rounded-full" />
                            </div>
                            <div class="absolute top-0 right-3 w-32 h-32">
                                <img src="/assets/Others/Skillstamp.png" alt="Badge" class="w-full h-full object-cover" />
                            </div>
                            <div class="text-gray-200 text-sm">
                                <p class="uppercase tracking-widest">Skill ID:</p>
                                <p class="text-lg font-bold text-cyan-300">{item.skillid}</p>
                            </div>
                            <div class="flex items-center justify-center space-x-4 mt-4 text-white">
                                <div class="flex items-center space-x-2">
                                    <FontAwesomeIcon icon={faBriefcase} />
                                    <span>{item.year} year</span>
                                </div>
                                <div class="flex items-center space-x-2">
                                    <FontAwesomeIcon icon={faSquarePollVertical} />
                                    <span>Projects: {item.projects}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}
