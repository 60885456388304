import React, { useRef, useState } from 'react'
import Header from '../Layout/Header'
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';


export default function DnaAi() {
    const [isActive, setActive] = useState('0');
    const [Purpose, setPurpose] = useState(null);
    const [ConfirmSubmission, setConfirmSubmission] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null);
    const reCaptcha_public = "6LeDPCoqAAAAAH7ABnqmmz3q7YvSZIXZxbRE4mN2"

    const exploreSectionRef = useRef(null);
    const scrollToExploreSection = () => {
        exploreSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };


    const handleTabClick = (tab) => {
      setActive(tab)
    }

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobile: '',
        address: '',
        country: '',
        zipCode: '',
        referCode: '',
        areYouHereAs: "",
    });

    const Selectpurpose = [
        { value: 'CXO', label: 'CXO' },
        { value: 'Student', label: 'Student' },
        { value: 'Graduate', label: 'Graduate' },
        { value: 'Working professional', label: 'Working professional' },
    ];

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handlePurpose = (selectedOption) => {
        setPurpose(selectedOption);

        setFormData((prevData) => ({
            ...prevData,
            areYouHereAs: selectedOption.value,
        }));
    };

    const handleSubmission = () => {

        const { fullName, email, mobile, address, country, zipCode, areYouHereAs } = formData;

        console.log("formData", formData)

        if (!fullName || !email || !mobile || !address || !country || !zipCode || !areYouHereAs) {
            toast.error('Please fill out all required fields');
            return;
        }

        if (!recaptchaToken) {
            toast.error('Please complete the reCAPTCHA');
            return;
        }
        setConfirmSubmission(!ConfirmSubmission)
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '0px',
            borderBottom: '1px dashed',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: 'white',
            outline: 'none !important',
            ring: 'none',
            borderRadius: '0px',
            '&:hover': {
                borderBottom: '1px dashed white',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000',
            ':active': {
                backgroundColor: 'lightgray',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
        input: (provided) => ({
            ...provided,
            color: '#000',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#fff',
        }),
    };

    return (
        <div className='overflow-hidden'>
            <Header />
            <div className='sec-1 text-lg 2xl:text-xl flex items-center justify-center text-center relative mt-5 mb-10'>
                <div>
                    <div className='flex justify-center'>
                        <h1 className='text-2xl md:text-3xl 2xl:text-4xl font-bold text-transparent bg-gradient-to-r from-[#31989B] to-[#4A5CA4] bg-clip-text '
                        >Let’s Nurture your Nature</h1>
                    </div>
                    <p className='italic mt-7 w-[90%] md:w-[60%] mx-auto'>“DNA is the script of life, and with each enhancement, we evolve the story—turning potential into power, and existence into evolution”</p>
                    <img data-aos='flip-left' src='/assets/Mascots/Nurture.png' className='w-[90%] md:w-[25%] 2xl:w-[35%] mt-5 mx-auto'></img>
                    <div className='flex justify-center mt-10'>
                        <button onClick={scrollToExploreSection} className='mx-auto font-semibold md:mx-0 font-play bg-gray-300 shadow-md active:translate-y-1 duration-300 py-2 px-7 rounded-full'>
                            My Passport
                        </button>
                    </div>
                </div>
                <div className='absolute flex justify-between z-10 w-[100%]'>
                    <img data-aos='flip-up' data-aos-duration='1000' src='/assets/Others/DNAright.png' className='w-[20%]'></img>
                    <img data-aos='flip-up' data-aos-duration='1000' src='/assets/Others/DNAleft.png' className='w-[20%]'></img>
                </div>
                <div>
                    <img src='/assets/Others/C1.png' className='absolute top-10 md:top-0 left-[25%] w-5 md:w-10 h-5 md:h-10 fa-bounce'></img>
                    <img src='/assets/Others/C2.png' className='absolute top-10 md:top-[20%] left-[10%] w-5 md:w-10 h-5 md:h-10 fa-bounce'></img>
                    <img src='/assets/Others/C3.png' className='absolute top-10 md:top-0 right-[25%] w-5 md:w-10 h-5 md:h-10 fa-bounce'></img>
                    <img src='/assets/Others/C4.png' className='absolute top-10 md:top-[20%] right-[10%] w-5 md:w-10 h-5 md:h-10 fa-bounce'></img>
                </div>
            </div>
            <div className='sec-2 py-10'>
            <h1 className='text-3xl text-center font-semibold w-[90%] lg:w-[100%] mx-auto'>My Nature</h1>
            <div className="mt-10 relative">
              <div className='w-[100%] flex flex-wrap justify-center lg:gap-5 bg-[#D9D9D95E]'>
                <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '0' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#0E2DA778] to-[#51CBFF78]' : ''}`} onClick={() => handleTabClick('0')}>Holland Theory</button>
                <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '1' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#0E2DA778] to-[#51CBFF78]' : ''}`} onClick={() => handleTabClick('1')}>Personality</button>
                <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '2' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#0E2DA778] to-[#51CBFF78]' : ''}`} onClick={() => handleTabClick('2')}>Psychological</button>
                <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '3' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#0E2DA778] to-[#51CBFF78]' : ''}`} onClick={() => handleTabClick('3')}>Emotional</button>
                <button className={`duration-500 text-lg md:text-xl w-[45%] md:w-[25%] lg:w-[18%] h-[60px] md:h-[75px] rounded-full ${isActive === '4' ? 'font-semibold shadow-md shadow-black/20 bg-gradient-to-r from-[#0E2DA778] to-[#51CBFF78]' : ''}`} onClick={() => handleTabClick('4')}>Behaviour</button>
              </div>
              <div className="w-[90%] mx-auto lg:h-[480px] 2xl:h-[560px]">
                {isActive === '0' && (
                  <div data-aos='fade-in' data-aos-duration="1000">
                    <div className="flex flex-col lg:flex-row gap-10 items-center mt-5">
                      <div className="relative lg:w-[50%]">
                        <img src="/assets/Others/nature1.png" className="w-[90%] mx-auto z-10"></img>
                        <div className="z-0 rounded-full w-[80%] h-[80%] absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] bg-gradient-to-r from-[#F5E587A1]/20 to-[#A1D3FFA1]/20 blur-3xl">
                        </div>
                      </div>
                      <div className="lg:w-[50%]">
                        <p className="leading-8 lg:leading-10 w-[90%] mx-auto">Awarded for being one of the first to mint an NFT on the platform, marking the beginning of your journey in the digital collectible realm.</p>
                      </div>
                    </div>
                  </div>
                )}
                {isActive === '1' && (
                  <div></div>
                )}
                {isActive === '2' && (
                  <div></div>
                )}
                {isActive === '3' && (
                  <div>
                  </div>
                )}
                {isActive === '4' && (
                 <div>
                 </div>
                )}
              </div>
            </div>
    
          </div>
            <div className='sec-3 py-10'>
                <div className='text-center grid gap-5 w-[90%] mx-auto'>
                    <h1 className=' font-bold text-3xl' data-aos='fade-up' data-aos-duration='1000'>My Consultation</h1>
                    <p data-aos='fade-up' data-aos-duration='1250'>Enhance your well-being with tailored advice on mental clarity, stress management, and productivity for professional success.</p>
                    <p data-aos='fade-up' data-aos-duration='1500'>Elevate your career with unbiased hiring, skill validation, and real-time insights into your professional worth</p>
                </div>
                <div>
                    <img src='/assets/Mascots/consultation.png' className='mx-auto' data-aos='flip-left' data-aos-duration='1500'></img>
                </div>
                <div className='flex justify-center mt-10'>
                    <button className='mx-auto font-semibold md:mx-0 font-play bg-gray-300 shadow-md active:translate-y-1 duration-300 py-2 px-7 rounded-full'>
                        Know more
                    </button>
                </div>
            </div>
            <div className='sec-4 py-10'>
                <div className='text-center grid gap-5 w-[90%] mx-auto'>
                    <h1 className=' font-bold text-3xl' data-aos='fade-up' data-aos-duration='1000'>My Validation & Nurture</h1>
                    <p data-aos='fade-up' data-aos-duration='1250'>Skill validation ensures career credibility through verified expertise, while health validation supports productivity and balance for overall success.</p>
                </div>
                <div className='flex items-center justify-center h-[500px] 2xl:h-[600px] relative'>
                    <div className='w-[80%] md:w-[60%] absolute left-0 top-0' >
                        <img src='/assets/Others/Validationleft.png' data-aos='fade-right' data-aos-duration='1500'></img>
                    </div>
                    <div className='w-[90%] md:w-[50%] lg:w-1/3'>
                        <p className='text-center leading-7'><span className='yellow-gradient font-bold'>Natural ability is the seed</span>, and talent is the growth nurtured through effort and learning. Validation ensures both are recognized, fostering confidence and credibility in <span className='yellow-gradient font-bold'>personal</span> and <span className='blue-gradient font-bold'>professional pursuits.</span></p>
                    </div>
                    <div className='w-[80%] md:w-[60%] absolute right-0 bottom-0' >
                        <img src='/assets/Others/Validationright.png' data-aos='fade-left' data-aos-duration='1500'></img>
                    </div>
                </div>
                <div className='flex justify-center mt-10 md:mt-0'>
                    <button className='mx-auto font-semibold md:mx-0 font-play bg-gray-300 shadow-md active:translate-y-1 duration-300 py-2 px-7 rounded-full'>
                        Validate Now
                    </button>
                </div>
            </div>
            <div className='sec-5 pb-10 2xl:pt-10 flex items-center' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='w-[90%] mx-auto'>
                    <div className='text-center grid gap-5 w-[90%] mx-auto'>
                        <h1 className=' font-bold text-3xl' data-aos='fade-up' data-aos-duration='1000'>Book My Kit</h1>
                    </div>
                    <div className='flex flex-col lg:flex-row items-center gap-5 lg:gap-0 mt-5'>
                        <div className='w-[100%] lg:w-[50%] text-center'>
                            <img data-aos='flip-left' data-aos-duration='1000' src='/assets/Others/DNAform.png' className='w-[80%] 2xl:w-[60%] mx-auto'></img>
                            <p className='text-lg italic'>“Your Data Your Control”</p>
                            <p className='text-sm 2xl:w-[60%] mx-auto'>The Data test report is stored on Blockchain where the user only have the access to their own data</p>
                        </div>
                        <div className='w-[100%] lg:w-[50%]' ref={exploreSectionRef}>
                            <div class="w-[100%] md:w-[90%] 2xl:w-[80%] bg-gray-100 border-2 sahdow-md mx-auto relative p-5 md:px-10 py-7 rounded-2xl shadow-md">
                                <h1 className='text-center text-[#F29D38] text-lg font-semibold'>Book your DNA test kit</h1>
                                <div className='grid gap-5'>
                                    <div>
                                        <label className='text-sm'>Full Name :</label>
                                        <input
                                            type="text"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                            className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'

                                        ></input>
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-5'>
                                        <div>
                                            <label className='text-sm'>Mail ID :</label>
                                            <input
                                                type='email'
                                                className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                        <div>
                                            <label className='text-sm'>Mobile :</label>
                                            <input
                                                type="number"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                                className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'
                                            ></input>
                                        </div>
                                    </div>
                                    <div>
                                        <label className='text-sm'>Address :</label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'
                                        ></input>
                                    </div>
                                    <div>
                                        <label className='text-sm'>Country :</label>
                                        <input
                                            type="text"
                                            name="country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'
                                        ></input>
                                    </div>
                                    <div>
                                        <label className='text-sm'>Pin code / Zip code :</label>
                                        <input
                                            type="text"
                                            name="zipCode"
                                            value={formData.zipCode}
                                            onChange={handleChange}

                                            className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent'></input>
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-5'>
                                        <div className='w-[100%] md:w-[50%]'>
                                            <label className='text-sm'>Are you here as :</label>
                                            <Select
                                                className="w-[100%] border-b border-dashed border-black/50 outline-none"
                                                value={Purpose}
                                                onChange={handlePurpose}
                                                options={Selectpurpose}
                                                styles={customStyles}
                                                placeholder="Prefer one"
                                            />
                                        </div>
                                        <div className='w-[100%] md:w-[50%]'>
                                            <label className='text-sm'>Referral Code (optional)</label>
                                            <input
                                                className='border-b border-dashed border-black/50 outline-none w-[100%] bg-transparent h-[38px]'
                                                type="text"
                                                name="referCode"
                                                value={formData.referCode}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            sitekey={reCaptcha_public}
                                            onChange={handleRecaptchaChange}
                                            className="scale-90"
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-end mt-5 font-semibold'>
                                    <button onClick={handleSubmission} className='font-play bg-gray-300 backdrop-blur-md py-2 px-5 rounded-full duration-500 active:translate-y-1'>
                                        Book my kit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
