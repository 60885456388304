// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';

// const SERVER_URL ="http://localhost:5000"

// export const fetchUserRank = createAsyncThunk(
//     'rankings/fetchUserRank',
//     async (userId) => {
//         const response = await axios.get(`${SERVER_URL}/api/user-rank/${userId}`);

//         console.log("response fetchUserRank",response)
//         return response.data;
//     }
// );


// const initialState = {
//     user: null,
//     organizationRanking: [],
//     globalRanking: [],
//     status: 'idle',
//     error: null
// };

// const rankingsSlice = createSlice({
//     name: 'rankings',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchUserRank.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchUserRank.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.user = action.payload.user;
//                 state.organizationRanking = action.payload.organization_ranking;
//                 state.globalRanking = action.payload.global_ranking;
//             })
//             .addCase(fetchUserRank.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     }
// });

// export default rankingsSlice.reducer;



// rankingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL ="http://localhost:5000"


export const fetchUserRank = createAsyncThunk(
  'rankings/fetchUserRank',
  async (userId) => {
    const response = await axios.get(`${SERVER_URL}/api/coe/user-rank/${userId}`);
    console.log("response",response)
    return response.data;
  }
);

const rankingsSlice = createSlice({
  name: 'rankings',
  initialState: {
    user: null,
    organization_ranking: [],
    global_ranking: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRank.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserRank.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.organization_ranking = action.payload.organization_ranking;
        state.global_ranking = action.payload.global_ranking;
      })
      .addCase(fetchUserRank.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default rankingsSlice.reducer;
