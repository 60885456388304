import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const AdminPanel = () => {
    const SERVER_URL = "http://localhost:5000";
    const [institutes, setInstitutes] = useState([]);
    const [courses, setCourses] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedInstitute, setSelectedInstitute] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [email, setEmail] = useState('');
    const [projectDetails, setProjectDetails] = useState({
        name: '',
        description: '',
        issuedBy: '',
        startDate: '',
        endDate: '',
        completionPercentage: '',
    });
    const [mode, setMode] = useState('dropdown'); // 'dropdown' or 'email'

    // Fetch institutes on load
    useEffect(() => {
        const fetchInstitutes = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/institutes`);
                setInstitutes(response.data);
            } catch (error) {
                console.error('Error fetching institutes:', error);
                toast.error('Failed to load institutes.');
            }
        };
        fetchInstitutes();
    }, []);

    // Fetch courses when an institute is selected
    useEffect(() => {
        const fetchCourses = async () => {
            if (!selectedInstitute) return;
            try {
                const response = await axios.get(`${SERVER_URL}/api/institute-courses/${selectedInstitute}`);

                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
                toast.error('Failed to load courses.');
            }
        };
        fetchCourses();
    }, [selectedInstitute]);


    useEffect(() => {
        const fetchUsers = async () => {
            if (!selectedCourse) return;
            try {
                const response = await axios.get(`${SERVER_URL}/api/coe/courses/${selectedCourse}/users`);
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
                toast.error('Failed to load users.');
            }
        };
        fetchUsers();
    }, [selectedCourse]);

    const handleProjectSubmission = async () => {
        try {
            const payload = mode === 'dropdown'
                ? {
                      user_id: selectedUser,
                      ...projectDetails,
                  }
                : {
                      email,
                      ...projectDetails,
                  };

            const response = await axios.post(`${SERVER_URL}/api/coe/add-projects`, payload);
            toast.success('Project added successfully!');
            console.log('Project added:', response.data);
        } catch (error) {
            console.error('Error adding project:', error);
            if (error.response && error.response.status === 404) {
                toast.error('User not found. Please check the email or selected user.');
            } else {
                toast.error('Failed to add project. Please try again.');
            }
        }
    };

    return (
        <div className="container mx-auto p-4">
            <Toaster />
            <h1 className="text-2xl font-bold mb-4">Admin Panel - Add Projects</h1>
            <div className="mb-4">
                <label className="block mb-2">
                    <input
                        type="radio"
                        value="dropdown"
                        checked={mode === 'dropdown'}
                        onChange={() => setMode('dropdown')}
                        className="mr-2"
                    />
                    Use Dropdown Selection
                </label>
                <label className="block">
                    <input
                        type="radio"
                        value="email"
                        checked={mode === 'email'}
                        onChange={() => setMode('email')}
                        className="mr-2"
                    />
                    Use Email Input
                </label>
            </div>

            {mode === 'dropdown' ? (
                <div>
                    {/* Institute Selection */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium mb-2">Select Institute</label>
                        <select
                            className="w-full p-2 border border-gray-300 rounded"
                            value={selectedInstitute}
                            onChange={(e) => setSelectedInstitute(e.target.value)}
                        >
                            <option value="">-- Select an Institute --</option>
                            {institutes.map((institute) => (
                                <option key={institute.id} value={institute.id}>
                                    {institute.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Course Selection */}
                    {courses.length > 0 && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-2">Select Course</label>
                            <select
                                className="w-full p-2 border border-gray-300 rounded"
                                value={selectedCourse}
                                onChange={(e) => setSelectedCourse(e.target.value)}
                            >
                                <option value="">-- Select a Course --</option>
                                {courses.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* User Selection */}
                    {users.length > 0 && (
                        <div className="mb-4">
                            <label className="block text-sm font-medium mb-2">Select User</label>
                            <select
                                className="w-full p-2 border border-gray-300 rounded"
                                value={selectedUser}
                                onChange={(e) => setSelectedUser(e.target.value)}
                            >
                                <option value="">-- Select a User --</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            ) : (
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Enter User Email</label>
                    <input
                        type="email"
                        className="w-full p-2 border border-gray-300 rounded"
                        placeholder="User Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            )}

            {/* Project Details Form */}
            <div>
                <label className="block text-sm font-medium mb-2">Project Name</label>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    placeholder="Project Name"
                    value={projectDetails.name}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({ ...prev, name: e.target.value }))
                    }
                />
                <label className="block text-sm font-medium mb-2">Project Description</label>
                <textarea
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    placeholder="Project Description"
                    value={projectDetails.description}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({ ...prev, description: e.target.value }))
                    }
                ></textarea>

            <label className="block text-sm font-medium mb-2">Issued By</label>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    placeholder="Issued By"
                    value={projectDetails.issuedBy}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({ ...prev, issuedBy: e.target.value }))
                    }
                />

                <label className="block text-sm font-medium mb-2">Start Date</label>
                <input
                    type="date"
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    value={projectDetails.startDate}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({ ...prev, startDate: e.target.value }))
                    }
                />
                <label className="block text-sm font-medium mb-2">End Date</label>
                <input
                    type="date"
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                    value={projectDetails.endDate}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({ ...prev, endDate: e.target.value }))
                    }
                />
                <label className="block text-sm font-medium mb-2">Completion Percentage</label>
                <input
                    type="number"
                    className="w-full p-2 border border-gray-300 rounded"
                    placeholder="Percentage Completed"
                    value={projectDetails.completionPercentage}
                    onChange={(e) =>
                        setProjectDetails((prev) => ({
                            ...prev,
                            completionPercentage: e.target.value,
                        }))
                    }
                />
            </div>

            <button
                onClick={handleProjectSubmission}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                Add Project
            </button>
        </div>
    );
};

export default AdminPanel;
