// AdminPanel.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const AdminPanel = () => {
  const SERVER_URL = "http://localhost:5000";
  const [institutes, setInstitutes] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState({
    title: '',
    meetingLink: '',
    scheduledAt: ''
  });

  useEffect(() => {
    const fetchInstitutes = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/institutes`);
        setInstitutes(response.data);
      } catch (error) {
        console.error("Error fetching institutes:", error);
        toast.error("Failed to load institutes. Please try again later.");
      }
    };
    fetchInstitutes();
  }, []);

 
  const handleInstituteSelect = async (instituteId) => {
    setSelectedInstitute(instituteId);
    setCourses([]);
    try {
      const response = await axios.get(`${SERVER_URL}/api/institute-courses/${instituteId}`);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);

      toast.error("Failed to load courses. Please try again later.");
    }
  };

  // Create a new meeting
  const handleCreateMeeting = async () => {
    if (!selectedCourse || !meetingDetails.title || !meetingDetails.meetingLink || !meetingDetails.scheduledAt) {
      toast.error("Please fill all fields before creating the meeting.");
      return;
    }

    try {
   const response=    await axios.post(`${SERVER_URL}/api/create-meeting`, {
        courseId: selectedCourse,
        instituteId:selectedInstitute,
        ...meetingDetails,
      });

      console.log("response",response)

      toast.success('Meeting created and notifications sent!');
      setMeetingDetails({ title: '', meetingLink: '', scheduledAt: '' });
    } catch (error) {
      console.error("Error creating meeting:", error);
      toast.error(error?.response?.data?.message||"Failed to create meeting. Please try again later.");
      // toast.error("Failed to create meeting. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-100 to-blue-200 p-8">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-lg border-2 border-gray-200">
        <h1 className="text-4xl font-bold text-purple-700 mb-6">Admin Panel - Schedule a Meeting</h1>

        {/* Institute Selection */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Select Institute</label>
          <select
            onChange={(e) => handleInstituteSelect(e.target.value)}
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            <option value="">Choose Institute</option>
            {institutes.map((institute) => (
              <option key={institute.id} value={institute.id}>{institute.name}</option>
            ))}
          </select>
        </div>

        {/* Course Selection */}
        {courses.length > 0 && (
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Select Course</label>
            <select
              onChange={(e) => setSelectedCourse(e.target.value)}
              className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <option value="">Choose Course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.id}>{course.title}</option>
              ))}
            </select>
          </div>
        )}

        {/* Meeting Title */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Meeting Title</label>
          <input
            type="text"
            placeholder="Enter meeting title"
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={meetingDetails.title}
            onChange={(e) => setMeetingDetails({ ...meetingDetails, title: e.target.value })}
          />
        </div>

        {/* Meeting Link */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Meeting Link</label>
          <input
            type="text"
            placeholder="Enter meeting link"
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={meetingDetails.meetingLink}
            onChange={(e) => setMeetingDetails({ ...meetingDetails, meetingLink: e.target.value })}
          />
        </div>

        {/* Meeting Date and Time */}
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Scheduled Date & Time</label>
          <input
            type="datetime-local"
            className="w-full px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            value={meetingDetails.scheduledAt}
            onChange={(e) => setMeetingDetails({ ...meetingDetails, scheduledAt: e.target.value })}
          />
        </div>

        {/* Create Meeting Button */}
        <div className="flex justify-center">
          <button
            onClick={handleCreateMeeting}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg font-semibold hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            disabled={!selectedCourse}
          >
            Create Meeting
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
