import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVER_URL ="http://localhost:5000"
const initialState = {
    userScores: [],
    status: 'idle', 
    error: null,
};

export const fetchUserScores = createAsyncThunk(
    'scores/fetchUserScores',
    async (instituteId) => {
        const response = await axios.get(`${SERVER_URL}/api/get-rankings/${instituteId}`);
        return response.data.rankedUsers;
    }
);

const scoresSlice = createSlice({
    name: 'scores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserScores.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserScores.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userScores = action.payload;
            })
            .addCase(fetchUserScores.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default scoresSlice.reducer;
