import React from 'react';
import Chart from 'react-apexcharts';

export default function Attendance() {
  const chartOptions = {
    series: [70], // Percentage value
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '60%',
          },
          track: {
            strokeWidth: '10%',
            background: '#E2E2E2',
          },
          dataLabels: {
            show: false,
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          gradientToColors: ['#51CBFF'],
          stops: [0, 100],
        },
      },
    },
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className='w-[50%]'>
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="radialBar"
          height={300}
        />
      </div>
      <div className='w-[50%]'>
        <h2 className="text-base font-bold">Attendance</h2>
        <p className="text-lg mt-2">70%</p>
      </div>
    </div>
  );
}
