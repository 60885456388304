// // src/features/courseProgress/courseProgressSlice.js

// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// const SERVER_URL ="http://localhost:5000"
// export const fetchCourseProgress = createAsyncThunk(
//   'courseProgress/fetchCourseProgress',
//   async ({ userId, courseId }) => {
//     const response = await axios.get(`${SERVER_URL}/api/course/${courseId}/progress/${userId}`);
//     console.log("response",response)
//     return response.data;
//   }
// );

// // Thunk for updating module completion
// export const updateModuleCompletion = createAsyncThunk(
//   'courseProgress/updateModuleCompletion',
//   async ({ userId, courseId, moduleId }) => {
//     const response = await axios.post(`${SERVER_URL}/course/${courseId}/complete-module`, { userId, moduleId });
//     return { moduleId, ...response.data };
//   }
// );

// const courseProgressSlice = createSlice({
//   name: 'courseProgress',
//   initialState: {
//     modules: [],
//     overallCompletion: 0,
//     status: 'idle',
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchCourseProgress.pending, (state) => {
//         state.status = 'loading';
//       })
//       .addCase(fetchCourseProgress.fulfilled, (state, action) => {
//         state.status = 'succeeded';
//         state.modules = action.payload.modules;
//         state.overallCompletion = action.payload.overallCompletion;
//       })
//       .addCase(fetchCourseProgress.rejected, (state, action) => {
//         state.status = 'failed';
//         state.error = action.error.message;
//       })
//       .addCase(updateModuleCompletion.fulfilled, (state, action) => {
//         const { moduleId, completion_percentage } = action.payload;
//         const moduleIndex = state.modules.findIndex(module => module.module_id === moduleId);
//         if (moduleIndex !== -1) {
//           state.modules[moduleIndex].completion_percentage = completion_percentage;
//         }
//       });
//   },
// });

// export default courseProgressSlice.reducer;










// src/features/courseProgress/courseProgressSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const initialState = {
  courses: {},  // Object to store progress per course
  status: 'idle',
  error: null,
};

const SERVER_URL ="http://localhost:5000"

export const fetchUserCoursesProgress = createAsyncThunk(
  'courseProgress/fetchUserCoursesProgress',
  async (userId) => {
    const response = await axios.get(`${SERVER_URL}/api/user/${userId}/courses/progress`);
    console.log('response fetchUserCoursesProgress',response)
    return response.data.courses;
  }
);

const courseProgressSlice = createSlice({
  name: 'courseProgress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCoursesProgress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserCoursesProgress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.courses = action.payload;
      })
      .addCase(fetchUserCoursesProgress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectCourseProgress = (state) => state.courseProgress.courses;
export const selectCourseStatus = (state) => state.courseProgress.status;
export const selectCourseError = (state) => state.courseProgress.error;

export default courseProgressSlice.reducer;

