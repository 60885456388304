import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const EditProject = () => {
    const SERVER_URL = "http://localhost:5000";

    // const { projectId } = useParams();
    const  projectId  = 1;

    
    const navigate = useNavigate();

    const [project, setProject] = useState({
        name: '',
        description: '',
        issuedBy:'',
        startDate: '',
        endDate: '',
        completionPercentage: '',
    });

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/coe/projects/${projectId}`);


                console.log("response",response)
                const { project_name, project_description, start_date, end_date, completion_percentage ,issued_by } = response.data;

                setProject({
                    name: project_name,
                    issuedBy :issued_by,
                    description: project_description,
                    startDate: formatDate(start_date),
                    endDate: formatDate(end_date),
                    completionPercentage: completion_percentage,
                });
            } catch (error) {
                console.error('Error fetching project:', error);
                toast.error('Failed to load project details.');
            }
        };
        fetchProject();
    }, [projectId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProject((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = async () => {
        try {
          

            const response = await axios.post(`${SERVER_URL}/api/coe/update-projects/${projectId}`, project);
            if (response.status === 200) {
                toast.success('Project updated successfully!');
               
            }
        } catch (error) {
            console.error('Error updating project:', error);
            toast.error('Failed to update project.');
            if (error.response && error.response.data) {
                toast.error(error.response.data.message || 'An error occurred.');
            } else {
                toast.error('An unexpected error occurred.');
            }
    };
}

    return (
        <div className="container mx-auto p-4">
            <Toaster />
            <h1 className="text-2xl font-bold mb-4 text-purple-600">Edit Project</h1>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Project Name</label>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded"
                    name="name"
                    value={project.name}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Project Description</label>
                <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    name="description"
                    value={project.description}
                    onChange={handleInputChange}
                ></textarea>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Issued By</label>
                <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded"
                    name="issuedBy"
                    value={project.issuedBy}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Start Date</label>
                <input
                    type="date"
                    className="w-full p-2 border border-gray-300 rounded"
                    name="startDate"
                    value={project.startDate}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">End Date</label>
                <input
                    type="date"
                    className="w-full p-2 border border-gray-300 rounded"
                    name="endDate"
                    value={project.endDate}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Completion Percentage</label>
                <input
                    type="number"
                    className="w-full p-2 border border-gray-300 rounded"
                    name="completionPercentage"
                    value={project.completionPercentage}
                    onChange={handleInputChange}
                />
            </div>
            <button
                onClick={handleUpdate}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                Update Project
            </button>
        </div>
    );
};

export default EditProject;
