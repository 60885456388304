// ScheduleMeeting.js

import React, { useState } from 'react';
import axios from 'axios';

function ScheduleMeeting() {
    const [users, setUsers] = useState('');
    const [subject, setSubject] = useState('');
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [meetingLink, setMeetingLink] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/api/schedule-meeting', {
                users: users.split(',').map(email => email.trim()),
                subject,
                startDateTime,
                endDateTime
            });

            setMeetingLink(response.data.meetingLink);
        } catch (error) {
            console.error('Error scheduling meeting:', error);
            alert('Failed to schedule meeting');
        }
    };

    return (
        <div>
            <h2>Schedule a Meeting in Microsoft Teams</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Users (comma-separated emails):
                    <input
                        type="text"
                        value={users}
                        onChange={(e) => setUsers(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Subject:
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    Start DateTime (UTC):
                    <input
                        type="datetime-local"
                        value={startDateTime}
                        onChange={(e) => setStartDateTime(e.target.value)}
                        required
                    />
                </label>
                <br />
                <label>
                    End DateTime (UTC):
                    <input
                        type="datetime-local"
                        value={endDateTime}
                        onChange={(e) => setEndDateTime(e.target.value)}
                        required
                    />
                </label>
                <br />
                <button type="submit">Schedule Meeting</button>
            </form>

            {meetingLink && (
                <div>
                    <h3>Meeting Scheduled!</h3>
                    <p>Meeting Link: <a href={meetingLink} target="_blank" rel="noopener noreferrer">{meetingLink}</a></p>
                </div>
            )}
        </div>
    );
}

export default ScheduleMeeting;
